import type { FormInstance } from "element-plus";

export async function validateForm(
  formEl: FormInstance | undefined
): Promise<boolean | undefined> {
  if (!formEl) return false;
  return await formEl.validate((valid) => {
    return valid;
  });
}

export const submitForm = async (
  formEl: FormInstance | undefined,
  emit: any,
  form: any
) => {
  await validateForm(formEl).then((isValid: boolean | undefined) => {
    if (isValid) {
      emit("confirmAction", form.value);
    }
  });
};
