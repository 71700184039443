/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddCoreTrainingCommand
 */
export interface AddCoreTrainingCommand {
    /**
     * 
     * @type {string}
     * @memberof AddCoreTrainingCommand
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCoreTrainingCommand
     */
    linkedProviderId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddCoreTrainingCommand
     */
    allProvidersIds?: Array<string> | null;
}

export function AddCoreTrainingCommandFromJSON(json: any): AddCoreTrainingCommand {
    return AddCoreTrainingCommandFromJSONTyped(json, false);
}

export function AddCoreTrainingCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddCoreTrainingCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'linkedProviderId': !exists(json, 'linkedProviderId') ? undefined : json['linkedProviderId'],
        'allProvidersIds': !exists(json, 'allProvidersIds') ? undefined : json['allProvidersIds'],
    };
}

export function AddCoreTrainingCommandToJSON(value?: AddCoreTrainingCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'linkedProviderId': value.linkedProviderId,
        'allProvidersIds': value.allProvidersIds,
    };
}

