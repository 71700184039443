/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDetailsViewModel
 */
export interface UserDetailsViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    readonly notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    addressLine1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    postCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin1Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin1Relationship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin1Mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin1Address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin2Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin2Relationship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin2Mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    kin2Address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    roleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    roleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    managerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    roomLeaderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    userImageUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsViewModel
     */
    learningStyleId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsViewModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsViewModel
     */
    status?: string | null;
}

export function UserDetailsViewModelFromJSON(json: any): UserDetailsViewModel {
    return UserDetailsViewModelFromJSONTyped(json, false);
}

export function UserDetailsViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetailsViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'postCode': !exists(json, 'postCode') ? undefined : json['postCode'],
        'kin1Name': !exists(json, 'kin1Name') ? undefined : json['kin1Name'],
        'kin1Relationship': !exists(json, 'kin1Relationship') ? undefined : json['kin1Relationship'],
        'kin1Mobile': !exists(json, 'kin1Mobile') ? undefined : json['kin1Mobile'],
        'kin1Address': !exists(json, 'kin1Address') ? undefined : json['kin1Address'],
        'kin2Name': !exists(json, 'kin2Name') ? undefined : json['kin2Name'],
        'kin2Relationship': !exists(json, 'kin2Relationship') ? undefined : json['kin2Relationship'],
        'kin2Mobile': !exists(json, 'kin2Mobile') ? undefined : json['kin2Mobile'],
        'kin2Address': !exists(json, 'kin2Address') ? undefined : json['kin2Address'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'roomLeaderId': !exists(json, 'roomLeaderId') ? undefined : json['roomLeaderId'],
        'userImageUrl': !exists(json, 'userImageUrl') ? undefined : json['userImageUrl'],
        'learningStyleId': !exists(json, 'learningStyleId') ? undefined : json['learningStyleId'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UserDetailsViewModelToJSON(value?: UserDetailsViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'town': value.town,
        'postCode': value.postCode,
        'kin1Name': value.kin1Name,
        'kin1Relationship': value.kin1Relationship,
        'kin1Mobile': value.kin1Mobile,
        'kin1Address': value.kin1Address,
        'kin2Name': value.kin2Name,
        'kin2Relationship': value.kin2Relationship,
        'kin2Mobile': value.kin2Mobile,
        'kin2Address': value.kin2Address,
        'startDate': value.startDate,
        'roleName': value.roleName,
        'roleId': value.roleId,
        'managerId': value.managerId,
        'roomLeaderId': value.roomLeaderId,
        'userImageUrl': value.userImageUrl,
        'learningStyleId': value.learningStyleId,
        'deleted': value.deleted,
        'status': value.status,
    };
}

