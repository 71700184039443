/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LearningStyleDto,
    LearningStyleDtoFromJSON,
    LearningStyleDtoFromJSONTyped,
    LearningStyleDtoToJSON,
} from './LearningStyleDto';

/**
 * 
 * @export
 * @interface LearningStyleDtoListApiResponse
 */
export interface LearningStyleDtoListApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LearningStyleDtoListApiResponse
     */
    succeeded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LearningStyleDtoListApiResponse
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LearningStyleDtoListApiResponse
     */
    errors?: Array<string> | null;
    /**
     * 
     * @type {Array<LearningStyleDto>}
     * @memberof LearningStyleDtoListApiResponse
     */
    data?: Array<LearningStyleDto> | null;
}

export function LearningStyleDtoListApiResponseFromJSON(json: any): LearningStyleDtoListApiResponse {
    return LearningStyleDtoListApiResponseFromJSONTyped(json, false);
}

export function LearningStyleDtoListApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LearningStyleDtoListApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(LearningStyleDtoFromJSON)),
    };
}

export function LearningStyleDtoListApiResponseToJSON(value?: LearningStyleDtoListApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'succeeded': value.succeeded,
        'message': value.message,
        'errors': value.errors,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(LearningStyleDtoToJSON)),
    };
}

