export const BASE_URLS = {
  HOME: "/",
  SET_PASSWORD: "/set-password",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  TRAININGS: "/trainings",
  POLICIES: "/policies",
  TODOS: "/todos",
  DISCIPLINARIES: "/disciplinaries",
  USERS: "/users",
  SETTINGS: "/settings",
};
