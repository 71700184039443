/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CoreTrainingProvider,
    CoreTrainingProviderFromJSON,
    CoreTrainingProviderFromJSONTyped,
    CoreTrainingProviderToJSON,
} from './CoreTrainingProvider';
import {
    Training,
    TrainingFromJSON,
    TrainingFromJSONTyped,
    TrainingToJSON,
} from './Training';

/**
 * 
 * @export
 * @interface TrainingProvider
 */
export interface TrainingProvider {
    /**
     * 
     * @type {string}
     * @memberof TrainingProvider
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProvider
     */
    name?: string | null;
    /**
     * 
     * @type {Array<CoreTrainingProvider>}
     * @memberof TrainingProvider
     */
    coreTrainingProviders?: Array<CoreTrainingProvider> | null;
    /**
     * 
     * @type {Array<Training>}
     * @memberof TrainingProvider
     */
    trainings?: Array<Training> | null;
}

export function TrainingProviderFromJSON(json: any): TrainingProvider {
    return TrainingProviderFromJSONTyped(json, false);
}

export function TrainingProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'coreTrainingProviders': !exists(json, 'coreTrainingProviders') ? undefined : (json['coreTrainingProviders'] === null ? null : (json['coreTrainingProviders'] as Array<any>).map(CoreTrainingProviderFromJSON)),
        'trainings': !exists(json, 'trainings') ? undefined : (json['trainings'] === null ? null : (json['trainings'] as Array<any>).map(TrainingFromJSON)),
    };
}

export function TrainingProviderToJSON(value?: TrainingProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'coreTrainingProviders': value.coreTrainingProviders === undefined ? undefined : (value.coreTrainingProviders === null ? null : (value.coreTrainingProviders as Array<any>).map(CoreTrainingProviderToJSON)),
        'trainings': value.trainings === undefined ? undefined : (value.trainings === null ? null : (value.trainings as Array<any>).map(TrainingToJSON)),
    };
}

