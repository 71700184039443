/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddCoreTrainingCommand,
    AddCoreTrainingCommandFromJSON,
    AddCoreTrainingCommandToJSON,
    CoreTrainingDtoApiResponse,
    CoreTrainingDtoApiResponseFromJSON,
    CoreTrainingDtoApiResponseToJSON,
    CoreTrainingDtoPagedResultApiResponse,
    CoreTrainingDtoPagedResultApiResponseFromJSON,
    CoreTrainingDtoPagedResultApiResponseToJSON,
    GetAllPagedCoreTrainingsQuery,
    GetAllPagedCoreTrainingsQueryFromJSON,
    GetAllPagedCoreTrainingsQueryToJSON,
    GuidApiResponse,
    GuidApiResponseFromJSON,
    GuidApiResponseToJSON,
    UpdateCoreTrainingCommand,
    UpdateCoreTrainingCommandFromJSON,
    UpdateCoreTrainingCommandToJSON,
} from '../models';

export interface ApiCoreTrainingIdDeleteRequest {
    id: string;
}

export interface ApiCoreTrainingIdGetRequest {
    id: string;
}

export interface ApiCoreTrainingPagedPostRequest {
    getAllPagedCoreTrainingsQuery?: GetAllPagedCoreTrainingsQuery;
}

export interface ApiCoreTrainingPostRequest {
    addCoreTrainingCommand?: AddCoreTrainingCommand;
}

export interface ApiCoreTrainingPutRequest {
    updateCoreTrainingCommand?: UpdateCoreTrainingCommand;
}

/**
 * CoreTrainingApi - interface
 * 
 * @export
 * @interface CoreTrainingApiInterface
 */
export interface CoreTrainingApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreTrainingApiInterface
     */
    apiCoreTrainingIdDeleteRaw(requestParameters: ApiCoreTrainingIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiCoreTrainingIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreTrainingApiInterface
     */
    apiCoreTrainingIdGetRaw(requestParameters: ApiCoreTrainingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CoreTrainingDtoApiResponse>>;

    /**
     */
    apiCoreTrainingIdGet(id: string, initOverrides?: RequestInit): Promise<CoreTrainingDtoApiResponse>;

    /**
     * 
     * @param {GetAllPagedCoreTrainingsQuery} [getAllPagedCoreTrainingsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreTrainingApiInterface
     */
    apiCoreTrainingPagedPostRaw(requestParameters: ApiCoreTrainingPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CoreTrainingDtoPagedResultApiResponse>>;

    /**
     */
    apiCoreTrainingPagedPost(getAllPagedCoreTrainingsQuery?: GetAllPagedCoreTrainingsQuery, initOverrides?: RequestInit): Promise<CoreTrainingDtoPagedResultApiResponse>;

    /**
     * 
     * @param {AddCoreTrainingCommand} [addCoreTrainingCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreTrainingApiInterface
     */
    apiCoreTrainingPostRaw(requestParameters: ApiCoreTrainingPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>>;

    /**
     */
    apiCoreTrainingPost(addCoreTrainingCommand?: AddCoreTrainingCommand, initOverrides?: RequestInit): Promise<GuidApiResponse>;

    /**
     * 
     * @param {UpdateCoreTrainingCommand} [updateCoreTrainingCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreTrainingApiInterface
     */
    apiCoreTrainingPutRaw(requestParameters: ApiCoreTrainingPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiCoreTrainingPut(updateCoreTrainingCommand?: UpdateCoreTrainingCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class CoreTrainingApi extends runtime.BaseAPI implements CoreTrainingApiInterface {

    /**
     */
    async apiCoreTrainingIdDeleteRaw(requestParameters: ApiCoreTrainingIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCoreTrainingIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CoreTraining/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCoreTrainingIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiCoreTrainingIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiCoreTrainingIdGetRaw(requestParameters: ApiCoreTrainingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CoreTrainingDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCoreTrainingIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CoreTraining/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CoreTrainingDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCoreTrainingIdGet(id: string, initOverrides?: RequestInit): Promise<CoreTrainingDtoApiResponse> {
        const response = await this.apiCoreTrainingIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCoreTrainingPagedPostRaw(requestParameters: ApiCoreTrainingPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CoreTrainingDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CoreTraining/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllPagedCoreTrainingsQueryToJSON(requestParameters.getAllPagedCoreTrainingsQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CoreTrainingDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCoreTrainingPagedPost(getAllPagedCoreTrainingsQuery?: GetAllPagedCoreTrainingsQuery, initOverrides?: RequestInit): Promise<CoreTrainingDtoPagedResultApiResponse> {
        const response = await this.apiCoreTrainingPagedPostRaw({ getAllPagedCoreTrainingsQuery: getAllPagedCoreTrainingsQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCoreTrainingPostRaw(requestParameters: ApiCoreTrainingPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CoreTraining`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCoreTrainingCommandToJSON(requestParameters.addCoreTrainingCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuidApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCoreTrainingPost(addCoreTrainingCommand?: AddCoreTrainingCommand, initOverrides?: RequestInit): Promise<GuidApiResponse> {
        const response = await this.apiCoreTrainingPostRaw({ addCoreTrainingCommand: addCoreTrainingCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCoreTrainingPutRaw(requestParameters: ApiCoreTrainingPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CoreTraining`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCoreTrainingCommandToJSON(requestParameters.updateCoreTrainingCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCoreTrainingPut(updateCoreTrainingCommand?: UpdateCoreTrainingCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiCoreTrainingPutRaw({ updateCoreTrainingCommand: updateCoreTrainingCommand }, initOverrides);
    }

}
