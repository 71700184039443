/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    FormType,
    FormTypeFromJSON,
    FormTypeFromJSONTyped,
    FormTypeToJSON,
} from './FormType';
import {
    PerformanceFileStatus,
    PerformanceFileStatusFromJSON,
    PerformanceFileStatusFromJSONTyped,
    PerformanceFileStatusToJSON,
} from './PerformanceFileStatus';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';

/**
 * 
 * @export
 * @interface PerformanceFile
 */
export interface PerformanceFile {
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PerformanceFile
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PerformanceFile
     */
    modified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    title?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PerformanceFile
     */
    scheduledDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PerformanceFile
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof PerformanceFile
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    managerId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof PerformanceFile
     */
    manager?: ApplicationUser;
    /**
     * 
     * @type {number}
     * @memberof PerformanceFile
     */
    performanceFileStatusId?: number | null;
    /**
     * 
     * @type {PerformanceFileStatus}
     * @memberof PerformanceFile
     */
    performanceFileStatus?: PerformanceFileStatus;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    uploadedFileId?: string | null;
    /**
     * 
     * @type {UploadedFile}
     * @memberof PerformanceFile
     */
    uploadedFile?: UploadedFile;
    /**
     * 
     * @type {number}
     * @memberof PerformanceFile
     */
    formTypeId?: number | null;
    /**
     * 
     * @type {FormType}
     * @memberof PerformanceFile
     */
    formType?: FormType;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFile
     */
    formContent?: string | null;
}

export function PerformanceFileFromJSON(json: any): PerformanceFile {
    return PerformanceFileFromJSONTyped(json, false);
}

export function PerformanceFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerformanceFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'scheduledDate': !exists(json, 'scheduledDate') ? undefined : (json['scheduledDate'] === null ? null : new Date(json['scheduledDate'])),
        'completedDate': !exists(json, 'completedDate') ? undefined : (json['completedDate'] === null ? null : new Date(json['completedDate'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'manager': !exists(json, 'manager') ? undefined : ApplicationUserFromJSON(json['manager']),
        'performanceFileStatusId': !exists(json, 'performanceFileStatusId') ? undefined : json['performanceFileStatusId'],
        'performanceFileStatus': !exists(json, 'performanceFileStatus') ? undefined : PerformanceFileStatusFromJSON(json['performanceFileStatus']),
        'uploadedFileId': !exists(json, 'uploadedFileId') ? undefined : json['uploadedFileId'],
        'uploadedFile': !exists(json, 'uploadedFile') ? undefined : UploadedFileFromJSON(json['uploadedFile']),
        'formTypeId': !exists(json, 'formTypeId') ? undefined : json['formTypeId'],
        'formType': !exists(json, 'formType') ? undefined : FormTypeFromJSON(json['formType']),
        'formContent': !exists(json, 'formContent') ? undefined : json['formContent'],
    };
}

export function PerformanceFileToJSON(value?: PerformanceFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
        'title': value.title,
        'scheduledDate': value.scheduledDate === undefined ? undefined : (value.scheduledDate === null ? null : value.scheduledDate.toISOString()),
        'completedDate': value.completedDate === undefined ? undefined : (value.completedDate === null ? null : value.completedDate.toISOString()),
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
        'managerId': value.managerId,
        'manager': ApplicationUserToJSON(value.manager),
        'performanceFileStatusId': value.performanceFileStatusId,
        'performanceFileStatus': PerformanceFileStatusToJSON(value.performanceFileStatus),
        'uploadedFileId': value.uploadedFileId,
        'uploadedFile': UploadedFileToJSON(value.uploadedFile),
        'formTypeId': value.formTypeId,
        'formType': FormTypeToJSON(value.formType),
        'formContent': value.formContent,
    };
}

