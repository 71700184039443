/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyStatus,
    PolicyStatusFromJSON,
    PolicyStatusFromJSONTyped,
    PolicyStatusToJSON,
} from './PolicyStatus';

/**
 * 
 * @export
 * @interface AddPolicyCommand
 */
export interface AddPolicyCommand {
    /**
     * 
     * @type {string}
     * @memberof AddPolicyCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddPolicyCommand
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddPolicyCommand
     */
    typeId?: number;
    /**
     * 
     * @type {PolicyStatus}
     * @memberof AddPolicyCommand
     */
    status?: PolicyStatus;
}

export function AddPolicyCommandFromJSON(json: any): AddPolicyCommand {
    return AddPolicyCommandFromJSONTyped(json, false);
}

export function AddPolicyCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPolicyCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'status': !exists(json, 'status') ? undefined : PolicyStatusFromJSON(json['status']),
    };
}

export function AddPolicyCommandToJSON(value?: AddPolicyCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'typeId': value.typeId,
        'status': PolicyStatusToJSON(value.status),
    };
}

