/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LearningStyleDtoListApiResponse,
    LearningStyleDtoListApiResponseFromJSON,
    LearningStyleDtoListApiResponseToJSON,
} from '../models';

/**
 * LearningStyleApi - interface
 * 
 * @export
 * @interface LearningStyleApiInterface
 */
export interface LearningStyleApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearningStyleApiInterface
     */
    apiLearningStyleAllGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LearningStyleDtoListApiResponse>>;

    /**
     */
    apiLearningStyleAllGet(initOverrides?: RequestInit): Promise<LearningStyleDtoListApiResponse>;

}

/**
 * 
 */
export class LearningStyleApi extends runtime.BaseAPI implements LearningStyleApiInterface {

    /**
     */
    async apiLearningStyleAllGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LearningStyleDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/LearningStyle/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LearningStyleDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiLearningStyleAllGet(initOverrides?: RequestInit): Promise<LearningStyleDtoListApiResponse> {
        const response = await this.apiLearningStyleAllGetRaw(initOverrides);
        return await response.value();
    }

}
