/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllUsersViewModel
 */
export interface AllUsersViewModel {
    /**
     * 
     * @type {string}
     * @memberof AllUsersViewModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AllUsersViewModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllUsersViewModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AllUsersViewModel
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AllUsersViewModel
     */
    role?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllUsersViewModel
     */
    archived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AllUsersViewModel
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllUsersViewModel
     */
    status?: string | null;
}

export function AllUsersViewModelFromJSON(json: any): AllUsersViewModel {
    return AllUsersViewModelFromJSONTyped(json, false);
}

export function AllUsersViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllUsersViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'role': !exists(json, 'role') ? undefined : json['role'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function AllUsersViewModelToJSON(value?: AllUsersViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'role': value.role,
        'archived': value.archived,
        'active': value.active,
        'status': value.status,
    };
}

