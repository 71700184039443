/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisciplinaryType,
    DisciplinaryTypeFromJSON,
    DisciplinaryTypeFromJSONTyped,
    DisciplinaryTypeToJSON,
} from './DisciplinaryType';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';
import {
    UserDisciplinary,
    UserDisciplinaryFromJSON,
    UserDisciplinaryFromJSONTyped,
    UserDisciplinaryToJSON,
} from './UserDisciplinary';

/**
 * 
 * @export
 * @interface Disciplinary
 */
export interface Disciplinary {
    /**
     * 
     * @type {string}
     * @memberof Disciplinary
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Disciplinary
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Disciplinary
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof Disciplinary
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Disciplinary
     */
    modified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Disciplinary
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Disciplinary
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof Disciplinary
     */
    employeeSignature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Disciplinary
     */
    managerSignature?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Disciplinary
     */
    disciplinaryTypeId?: number | null;
    /**
     * 
     * @type {DisciplinaryType}
     * @memberof Disciplinary
     */
    disciplinaryType?: DisciplinaryType;
    /**
     * 
     * @type {Array<UploadedFile>}
     * @memberof Disciplinary
     */
    uploadedFiles?: Array<UploadedFile> | null;
    /**
     * 
     * @type {Array<UserDisciplinary>}
     * @memberof Disciplinary
     */
    userDisciplinaries?: Array<UserDisciplinary> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Disciplinary
     */
    deleted?: boolean;
}

export function DisciplinaryFromJSON(json: any): Disciplinary {
    return DisciplinaryFromJSONTyped(json, false);
}

export function DisciplinaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Disciplinary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'employeeSignature': !exists(json, 'employeeSignature') ? undefined : json['employeeSignature'],
        'managerSignature': !exists(json, 'managerSignature') ? undefined : json['managerSignature'],
        'disciplinaryTypeId': !exists(json, 'disciplinaryTypeId') ? undefined : json['disciplinaryTypeId'],
        'disciplinaryType': !exists(json, 'disciplinaryType') ? undefined : DisciplinaryTypeFromJSON(json['disciplinaryType']),
        'uploadedFiles': !exists(json, 'uploadedFiles') ? undefined : (json['uploadedFiles'] === null ? null : (json['uploadedFiles'] as Array<any>).map(UploadedFileFromJSON)),
        'userDisciplinaries': !exists(json, 'userDisciplinaries') ? undefined : (json['userDisciplinaries'] === null ? null : (json['userDisciplinaries'] as Array<any>).map(UserDisciplinaryFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

export function DisciplinaryToJSON(value?: Disciplinary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
        'description': value.description,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'employeeSignature': value.employeeSignature,
        'managerSignature': value.managerSignature,
        'disciplinaryTypeId': value.disciplinaryTypeId,
        'disciplinaryType': DisciplinaryTypeToJSON(value.disciplinaryType),
        'uploadedFiles': value.uploadedFiles === undefined ? undefined : (value.uploadedFiles === null ? null : (value.uploadedFiles as Array<any>).map(UploadedFileToJSON)),
        'userDisciplinaries': value.userDisciplinaries === undefined ? undefined : (value.userDisciplinaries === null ? null : (value.userDisciplinaries as Array<any>).map(UserDisciplinaryToJSON)),
        'deleted': value.deleted,
    };
}

