/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PerformanceFileStatusDtoApiResponse,
    PerformanceFileStatusDtoApiResponseFromJSON,
    PerformanceFileStatusDtoApiResponseToJSON,
    PerformanceFileStatusDtoListApiResponse,
    PerformanceFileStatusDtoListApiResponseFromJSON,
    PerformanceFileStatusDtoListApiResponseToJSON,
} from '../models';

export interface ApiPerformanceFileStatusIdGetRequest {
    id: number;
}

/**
 * PerformanceFileStatusApi - interface
 * 
 * @export
 * @interface PerformanceFileStatusApiInterface
 */
export interface PerformanceFileStatusApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileStatusApiInterface
     */
    apiPerformanceFileStatusGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileStatusDtoListApiResponse>>;

    /**
     */
    apiPerformanceFileStatusGet(initOverrides?: RequestInit): Promise<PerformanceFileStatusDtoListApiResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileStatusApiInterface
     */
    apiPerformanceFileStatusIdGetRaw(requestParameters: ApiPerformanceFileStatusIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileStatusDtoApiResponse>>;

    /**
     */
    apiPerformanceFileStatusIdGet(id: number, initOverrides?: RequestInit): Promise<PerformanceFileStatusDtoApiResponse>;

}

/**
 * 
 */
export class PerformanceFileStatusApi extends runtime.BaseAPI implements PerformanceFileStatusApiInterface {

    /**
     */
    async apiPerformanceFileStatusGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileStatusDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PerformanceFileStatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformanceFileStatusDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPerformanceFileStatusGet(initOverrides?: RequestInit): Promise<PerformanceFileStatusDtoListApiResponse> {
        const response = await this.apiPerformanceFileStatusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPerformanceFileStatusIdGetRaw(requestParameters: ApiPerformanceFileStatusIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileStatusDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPerformanceFileStatusIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PerformanceFileStatus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformanceFileStatusDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPerformanceFileStatusIdGet(id: number, initOverrides?: RequestInit): Promise<PerformanceFileStatusDtoApiResponse> {
        const response = await this.apiPerformanceFileStatusIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

}
