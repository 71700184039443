/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddDisciplinaryTypeCommand,
    AddDisciplinaryTypeCommandFromJSON,
    AddDisciplinaryTypeCommandToJSON,
    DisciplinaryTypeDtoApiResponse,
    DisciplinaryTypeDtoApiResponseFromJSON,
    DisciplinaryTypeDtoApiResponseToJSON,
    DisciplinaryTypeDtoListApiResponse,
    DisciplinaryTypeDtoListApiResponseFromJSON,
    DisciplinaryTypeDtoListApiResponseToJSON,
    GetAllDisciplinaryTypesQuery,
    GetAllDisciplinaryTypesQueryFromJSON,
    GetAllDisciplinaryTypesQueryToJSON,
    UpdateDisciplinaryTypeCommand,
    UpdateDisciplinaryTypeCommandFromJSON,
    UpdateDisciplinaryTypeCommandToJSON,
} from '../models';

export interface ApiDisciplinaryTypeAllPostRequest {
    getAllDisciplinaryTypesQuery?: GetAllDisciplinaryTypesQuery;
}

export interface ApiDisciplinaryTypeIdDeleteRequest {
    id: number;
}

export interface ApiDisciplinaryTypeIdGetRequest {
    id: number;
}

export interface ApiDisciplinaryTypePostRequest {
    addDisciplinaryTypeCommand?: AddDisciplinaryTypeCommand;
}

export interface ApiDisciplinaryTypePutRequest {
    updateDisciplinaryTypeCommand?: UpdateDisciplinaryTypeCommand;
}

/**
 * DisciplinaryTypeApi - interface
 * 
 * @export
 * @interface DisciplinaryTypeApiInterface
 */
export interface DisciplinaryTypeApiInterface {
    /**
     * 
     * @param {GetAllDisciplinaryTypesQuery} [getAllDisciplinaryTypesQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryTypeApiInterface
     */
    apiDisciplinaryTypeAllPostRaw(requestParameters: ApiDisciplinaryTypeAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryTypeDtoListApiResponse>>;

    /**
     */
    apiDisciplinaryTypeAllPost(getAllDisciplinaryTypesQuery?: GetAllDisciplinaryTypesQuery, initOverrides?: RequestInit): Promise<DisciplinaryTypeDtoListApiResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryTypeApiInterface
     */
    apiDisciplinaryTypeIdDeleteRaw(requestParameters: ApiDisciplinaryTypeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiDisciplinaryTypeIdDelete(id: number, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryTypeApiInterface
     */
    apiDisciplinaryTypeIdGetRaw(requestParameters: ApiDisciplinaryTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryTypeDtoApiResponse>>;

    /**
     */
    apiDisciplinaryTypeIdGet(id: number, initOverrides?: RequestInit): Promise<DisciplinaryTypeDtoApiResponse>;

    /**
     * 
     * @param {AddDisciplinaryTypeCommand} [addDisciplinaryTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryTypeApiInterface
     */
    apiDisciplinaryTypePostRaw(requestParameters: ApiDisciplinaryTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryTypeDtoApiResponse>>;

    /**
     */
    apiDisciplinaryTypePost(addDisciplinaryTypeCommand?: AddDisciplinaryTypeCommand, initOverrides?: RequestInit): Promise<DisciplinaryTypeDtoApiResponse>;

    /**
     * 
     * @param {UpdateDisciplinaryTypeCommand} [updateDisciplinaryTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryTypeApiInterface
     */
    apiDisciplinaryTypePutRaw(requestParameters: ApiDisciplinaryTypePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiDisciplinaryTypePut(updateDisciplinaryTypeCommand?: UpdateDisciplinaryTypeCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class DisciplinaryTypeApi extends runtime.BaseAPI implements DisciplinaryTypeApiInterface {

    /**
     */
    async apiDisciplinaryTypeAllPostRaw(requestParameters: ApiDisciplinaryTypeAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryTypeDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DisciplinaryType/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllDisciplinaryTypesQueryToJSON(requestParameters.getAllDisciplinaryTypesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisciplinaryTypeDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDisciplinaryTypeAllPost(getAllDisciplinaryTypesQuery?: GetAllDisciplinaryTypesQuery, initOverrides?: RequestInit): Promise<DisciplinaryTypeDtoListApiResponse> {
        const response = await this.apiDisciplinaryTypeAllPostRaw({ getAllDisciplinaryTypesQuery: getAllDisciplinaryTypesQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDisciplinaryTypeIdDeleteRaw(requestParameters: ApiDisciplinaryTypeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDisciplinaryTypeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DisciplinaryType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDisciplinaryTypeIdDelete(id: number, initOverrides?: RequestInit): Promise<void> {
        await this.apiDisciplinaryTypeIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiDisciplinaryTypeIdGetRaw(requestParameters: ApiDisciplinaryTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryTypeDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDisciplinaryTypeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DisciplinaryType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisciplinaryTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDisciplinaryTypeIdGet(id: number, initOverrides?: RequestInit): Promise<DisciplinaryTypeDtoApiResponse> {
        const response = await this.apiDisciplinaryTypeIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDisciplinaryTypePostRaw(requestParameters: ApiDisciplinaryTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryTypeDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DisciplinaryType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDisciplinaryTypeCommandToJSON(requestParameters.addDisciplinaryTypeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisciplinaryTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDisciplinaryTypePost(addDisciplinaryTypeCommand?: AddDisciplinaryTypeCommand, initOverrides?: RequestInit): Promise<DisciplinaryTypeDtoApiResponse> {
        const response = await this.apiDisciplinaryTypePostRaw({ addDisciplinaryTypeCommand: addDisciplinaryTypeCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDisciplinaryTypePutRaw(requestParameters: ApiDisciplinaryTypePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DisciplinaryType`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDisciplinaryTypeCommandToJSON(requestParameters.updateDisciplinaryTypeCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDisciplinaryTypePut(updateDisciplinaryTypeCommand?: UpdateDisciplinaryTypeCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiDisciplinaryTypePutRaw({ updateDisciplinaryTypeCommand: updateDisciplinaryTypeCommand }, initOverrides);
    }

}
