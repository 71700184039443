/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCoreTrainingCommand
 */
export interface UpdateCoreTrainingCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCoreTrainingCommand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCoreTrainingCommand
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCoreTrainingCommand
     */
    linkedTrainingProviderId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCoreTrainingCommand
     */
    otherTrainingProviderIds?: Array<string> | null;
}

export function UpdateCoreTrainingCommandFromJSON(json: any): UpdateCoreTrainingCommand {
    return UpdateCoreTrainingCommandFromJSONTyped(json, false);
}

export function UpdateCoreTrainingCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCoreTrainingCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'linkedTrainingProviderId': !exists(json, 'linkedTrainingProviderId') ? undefined : json['linkedTrainingProviderId'],
        'otherTrainingProviderIds': !exists(json, 'otherTrainingProviderIds') ? undefined : json['otherTrainingProviderIds'],
    };
}

export function UpdateCoreTrainingCommandToJSON(value?: UpdateCoreTrainingCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'linkedTrainingProviderId': value.linkedTrainingProviderId,
        'otherTrainingProviderIds': value.otherTrainingProviderIds,
    };
}

