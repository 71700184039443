import { useAuthStore } from "@/stores/authStore";
import { storeToRefs } from "pinia";
import { USER_PERMISSIONS } from "@/shared/config/user-permissions";

const checkPermission = function (key: number, type: string, role = "") {
  if (role === "") {
    const authStore = useAuthStore();
    const { userRole } = storeToRefs(authStore);
    role = userRole.value;
  }
  if (role === "" || role === undefined) {
    return false;
  }

  role = role.toLowerCase();
  const permissions = USER_PERMISSIONS[role];
  if (permissions != null) {
    return permissions[type] && permissions[type].indexOf(key) > -1;
  }
  return false;
};

export const CheckFieldPermission = function (key: number, role = "") {
  return checkPermission(key, "fields", role);
};

export const CheckViewPermission = function (key: number, role = "") {
  return checkPermission(key, "views", role);
};

export const CheckAbilityPermission = function (key: number, role = "") {
  return checkPermission(key, "abilities", role);
};
