import { defineStore } from "pinia";
import { ref } from "vue";

export const useSnackbarStore = defineStore({
  id: "snackbar",
  state: () => {
    const message = ref<string>("");
    const isOpen = ref<boolean>(false);
    const timeout = ref<number>(5000);
    const snackbarClass = ref<string>("");

    return {
      message,
      isOpen,
      timeout,
      snackbarClass,
    };
  },
  getters: {},
  actions: {},
});
