/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PolicyDto
 */
export interface PolicyDto {
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyDto
     */
    typeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    publishedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    publishedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    updatedDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyDto
     */
    approvedCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyDto
     */
    totalCount?: number | null;
}

export function PolicyDtoFromJSON(json: any): PolicyDto {
    return PolicyDtoFromJSONTyped(json, false);
}

export function PolicyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'publishedBy': !exists(json, 'publishedBy') ? undefined : json['publishedBy'],
        'publishedDate': !exists(json, 'publishedDate') ? undefined : json['publishedDate'],
        'updatedDate': !exists(json, 'updatedDate') ? undefined : json['updatedDate'],
        'approvedCount': !exists(json, 'approvedCount') ? undefined : json['approvedCount'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function PolicyDtoToJSON(value?: PolicyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'status': value.status,
        'type': value.type,
        'typeId': value.typeId,
        'publishedBy': value.publishedBy,
        'publishedDate': value.publishedDate,
        'updatedDate': value.updatedDate,
        'approvedCount': value.approvedCount,
        'totalCount': value.totalCount,
    };
}

