/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyDto,
    PolicyDtoFromJSON,
    PolicyDtoFromJSONTyped,
    PolicyDtoToJSON,
} from './PolicyDto';

/**
 * 
 * @export
 * @interface UserPolicyViewModel
 */
export interface UserPolicyViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserPolicyViewModel
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolicyViewModel
     */
    approved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserPolicyViewModel
     */
    approvedDate?: string | null;
    /**
     * 
     * @type {PolicyDto}
     * @memberof UserPolicyViewModel
     */
    policy?: PolicyDto;
}

export function UserPolicyViewModelFromJSON(json: any): UserPolicyViewModel {
    return UserPolicyViewModelFromJSONTyped(json, false);
}

export function UserPolicyViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPolicyViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'approvedDate': !exists(json, 'approvedDate') ? undefined : json['approvedDate'],
        'policy': !exists(json, 'policy') ? undefined : PolicyDtoFromJSON(json['policy']),
    };
}

export function UserPolicyViewModelToJSON(value?: UserPolicyViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'approved': value.approved,
        'approvedDate': value.approvedDate,
        'policy': PolicyDtoToJSON(value.policy),
    };
}

