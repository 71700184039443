/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForgotPassword
 */
export interface ForgotPassword {
    /**
     * 
     * @type {string}
     * @memberof ForgotPassword
     */
    emailAddress?: string | null;
}

export function ForgotPasswordFromJSON(json: any): ForgotPassword {
    return ForgotPasswordFromJSONTyped(json, false);
}

export function ForgotPasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForgotPassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
    };
}

export function ForgotPasswordToJSON(value?: ForgotPassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
    };
}

