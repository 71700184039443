/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToDoDto
 */
export interface ToDoDto {
    /**
     * 
     * @type {string}
     * @memberof ToDoDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ToDoDto
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ToDoDto
     */
    priority?: number;
    /**
     * 
     * @type {Date}
     * @memberof ToDoDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ToDoDto
     */
    dueDate?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ToDoDto
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ToDoDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ToDoDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ToDoDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ToDoDto
     */
    isTraining?: boolean;
}

export function ToDoDtoFromJSON(json: any): ToDoDto {
    return ToDoDtoFromJSONTyped(json, false);
}

export function ToDoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToDoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'dueDate': !exists(json, 'dueDate') ? undefined : json['dueDate'],
        'completedDate': !exists(json, 'completedDate') ? undefined : (json['completedDate'] === null ? null : new Date(json['completedDate'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'isTraining': !exists(json, 'isTraining') ? undefined : json['isTraining'],
    };
}

export function ToDoDtoToJSON(value?: ToDoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'priority': value.priority,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'dueDate': value.dueDate,
        'completedDate': value.completedDate === undefined ? undefined : (value.completedDate === null ? null : value.completedDate.toISOString()),
        'userId': value.userId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'isTraining': value.isTraining,
    };
}

