/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrainingDtoPagedResult,
    TrainingDtoPagedResultFromJSON,
    TrainingDtoPagedResultFromJSONTyped,
    TrainingDtoPagedResultToJSON,
} from './TrainingDtoPagedResult';

/**
 * 
 * @export
 * @interface TrainingDtoPagedResultApiResponse
 */
export interface TrainingDtoPagedResultApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TrainingDtoPagedResultApiResponse
     */
    succeeded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrainingDtoPagedResultApiResponse
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingDtoPagedResultApiResponse
     */
    errors?: Array<string> | null;
    /**
     * 
     * @type {TrainingDtoPagedResult}
     * @memberof TrainingDtoPagedResultApiResponse
     */
    data?: TrainingDtoPagedResult;
}

export function TrainingDtoPagedResultApiResponseFromJSON(json: any): TrainingDtoPagedResultApiResponse {
    return TrainingDtoPagedResultApiResponseFromJSONTyped(json, false);
}

export function TrainingDtoPagedResultApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingDtoPagedResultApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'data': !exists(json, 'data') ? undefined : TrainingDtoPagedResultFromJSON(json['data']),
    };
}

export function TrainingDtoPagedResultApiResponseToJSON(value?: TrainingDtoPagedResultApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'succeeded': value.succeeded,
        'message': value.message,
        'errors': value.errors,
        'data': TrainingDtoPagedResultToJSON(value.data),
    };
}

