/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddAwardCommand
 */
export interface AddAwardCommand {
    /**
     * 
     * @type {string}
     * @memberof AddAwardCommand
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddAwardCommand
     */
    managerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddAwardCommand
     */
    badgeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAwardCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddAwardCommand
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AddAwardCommand
     */
    dateReceived?: Date;
}

export function AddAwardCommandFromJSON(json: any): AddAwardCommand {
    return AddAwardCommandFromJSONTyped(json, false);
}

export function AddAwardCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAwardCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'badgeId': !exists(json, 'badgeId') ? undefined : json['badgeId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dateReceived': !exists(json, 'dateReceived') ? undefined : (new Date(json['dateReceived'])),
    };
}

export function AddAwardCommandToJSON(value?: AddAwardCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'managerId': value.managerId,
        'badgeId': value.badgeId,
        'name': value.name,
        'description': value.description,
        'dateReceived': value.dateReceived === undefined ? undefined : (value.dateReceived.toISOString()),
    };
}

