/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddAwardCommand,
    AddAwardCommandFromJSON,
    AddAwardCommandToJSON,
    AwardDtoApiResponse,
    AwardDtoApiResponseFromJSON,
    AwardDtoApiResponseToJSON,
    AwardDtoListApiResponse,
    AwardDtoListApiResponseFromJSON,
    AwardDtoListApiResponseToJSON,
    UpdateAwardCommand,
    UpdateAwardCommandFromJSON,
    UpdateAwardCommandToJSON,
} from '../models';

export interface ApiAwardAllUserIdGetRequest {
    userId: string;
}

export interface ApiAwardAwardIdGetRequest {
    awardId: string;
}

export interface ApiAwardIdDeleteRequest {
    id: string;
}

export interface ApiAwardPostRequest {
    addAwardCommand?: AddAwardCommand;
}

export interface ApiAwardPutRequest {
    updateAwardCommand?: UpdateAwardCommand;
}

/**
 * AwardApi - interface
 * 
 * @export
 * @interface AwardApiInterface
 */
export interface AwardApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwardApiInterface
     */
    apiAwardAllUserIdGetRaw(requestParameters: ApiAwardAllUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AwardDtoListApiResponse>>;

    /**
     */
    apiAwardAllUserIdGet(userId: string, initOverrides?: RequestInit): Promise<AwardDtoListApiResponse>;

    /**
     * 
     * @param {string} awardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwardApiInterface
     */
    apiAwardAwardIdGetRaw(requestParameters: ApiAwardAwardIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AwardDtoApiResponse>>;

    /**
     */
    apiAwardAwardIdGet(awardId: string, initOverrides?: RequestInit): Promise<AwardDtoApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwardApiInterface
     */
    apiAwardIdDeleteRaw(requestParameters: ApiAwardIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAwardIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {AddAwardCommand} [addAwardCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwardApiInterface
     */
    apiAwardPostRaw(requestParameters: ApiAwardPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAwardPost(addAwardCommand?: AddAwardCommand, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {UpdateAwardCommand} [updateAwardCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwardApiInterface
     */
    apiAwardPutRaw(requestParameters: ApiAwardPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAwardPut(updateAwardCommand?: UpdateAwardCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class AwardApi extends runtime.BaseAPI implements AwardApiInterface {

    /**
     */
    async apiAwardAllUserIdGetRaw(requestParameters: ApiAwardAllUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AwardDtoListApiResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiAwardAllUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Award/all/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAwardAllUserIdGet(userId: string, initOverrides?: RequestInit): Promise<AwardDtoListApiResponse> {
        const response = await this.apiAwardAllUserIdGetRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAwardAwardIdGetRaw(requestParameters: ApiAwardAwardIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AwardDtoApiResponse>> {
        if (requestParameters.awardId === null || requestParameters.awardId === undefined) {
            throw new runtime.RequiredError('awardId','Required parameter requestParameters.awardId was null or undefined when calling apiAwardAwardIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Award/{awardId}`.replace(`{${"awardId"}}`, encodeURIComponent(String(requestParameters.awardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAwardAwardIdGet(awardId: string, initOverrides?: RequestInit): Promise<AwardDtoApiResponse> {
        const response = await this.apiAwardAwardIdGetRaw({ awardId: awardId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAwardIdDeleteRaw(requestParameters: ApiAwardIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAwardIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Award/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAwardIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiAwardIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiAwardPostRaw(requestParameters: ApiAwardPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Award`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAwardCommandToJSON(requestParameters.addAwardCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAwardPost(addAwardCommand?: AddAwardCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiAwardPostRaw({ addAwardCommand: addAwardCommand }, initOverrides);
    }

    /**
     */
    async apiAwardPutRaw(requestParameters: ApiAwardPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Award`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAwardCommandToJSON(requestParameters.updateAwardCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAwardPut(updateAwardCommand?: UpdateAwardCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiAwardPutRaw({ updateAwardCommand: updateAwardCommand }, initOverrides);
    }

}
