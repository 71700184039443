/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserFileDto
 */
export interface UserFileDto {
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserFileDto
     */
    onboarding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFileDto
     */
    adminViewOnly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserFileDto
     */
    fileCategoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    fileCategoryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    dateUploaded?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    uploadedFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    originalFileName?: string | null;
}

export function UserFileDtoFromJSON(json: any): UserFileDto {
    return UserFileDtoFromJSONTyped(json, false);
}

export function UserFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'onboarding': !exists(json, 'onboarding') ? undefined : json['onboarding'],
        'adminViewOnly': !exists(json, 'adminViewOnly') ? undefined : json['adminViewOnly'],
        'fileCategoryId': !exists(json, 'fileCategoryId') ? undefined : json['fileCategoryId'],
        'fileCategoryName': !exists(json, 'fileCategoryName') ? undefined : json['fileCategoryName'],
        'dateUploaded': !exists(json, 'dateUploaded') ? undefined : json['dateUploaded'],
        'uploadedFileId': !exists(json, 'uploadedFileId') ? undefined : json['uploadedFileId'],
        'originalFileName': !exists(json, 'originalFileName') ? undefined : json['originalFileName'],
    };
}

export function UserFileDtoToJSON(value?: UserFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'onboarding': value.onboarding,
        'adminViewOnly': value.adminViewOnly,
        'fileCategoryId': value.fileCategoryId,
        'fileCategoryName': value.fileCategoryName,
        'dateUploaded': value.dateUploaded,
        'uploadedFileId': value.uploadedFileId,
        'originalFileName': value.originalFileName,
    };
}

