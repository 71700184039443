/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAllQualificationsQuery,
    GetAllQualificationsQueryFromJSON,
    GetAllQualificationsQueryToJSON,
    QualificationDtoApiResponse,
    QualificationDtoApiResponseFromJSON,
    QualificationDtoApiResponseToJSON,
    QualificationDtoListApiResponse,
    QualificationDtoListApiResponseFromJSON,
    QualificationDtoListApiResponseToJSON,
} from '../models';

export interface ApiQualificationAllPostRequest {
    getAllQualificationsQuery?: GetAllQualificationsQuery;
}

export interface ApiQualificationCertificateCertificateIdGetRequest {
    certificateId: string;
}

export interface ApiQualificationIdDeleteRequest {
    id: string;
}

export interface ApiQualificationIdGetRequest {
    id: string;
}

export interface ApiQualificationPostRequest {
    name?: string;
    result?: string;
    provider?: string;
    dateAchieved?: Date;
    userId?: string;
    qualificationFile?: Blob;
}

export interface ApiQualificationPutRequest {
    id?: string;
    name?: string;
    result?: string;
    provider?: string;
    dateAchieved?: Date;
    qualificationFile?: Blob;
}

/**
 * QualificationApi - interface
 * 
 * @export
 * @interface QualificationApiInterface
 */
export interface QualificationApiInterface {
    /**
     * 
     * @param {GetAllQualificationsQuery} [getAllQualificationsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationApiInterface
     */
    apiQualificationAllPostRaw(requestParameters: ApiQualificationAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QualificationDtoListApiResponse>>;

    /**
     */
    apiQualificationAllPost(getAllQualificationsQuery?: GetAllQualificationsQuery, initOverrides?: RequestInit): Promise<QualificationDtoListApiResponse>;

    /**
     * 
     * @param {string} certificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationApiInterface
     */
    apiQualificationCertificateCertificateIdGetRaw(requestParameters: ApiQualificationCertificateCertificateIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiQualificationCertificateCertificateIdGet(certificateId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationApiInterface
     */
    apiQualificationIdDeleteRaw(requestParameters: ApiQualificationIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiQualificationIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationApiInterface
     */
    apiQualificationIdGetRaw(requestParameters: ApiQualificationIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QualificationDtoApiResponse>>;

    /**
     */
    apiQualificationIdGet(id: string, initOverrides?: RequestInit): Promise<QualificationDtoApiResponse>;

    /**
     * 
     * @param {string} [name] 
     * @param {string} [result] 
     * @param {string} [provider] 
     * @param {Date} [dateAchieved] 
     * @param {string} [userId] 
     * @param {Blob} [qualificationFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationApiInterface
     */
    apiQualificationPostRaw(requestParameters: ApiQualificationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QualificationDtoApiResponse>>;

    /**
     */
    apiQualificationPost(name?: string, result?: string, provider?: string, dateAchieved?: Date, userId?: string, qualificationFile?: Blob, initOverrides?: RequestInit): Promise<QualificationDtoApiResponse>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [result] 
     * @param {string} [provider] 
     * @param {Date} [dateAchieved] 
     * @param {Blob} [qualificationFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationApiInterface
     */
    apiQualificationPutRaw(requestParameters: ApiQualificationPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiQualificationPut(id?: string, name?: string, result?: string, provider?: string, dateAchieved?: Date, qualificationFile?: Blob, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class QualificationApi extends runtime.BaseAPI implements QualificationApiInterface {

    /**
     */
    async apiQualificationAllPostRaw(requestParameters: ApiQualificationAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QualificationDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Qualification/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllQualificationsQueryToJSON(requestParameters.getAllQualificationsQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QualificationDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiQualificationAllPost(getAllQualificationsQuery?: GetAllQualificationsQuery, initOverrides?: RequestInit): Promise<QualificationDtoListApiResponse> {
        const response = await this.apiQualificationAllPostRaw({ getAllQualificationsQuery: getAllQualificationsQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiQualificationCertificateCertificateIdGetRaw(requestParameters: ApiQualificationCertificateCertificateIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.certificateId === null || requestParameters.certificateId === undefined) {
            throw new runtime.RequiredError('certificateId','Required parameter requestParameters.certificateId was null or undefined when calling apiQualificationCertificateCertificateIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Qualification/certificate/{certificateId}`.replace(`{${"certificateId"}}`, encodeURIComponent(String(requestParameters.certificateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiQualificationCertificateCertificateIdGet(certificateId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiQualificationCertificateCertificateIdGetRaw({ certificateId: certificateId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiQualificationIdDeleteRaw(requestParameters: ApiQualificationIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiQualificationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Qualification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiQualificationIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiQualificationIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiQualificationIdGetRaw(requestParameters: ApiQualificationIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QualificationDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiQualificationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Qualification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QualificationDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiQualificationIdGet(id: string, initOverrides?: RequestInit): Promise<QualificationDtoApiResponse> {
        const response = await this.apiQualificationIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiQualificationPostRaw(requestParameters: ApiQualificationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QualificationDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.result !== undefined) {
            formParams.append('Result', requestParameters.result as any);
        }

        if (requestParameters.provider !== undefined) {
            formParams.append('Provider', requestParameters.provider as any);
        }

        if (requestParameters.dateAchieved !== undefined) {
            formParams.append('DateAchieved', requestParameters.dateAchieved as any);
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('UserId', requestParameters.userId as any);
        }

        if (requestParameters.qualificationFile !== undefined) {
            formParams.append('QualificationFile', requestParameters.qualificationFile as any);
        }

        const response = await this.request({
            path: `/api/Qualification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QualificationDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiQualificationPost(name?: string, result?: string, provider?: string, dateAchieved?: Date, userId?: string, qualificationFile?: Blob, initOverrides?: RequestInit): Promise<QualificationDtoApiResponse> {
        const response = await this.apiQualificationPostRaw({ name: name, result: result, provider: provider, dateAchieved: dateAchieved, userId: userId, qualificationFile: qualificationFile }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiQualificationPutRaw(requestParameters: ApiQualificationPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.result !== undefined) {
            formParams.append('Result', requestParameters.result as any);
        }

        if (requestParameters.provider !== undefined) {
            formParams.append('Provider', requestParameters.provider as any);
        }

        if (requestParameters.dateAchieved !== undefined) {
            formParams.append('DateAchieved', requestParameters.dateAchieved as any);
        }

        if (requestParameters.qualificationFile !== undefined) {
            formParams.append('QualificationFile', requestParameters.qualificationFile as any);
        }

        const response = await this.request({
            path: `/api/Qualification`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiQualificationPut(id?: string, name?: string, result?: string, provider?: string, dateAchieved?: Date, qualificationFile?: Blob, initOverrides?: RequestInit): Promise<void> {
        await this.apiQualificationPutRaw({ id: id, name: name, result: result, provider: provider, dateAchieved: dateAchieved, qualificationFile: qualificationFile }, initOverrides);
    }

}
