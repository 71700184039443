import { BASE_PATH, LOCAL_STORAGE_TOKEN } from "@/shared/config/const";
import {
  ForgotPassword,
  Login,
  ResetPassword,
  RoleDto,
  SetPassword,
  UserDto,
} from "../models";
import { ApplicationUserApi, AuthApi, Configuration } from "@/models";
import { useLocalStorage } from "@vueuse/core";
import { useToast } from "balm-ui";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { usePoliciesStore } from "@/stores/policiesStore";
import { errorToast, successToast } from "@/shared/composables/toast";
import jwtDecode from "jwt-decode";
import {
  isEmpty,
  isTokenExpired,
  isTokenNullOrEmpty,
} from "@/shared/composables/guards";
import { resetStores } from "@/shared/composables/reset-stores";

export interface Option {
  label: string | null | undefined;
  value: string | number | null | undefined;
}

export const useAuthStore = defineStore("user", () => {
  // State
  const token = ref(useLocalStorage(LOCAL_STORAGE_TOKEN, ""));
  const decodedToken = ref({} as any);
  const user = ref<UserDto>({} as UserDto);
  const roles = ref([] as RoleDto[] | null | undefined);
  const userPoliciesApproved = ref<boolean>(false);
  const toast = useToast();
  const resetConfirmed = ref(false);
  const resetSuccessful = ref(false);
  const setSuccessful = ref(false);
  const isLoading = ref(false);

  // Computed Properties
  const api = computed(() => {
    return new AuthApi(
      new Configuration({
        basePath: BASE_PATH,
        headers: { Authorization: "Bearer " + token.value },
      })
    );
  });
  const userApi = computed(() => {
    return new ApplicationUserApi(
      new Configuration({
        basePath: BASE_PATH,
        headers: { Authorization: "Bearer " + token.value },
      })
    );
  });
  const userId = computed(() => decodedToken.value.uid);
  const userName = computed(() => userFullNameOrEmail());
  const userRole = computed(
    (): string =>
      decodedToken.value[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ] ?? ""
  );
  const isTokenValid = computed(() => validateToken());
  const isLoggedIn = ref(false);
  const rolesAsOptions = computed(() => rolesToOptions());

  // Functions
  function userFullNameOrEmail() {
    if (user.value.firstName && user.value.lastName) {
      return `${user.value.firstName} ${user.value.lastName}`;
    }
    return decodedToken.value.email;
  }

  function validateToken() {
    if (!token.value) return false;
    if (isEmpty(decodedToken.value)) {
      decodedToken.value = jwtDecode(token.value);
    }
    return (
      !isTokenNullOrEmpty(decodedToken.value) &&
      !isTokenExpired(decodedToken.value)
    );
  }

  function rolesToOptions(): Option[] {
    const options = [] as Option[];
    roles.value?.forEach((x) => {
      options.push({
        label: x.name,
        value: x.id,
      });
    });
    return options;
  }

  async function handleApiError(error: any) {
    await errorToast(error);
  }

  async function login(login: Login) {
    isLoading.value = true;
    try {
      const response = await api.value.apiAuthLoginPost(login);

      if (response?.data?.accessToken) {
        token.value = response.data.accessToken;
        decodedToken.value = jwtDecode(response.data.accessToken);
        isLoggedIn.value = true;
        await getMe();
        await getNotApprovedPolicies();
      }
      return response;
    } catch (error) {
      await handleApiError(error);
    } finally {
      isLoading.value = false;
    }
  }

  async function getMe() {
    try {
      const response = await userApi.value.apiUserMeGet();
      if (response.succeeded && response.data != undefined) {
        user.value = response.data;
      }
      return response;
    } catch (error) {
      await handleApiError(error);
    }
  }

  function logout() {
    localStorage.clear();
    token.value = "";
    user.value = {};
    decodedToken.value = {};
    resetStores();
  }

  async function getRoles() {
    return await userApi.value
      .apiUserRolesGet()
      .then((response) => {
        if (response.succeeded && response.data != undefined) {
          roles.value = response.data;
        }
      })
      .catch(async (error) => {
        await errorToast(error);
      });
  }

  async function getNotApprovedPolicies() {
    if (userId.value) {
      const policiesStore = usePoliciesStore();
      await policiesStore.getNotApprovedPolicies(userId.value);
    }
  }

  async function forgotPassword(command: ForgotPassword) {
    await api.value
      .apiAuthForgotPasswordPost(command)
      .then(() => {
        resetConfirmed.value = true;
      })
      .catch(async (error) => {
        await errorToast(error);
      });
  }

  async function resetPassword(command: ResetPassword) {
    await api.value
      .apiAuthResetPasswordPost(command)
      .then(() => {
        resetSuccessful.value = true;
      })
      .catch(async (error) => {
        await errorToast(error);
      });
  }

  async function setPassword(command: SetPassword) {
    await api.value
      .apiAuthSetPasswordPost(command)
      .then(() => {
        setSuccessful.value = true;
      })
      .catch(async (error) => {
        await errorToast(error);
      });
  }

  async function confirmRegistration(
    userId: string,
    emailAddress: string,
    password: string,
    confirmPassword: string
  ) {
    await api.value
      .apiAuthConfirmRegistrationPost({
        userId: userId,
        emailAddress: emailAddress,
        password: password,
        confirmPassword: confirmPassword,
      })
      .then(() => {
        successToast();
      })
      .catch(async (error) => {
        await errorToast(error);
      });
  }

  return {
    decodedToken,
    user,
    isLoggedIn,
    token,
    roles,
    rolesAsOptions,
    userPoliciesApproved,
    userId,
    userName,
    toast,
    getNotApprovedPolicies,
    logout,
    getRoles,
    forgotPassword,
    resetPassword,
    setPassword,
    login,
    getMe,
    confirmRegistration,
    isTokenValid,
    userRole,
    resetConfirmed,
    resetSuccessful,
    setSuccessful,
    isLoading,
  };
});
