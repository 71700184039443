/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StringIdentityUserLogin
 */
export interface StringIdentityUserLogin {
    /**
     * 
     * @type {string}
     * @memberof StringIdentityUserLogin
     */
    loginProvider?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringIdentityUserLogin
     */
    providerKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringIdentityUserLogin
     */
    providerDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringIdentityUserLogin
     */
    userId?: string | null;
}

export function StringIdentityUserLoginFromJSON(json: any): StringIdentityUserLogin {
    return StringIdentityUserLoginFromJSONTyped(json, false);
}

export function StringIdentityUserLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringIdentityUserLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loginProvider': !exists(json, 'loginProvider') ? undefined : json['loginProvider'],
        'providerKey': !exists(json, 'providerKey') ? undefined : json['providerKey'],
        'providerDisplayName': !exists(json, 'providerDisplayName') ? undefined : json['providerDisplayName'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function StringIdentityUserLoginToJSON(value?: StringIdentityUserLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'loginProvider': value.loginProvider,
        'providerKey': value.providerKey,
        'providerDisplayName': value.providerDisplayName,
        'userId': value.userId,
    };
}

