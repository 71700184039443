/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddPolicyCommand,
    AddPolicyCommandFromJSON,
    AddPolicyCommandToJSON,
    GetPagedByUserIdPolicyQuery,
    GetPagedByUserIdPolicyQueryFromJSON,
    GetPagedByUserIdPolicyQueryToJSON,
    GetPagedWithFiltersPolicyQuery,
    GetPagedWithFiltersPolicyQueryFromJSON,
    GetPagedWithFiltersPolicyQueryToJSON,
    GetUserPoliciesByPolicyIdQuery,
    GetUserPoliciesByPolicyIdQueryFromJSON,
    GetUserPoliciesByPolicyIdQueryToJSON,
    PoliciesUserPolicyViewModelPagedResultApiResponse,
    PoliciesUserPolicyViewModelPagedResultApiResponseFromJSON,
    PoliciesUserPolicyViewModelPagedResultApiResponseToJSON,
    PolicyDtoApiResponse,
    PolicyDtoApiResponseFromJSON,
    PolicyDtoApiResponseToJSON,
    PolicyDtoPagedResultApiResponse,
    PolicyDtoPagedResultApiResponseFromJSON,
    PolicyDtoPagedResultApiResponseToJSON,
    UpdatePolicyCommand,
    UpdatePolicyCommandFromJSON,
    UpdatePolicyCommandToJSON,
    UserPolicyViewModelListApiResponse,
    UserPolicyViewModelListApiResponseFromJSON,
    UserPolicyViewModelListApiResponseToJSON,
    UserPolicyViewModelPagedResultApiResponse,
    UserPolicyViewModelPagedResultApiResponseFromJSON,
    UserPolicyViewModelPagedResultApiResponseToJSON,
} from '../models';

export interface ApiPolicyAllPagedPolicyPoliciesPostRequest {
    getUserPoliciesByPolicyIdQuery?: GetUserPoliciesByPolicyIdQuery;
}

export interface ApiPolicyAllPagedPostRequest {
    getPagedWithFiltersPolicyQuery?: GetPagedWithFiltersPolicyQuery;
}

export interface ApiPolicyAllPagedUserPostRequest {
    getPagedByUserIdPolicyQuery?: GetPagedByUserIdPolicyQuery;
}

export interface ApiPolicyApproveIdPutRequest {
    id: string;
}

export interface ApiPolicyIdDeleteRequest {
    id: string;
}

export interface ApiPolicyIdGetRequest {
    id: string;
}

export interface ApiPolicyPendingIdGetRequest {
    id: string;
}

export interface ApiPolicyPostRequest {
    addPolicyCommand?: AddPolicyCommand;
}

export interface ApiPolicyPutRequest {
    updatePolicyCommand?: UpdatePolicyCommand;
}

/**
 * PolicyApi - interface
 * 
 * @export
 * @interface PolicyApiInterface
 */
export interface PolicyApiInterface {
    /**
     * 
     * @param {GetUserPoliciesByPolicyIdQuery} [getUserPoliciesByPolicyIdQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyAllPagedPolicyPoliciesPostRaw(requestParameters: ApiPolicyAllPagedPolicyPoliciesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesUserPolicyViewModelPagedResultApiResponse>>;

    /**
     */
    apiPolicyAllPagedPolicyPoliciesPost(getUserPoliciesByPolicyIdQuery?: GetUserPoliciesByPolicyIdQuery, initOverrides?: RequestInit): Promise<PoliciesUserPolicyViewModelPagedResultApiResponse>;

    /**
     * 
     * @param {GetPagedWithFiltersPolicyQuery} [getPagedWithFiltersPolicyQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyAllPagedPostRaw(requestParameters: ApiPolicyAllPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyDtoPagedResultApiResponse>>;

    /**
     */
    apiPolicyAllPagedPost(getPagedWithFiltersPolicyQuery?: GetPagedWithFiltersPolicyQuery, initOverrides?: RequestInit): Promise<PolicyDtoPagedResultApiResponse>;

    /**
     * 
     * @param {GetPagedByUserIdPolicyQuery} [getPagedByUserIdPolicyQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyAllPagedUserPostRaw(requestParameters: ApiPolicyAllPagedUserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPolicyViewModelPagedResultApiResponse>>;

    /**
     */
    apiPolicyAllPagedUserPost(getPagedByUserIdPolicyQuery?: GetPagedByUserIdPolicyQuery, initOverrides?: RequestInit): Promise<UserPolicyViewModelPagedResultApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyApproveIdPutRaw(requestParameters: ApiPolicyApproveIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiPolicyApproveIdPut(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyIdDeleteRaw(requestParameters: ApiPolicyIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiPolicyIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyIdGetRaw(requestParameters: ApiPolicyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyDtoApiResponse>>;

    /**
     */
    apiPolicyIdGet(id: string, initOverrides?: RequestInit): Promise<PolicyDtoApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyPendingIdGetRaw(requestParameters: ApiPolicyPendingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPolicyViewModelListApiResponse>>;

    /**
     */
    apiPolicyPendingIdGet(id: string, initOverrides?: RequestInit): Promise<UserPolicyViewModelListApiResponse>;

    /**
     * 
     * @param {AddPolicyCommand} [addPolicyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyPostRaw(requestParameters: ApiPolicyPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyDtoApiResponse>>;

    /**
     */
    apiPolicyPost(addPolicyCommand?: AddPolicyCommand, initOverrides?: RequestInit): Promise<PolicyDtoApiResponse>;

    /**
     * 
     * @param {UpdatePolicyCommand} [updatePolicyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiInterface
     */
    apiPolicyPutRaw(requestParameters: ApiPolicyPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiPolicyPut(updatePolicyCommand?: UpdatePolicyCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class PolicyApi extends runtime.BaseAPI implements PolicyApiInterface {

    /**
     */
    async apiPolicyAllPagedPolicyPoliciesPostRaw(requestParameters: ApiPolicyAllPagedPolicyPoliciesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesUserPolicyViewModelPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy/all/paged/policy/policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUserPoliciesByPolicyIdQueryToJSON(requestParameters.getUserPoliciesByPolicyIdQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesUserPolicyViewModelPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyAllPagedPolicyPoliciesPost(getUserPoliciesByPolicyIdQuery?: GetUserPoliciesByPolicyIdQuery, initOverrides?: RequestInit): Promise<PoliciesUserPolicyViewModelPagedResultApiResponse> {
        const response = await this.apiPolicyAllPagedPolicyPoliciesPostRaw({ getUserPoliciesByPolicyIdQuery: getUserPoliciesByPolicyIdQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyAllPagedPostRaw(requestParameters: ApiPolicyAllPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy/all/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPagedWithFiltersPolicyQueryToJSON(requestParameters.getPagedWithFiltersPolicyQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyAllPagedPost(getPagedWithFiltersPolicyQuery?: GetPagedWithFiltersPolicyQuery, initOverrides?: RequestInit): Promise<PolicyDtoPagedResultApiResponse> {
        const response = await this.apiPolicyAllPagedPostRaw({ getPagedWithFiltersPolicyQuery: getPagedWithFiltersPolicyQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyAllPagedUserPostRaw(requestParameters: ApiPolicyAllPagedUserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPolicyViewModelPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy/all/paged/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPagedByUserIdPolicyQueryToJSON(requestParameters.getPagedByUserIdPolicyQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPolicyViewModelPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyAllPagedUserPost(getPagedByUserIdPolicyQuery?: GetPagedByUserIdPolicyQuery, initOverrides?: RequestInit): Promise<UserPolicyViewModelPagedResultApiResponse> {
        const response = await this.apiPolicyAllPagedUserPostRaw({ getPagedByUserIdPolicyQuery: getPagedByUserIdPolicyQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyApproveIdPutRaw(requestParameters: ApiPolicyApproveIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPolicyApproveIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPolicyApproveIdPut(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiPolicyApproveIdPutRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiPolicyIdDeleteRaw(requestParameters: ApiPolicyIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPolicyIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPolicyIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiPolicyIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiPolicyIdGetRaw(requestParameters: ApiPolicyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPolicyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyIdGet(id: string, initOverrides?: RequestInit): Promise<PolicyDtoApiResponse> {
        const response = await this.apiPolicyIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyPendingIdGetRaw(requestParameters: ApiPolicyPendingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPolicyViewModelListApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPolicyPendingIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy/pending/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPolicyViewModelListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyPendingIdGet(id: string, initOverrides?: RequestInit): Promise<UserPolicyViewModelListApiResponse> {
        const response = await this.apiPolicyPendingIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyPostRaw(requestParameters: ApiPolicyPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPolicyCommandToJSON(requestParameters.addPolicyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyPost(addPolicyCommand?: AddPolicyCommand, initOverrides?: RequestInit): Promise<PolicyDtoApiResponse> {
        const response = await this.apiPolicyPostRaw({ addPolicyCommand: addPolicyCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyPutRaw(requestParameters: ApiPolicyPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Policy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePolicyCommandToJSON(requestParameters.updatePolicyCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPolicyPut(updatePolicyCommand?: UpdatePolicyCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiPolicyPutRaw({ updatePolicyCommand: updatePolicyCommand }, initOverrides);
    }

}
