export enum AbilityPermission {
  AddAward,
  AddPolicy,
  AddDisciplinary,
  AddPerformanceFile,
  AddQualification,
  AddTraining,
  AddToDo,
  AddUser,
  ArchiveUser,
  ChangePassword,
  ConfirmUser,
  DeleteQualification,
  DeleteTraining,
  DeleteUser,
  DisableUser,
  EditJobTitle,
  EditQualification,
  EditShiftPatterns,
  EditTraining,
  EditUser,
  EditStartDate,
  EditPolicy,
  UploadFile,
  UploadPerformanceFile,
}
