/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ShiftDayDtoListApiResponse,
    ShiftDayDtoListApiResponseFromJSON,
    ShiftDayDtoListApiResponseToJSON,
    UpdateShiftDayCommand,
    UpdateShiftDayCommandFromJSON,
    UpdateShiftDayCommandToJSON,
} from '../models';

export interface ApiShiftDayIdGetRequest {
    id: string;
}

export interface ApiShiftDayPatternUserIdGetRequest {
    userId: string;
}

export interface ApiShiftDayPutRequest {
    updateShiftDayCommand?: UpdateShiftDayCommand;
}

/**
 * ShiftDayApi - interface
 * 
 * @export
 * @interface ShiftDayApiInterface
 */
export interface ShiftDayApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftDayApiInterface
     */
    apiShiftDayIdGetRaw(requestParameters: ApiShiftDayIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiShiftDayIdGet(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftDayApiInterface
     */
    apiShiftDayPatternUserIdGetRaw(requestParameters: ApiShiftDayPatternUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShiftDayDtoListApiResponse>>;

    /**
     */
    apiShiftDayPatternUserIdGet(userId: string, initOverrides?: RequestInit): Promise<ShiftDayDtoListApiResponse>;

    /**
     * 
     * @param {UpdateShiftDayCommand} [updateShiftDayCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftDayApiInterface
     */
    apiShiftDayPutRaw(requestParameters: ApiShiftDayPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiShiftDayPut(updateShiftDayCommand?: UpdateShiftDayCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class ShiftDayApi extends runtime.BaseAPI implements ShiftDayApiInterface {

    /**
     */
    async apiShiftDayIdGetRaw(requestParameters: ApiShiftDayIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShiftDayIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ShiftDay/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShiftDayIdGet(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiShiftDayIdGetRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiShiftDayPatternUserIdGetRaw(requestParameters: ApiShiftDayPatternUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShiftDayDtoListApiResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiShiftDayPatternUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ShiftDay/pattern/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftDayDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiShiftDayPatternUserIdGet(userId: string, initOverrides?: RequestInit): Promise<ShiftDayDtoListApiResponse> {
        const response = await this.apiShiftDayPatternUserIdGetRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShiftDayPutRaw(requestParameters: ApiShiftDayPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ShiftDay`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShiftDayCommandToJSON(requestParameters.updateShiftDayCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShiftDayPut(updateShiftDayCommand?: UpdateShiftDayCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiShiftDayPutRaw({ updateShiftDayCommand: updateShiftDayCommand }, initOverrides);
    }

}
