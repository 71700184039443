/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ToDoDtoPagedResult,
    ToDoDtoPagedResultFromJSON,
    ToDoDtoPagedResultFromJSONTyped,
    ToDoDtoPagedResultToJSON,
} from './ToDoDtoPagedResult';

/**
 * 
 * @export
 * @interface ToDoDtoPagedResultApiResponse
 */
export interface ToDoDtoPagedResultApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ToDoDtoPagedResultApiResponse
     */
    succeeded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ToDoDtoPagedResultApiResponse
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToDoDtoPagedResultApiResponse
     */
    errors?: Array<string> | null;
    /**
     * 
     * @type {ToDoDtoPagedResult}
     * @memberof ToDoDtoPagedResultApiResponse
     */
    data?: ToDoDtoPagedResult;
}

export function ToDoDtoPagedResultApiResponseFromJSON(json: any): ToDoDtoPagedResultApiResponse {
    return ToDoDtoPagedResultApiResponseFromJSONTyped(json, false);
}

export function ToDoDtoPagedResultApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToDoDtoPagedResultApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'data': !exists(json, 'data') ? undefined : ToDoDtoPagedResultFromJSON(json['data']),
    };
}

export function ToDoDtoPagedResultApiResponseToJSON(value?: ToDoDtoPagedResultApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'succeeded': value.succeeded,
        'message': value.message,
        'errors': value.errors,
        'data': ToDoDtoPagedResultToJSON(value.data),
    };
}

