/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangePassword,
    ChangePasswordFromJSON,
    ChangePasswordToJSON,
    ConfirmRegistrationCommand,
    ConfirmRegistrationCommandFromJSON,
    ConfirmRegistrationCommandToJSON,
    ForgotPassword,
    ForgotPasswordFromJSON,
    ForgotPasswordToJSON,
    JwtDtoApiResponse,
    JwtDtoApiResponseFromJSON,
    JwtDtoApiResponseToJSON,
    Login,
    LoginFromJSON,
    LoginToJSON,
    ResetPassword,
    ResetPasswordFromJSON,
    ResetPasswordToJSON,
    SetPassword,
    SetPasswordFromJSON,
    SetPasswordToJSON,
} from '../models';

export interface ApiAuthChangePasswordPostRequest {
    changePassword?: ChangePassword;
}

export interface ApiAuthConfirmRegistrationPostRequest {
    confirmRegistrationCommand?: ConfirmRegistrationCommand;
}

export interface ApiAuthForgotPasswordPostRequest {
    forgotPassword?: ForgotPassword;
}

export interface ApiAuthLoginPostRequest {
    login?: Login;
}

export interface ApiAuthResetPasswordPostRequest {
    resetPassword?: ResetPassword;
}

export interface ApiAuthSetPasswordPostRequest {
    setPassword?: SetPassword;
}

/**
 * AuthApi - interface
 * 
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     * 
     * @param {ChangePassword} [changePassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAuthChangePasswordPostRaw(requestParameters: ApiAuthChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAuthChangePasswordPost(changePassword?: ChangePassword, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {ConfirmRegistrationCommand} [confirmRegistrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAuthConfirmRegistrationPostRaw(requestParameters: ApiAuthConfirmRegistrationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAuthConfirmRegistrationPost(confirmRegistrationCommand?: ConfirmRegistrationCommand, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {ForgotPassword} [forgotPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAuthForgotPasswordPostRaw(requestParameters: ApiAuthForgotPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAuthForgotPasswordPost(forgotPassword?: ForgotPassword, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {Login} [login] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAuthLoginPostRaw(requestParameters: ApiAuthLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JwtDtoApiResponse>>;

    /**
     */
    apiAuthLoginPost(login?: Login, initOverrides?: RequestInit): Promise<JwtDtoApiResponse>;

    /**
     * 
     * @param {ResetPassword} [resetPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAuthResetPasswordPostRaw(requestParameters: ApiAuthResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAuthResetPasswordPost(resetPassword?: ResetPassword, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {SetPassword} [setPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAuthSetPasswordPostRaw(requestParameters: ApiAuthSetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiAuthSetPasswordPost(setPassword?: SetPassword, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {

    /**
     */
    async apiAuthChangePasswordPostRaw(requestParameters: ApiAuthChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordToJSON(requestParameters.changePassword),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthChangePasswordPost(changePassword?: ChangePassword, initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthChangePasswordPostRaw({ changePassword: changePassword }, initOverrides);
    }

    /**
     */
    async apiAuthConfirmRegistrationPostRaw(requestParameters: ApiAuthConfirmRegistrationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/confirm-registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmRegistrationCommandToJSON(requestParameters.confirmRegistrationCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthConfirmRegistrationPost(confirmRegistrationCommand?: ConfirmRegistrationCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthConfirmRegistrationPostRaw({ confirmRegistrationCommand: confirmRegistrationCommand }, initOverrides);
    }

    /**
     */
    async apiAuthForgotPasswordPostRaw(requestParameters: ApiAuthForgotPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordToJSON(requestParameters.forgotPassword),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthForgotPasswordPost(forgotPassword?: ForgotPassword, initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthForgotPasswordPostRaw({ forgotPassword: forgotPassword }, initOverrides);
    }

    /**
     */
    async apiAuthLoginPostRaw(requestParameters: ApiAuthLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JwtDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginToJSON(requestParameters.login),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JwtDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthLoginPost(login?: Login, initOverrides?: RequestInit): Promise<JwtDtoApiResponse> {
        const response = await this.apiAuthLoginPostRaw({ login: login }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthResetPasswordPostRaw(requestParameters: ApiAuthResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordToJSON(requestParameters.resetPassword),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthResetPasswordPost(resetPassword?: ResetPassword, initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthResetPasswordPostRaw({ resetPassword: resetPassword }, initOverrides);
    }

    /**
     */
    async apiAuthSetPasswordPostRaw(requestParameters: ApiAuthSetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/set-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPasswordToJSON(requestParameters.setPassword),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthSetPasswordPost(setPassword?: SetPassword, initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthSetPasswordPostRaw({ setPassword: setPassword }, initOverrides);
    }

}
