/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPolicyViewModel,
    UserPolicyViewModelFromJSON,
    UserPolicyViewModelFromJSONTyped,
    UserPolicyViewModelToJSON,
} from './UserPolicyViewModel';

/**
 * 
 * @export
 * @interface UserPolicyViewModelPagedResult
 */
export interface UserPolicyViewModelPagedResult {
    /**
     * 
     * @type {Array<UserPolicyViewModel>}
     * @memberof UserPolicyViewModelPagedResult
     */
    items?: Array<UserPolicyViewModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserPolicyViewModelPagedResult
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPolicyViewModelPagedResult
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPolicyViewModelPagedResult
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPolicyViewModelPagedResult
     */
    totalCount?: number;
}

export function UserPolicyViewModelPagedResultFromJSON(json: any): UserPolicyViewModelPagedResult {
    return UserPolicyViewModelPagedResultFromJSONTyped(json, false);
}

export function UserPolicyViewModelPagedResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPolicyViewModelPagedResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(UserPolicyViewModelFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function UserPolicyViewModelPagedResultToJSON(value?: UserPolicyViewModelPagedResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(UserPolicyViewModelToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalPages': value.totalPages,
        'totalCount': value.totalCount,
    };
}

