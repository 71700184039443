/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DisciplinaryDtoApiResponse,
    DisciplinaryDtoApiResponseFromJSON,
    DisciplinaryDtoApiResponseToJSON,
    DisciplinaryDtoPagedResultApiResponse,
    DisciplinaryDtoPagedResultApiResponseFromJSON,
    DisciplinaryDtoPagedResultApiResponseToJSON,
    GetAllPagedByUserIdDisciplinariesQuery,
    GetAllPagedByUserIdDisciplinariesQueryFromJSON,
    GetAllPagedByUserIdDisciplinariesQueryToJSON,
    GetAllPagedDisciplinariesQuery,
    GetAllPagedDisciplinariesQueryFromJSON,
    GetAllPagedDisciplinariesQueryToJSON,
} from '../models';

export interface ApiDisciplinaryFileFileIdGetRequest {
    fileId: string;
}

export interface ApiDisciplinaryIdGetRequest {
    id: number;
}

export interface ApiDisciplinaryPagedPostRequest {
    getAllPagedDisciplinariesQuery?: GetAllPagedDisciplinariesQuery;
}

export interface ApiDisciplinaryPostRequest {
    date?: Date;
    description?: string;
    disciplinaryTypeId?: number;
    managerId?: string;
    userId?: string;
    employeeSignature?: string;
    managerSignature?: string;
    files?: Array<Blob>;
}

export interface ApiDisciplinaryUserPagedPostRequest {
    getAllPagedByUserIdDisciplinariesQuery?: GetAllPagedByUserIdDisciplinariesQuery;
}

/**
 * DisciplinaryApi - interface
 * 
 * @export
 * @interface DisciplinaryApiInterface
 */
export interface DisciplinaryApiInterface {
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryApiInterface
     */
    apiDisciplinaryFileFileIdGetRaw(requestParameters: ApiDisciplinaryFileFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiDisciplinaryFileFileIdGet(fileId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryApiInterface
     */
    apiDisciplinaryIdGetRaw(requestParameters: ApiDisciplinaryIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoApiResponse>>;

    /**
     */
    apiDisciplinaryIdGet(id: number, initOverrides?: RequestInit): Promise<DisciplinaryDtoApiResponse>;

    /**
     * 
     * @param {GetAllPagedDisciplinariesQuery} [getAllPagedDisciplinariesQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryApiInterface
     */
    apiDisciplinaryPagedPostRaw(requestParameters: ApiDisciplinaryPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoPagedResultApiResponse>>;

    /**
     */
    apiDisciplinaryPagedPost(getAllPagedDisciplinariesQuery?: GetAllPagedDisciplinariesQuery, initOverrides?: RequestInit): Promise<DisciplinaryDtoPagedResultApiResponse>;

    /**
     * 
     * @param {Date} [date] 
     * @param {string} [description] 
     * @param {number} [disciplinaryTypeId] 
     * @param {string} [managerId] 
     * @param {string} [userId] 
     * @param {string} [employeeSignature] 
     * @param {string} [managerSignature] 
     * @param {Array<Blob>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryApiInterface
     */
    apiDisciplinaryPostRaw(requestParameters: ApiDisciplinaryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoApiResponse>>;

    /**
     */
    apiDisciplinaryPost(date?: Date, description?: string, disciplinaryTypeId?: number, managerId?: string, userId?: string, employeeSignature?: string, managerSignature?: string, files?: Array<Blob>, initOverrides?: RequestInit): Promise<DisciplinaryDtoApiResponse>;

    /**
     * 
     * @param {GetAllPagedByUserIdDisciplinariesQuery} [getAllPagedByUserIdDisciplinariesQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisciplinaryApiInterface
     */
    apiDisciplinaryUserPagedPostRaw(requestParameters: ApiDisciplinaryUserPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoPagedResultApiResponse>>;

    /**
     */
    apiDisciplinaryUserPagedPost(getAllPagedByUserIdDisciplinariesQuery?: GetAllPagedByUserIdDisciplinariesQuery, initOverrides?: RequestInit): Promise<DisciplinaryDtoPagedResultApiResponse>;

}

/**
 * 
 */
export class DisciplinaryApi extends runtime.BaseAPI implements DisciplinaryApiInterface {

    /**
     */
    async apiDisciplinaryFileFileIdGetRaw(requestParameters: ApiDisciplinaryFileFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiDisciplinaryFileFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Disciplinary/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiDisciplinaryFileFileIdGet(fileId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiDisciplinaryFileFileIdGetRaw({ fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDisciplinaryIdGetRaw(requestParameters: ApiDisciplinaryIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDisciplinaryIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Disciplinary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisciplinaryDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDisciplinaryIdGet(id: number, initOverrides?: RequestInit): Promise<DisciplinaryDtoApiResponse> {
        const response = await this.apiDisciplinaryIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDisciplinaryPagedPostRaw(requestParameters: ApiDisciplinaryPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Disciplinary/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllPagedDisciplinariesQueryToJSON(requestParameters.getAllPagedDisciplinariesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisciplinaryDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDisciplinaryPagedPost(getAllPagedDisciplinariesQuery?: GetAllPagedDisciplinariesQuery, initOverrides?: RequestInit): Promise<DisciplinaryDtoPagedResultApiResponse> {
        const response = await this.apiDisciplinaryPagedPostRaw({ getAllPagedDisciplinariesQuery: getAllPagedDisciplinariesQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDisciplinaryPostRaw(requestParameters: ApiDisciplinaryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.date !== undefined) {
            formParams.append('Date', requestParameters.date as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.disciplinaryTypeId !== undefined) {
            formParams.append('DisciplinaryTypeId', requestParameters.disciplinaryTypeId as any);
        }

        if (requestParameters.managerId !== undefined) {
            formParams.append('ManagerId', requestParameters.managerId as any);
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('UserId', requestParameters.userId as any);
        }

        if (requestParameters.employeeSignature !== undefined) {
            formParams.append('EmployeeSignature', requestParameters.employeeSignature as any);
        }

        if (requestParameters.managerSignature !== undefined) {
            formParams.append('ManagerSignature', requestParameters.managerSignature as any);
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('Files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/Disciplinary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisciplinaryDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDisciplinaryPost(date?: Date, description?: string, disciplinaryTypeId?: number, managerId?: string, userId?: string, employeeSignature?: string, managerSignature?: string, files?: Array<Blob>, initOverrides?: RequestInit): Promise<DisciplinaryDtoApiResponse> {
        const response = await this.apiDisciplinaryPostRaw({ date: date, description: description, disciplinaryTypeId: disciplinaryTypeId, managerId: managerId, userId: userId, employeeSignature: employeeSignature, managerSignature: managerSignature, files: files }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDisciplinaryUserPagedPostRaw(requestParameters: ApiDisciplinaryUserPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DisciplinaryDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Disciplinary/user/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllPagedByUserIdDisciplinariesQueryToJSON(requestParameters.getAllPagedByUserIdDisciplinariesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisciplinaryDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDisciplinaryUserPagedPost(getAllPagedByUserIdDisciplinariesQuery?: GetAllPagedByUserIdDisciplinariesQuery, initOverrides?: RequestInit): Promise<DisciplinaryDtoPagedResultApiResponse> {
        const response = await this.apiDisciplinaryUserPagedPostRaw({ getAllPagedByUserIdDisciplinariesQuery: getAllPagedByUserIdDisciplinariesQuery }, initOverrides);
        return await response.value();
    }

}
