/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePasswordCommand
 */
export interface ChangePasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordCommand
     */
    confirmPassword?: string | null;
}

export function ChangePasswordCommandFromJSON(json: any): ChangePasswordCommand {
    return ChangePasswordCommandFromJSONTyped(json, false);
}

export function ChangePasswordCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'confirmPassword': !exists(json, 'confirmPassword') ? undefined : json['confirmPassword'],
    };
}

export function ChangePasswordCommandToJSON(value?: ChangePasswordCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
    };
}

