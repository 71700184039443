/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    TrainingProvider,
    TrainingProviderFromJSON,
    TrainingProviderFromJSONTyped,
    TrainingProviderToJSON,
} from './TrainingProvider';
import {
    TrainingStatus,
    TrainingStatusFromJSON,
    TrainingStatusFromJSONTyped,
    TrainingStatusToJSON,
} from './TrainingStatus';
import {
    TrainingType,
    TrainingTypeFromJSON,
    TrainingTypeFromJSONTyped,
    TrainingTypeToJSON,
} from './TrainingType';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';

/**
 * 
 * @export
 * @interface Training
 */
export interface Training {
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Training
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Training
     */
    modified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    title?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Training
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Training
     */
    expiredDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Training
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    hours?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Training
     */
    isCore?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    trainingTypeId?: string | null;
    /**
     * 
     * @type {TrainingType}
     * @memberof Training
     */
    trainingType?: TrainingType;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    trainingProviderId?: string | null;
    /**
     * 
     * @type {TrainingProvider}
     * @memberof Training
     */
    trainingProvider?: TrainingProvider;
    /**
     * 
     * @type {number}
     * @memberof Training
     */
    trainingStatusId?: number | null;
    /**
     * 
     * @type {TrainingStatus}
     * @memberof Training
     */
    trainingStatus?: TrainingStatus;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    trainingFileId?: string | null;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Training
     */
    trainingFile?: UploadedFile;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    certificateFileId?: string | null;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Training
     */
    certificateFile?: UploadedFile;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof Training
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {boolean}
     * @memberof Training
     */
    deleted?: boolean;
}

export function TrainingFromJSON(json: any): Training {
    return TrainingFromJSONTyped(json, false);
}

export function TrainingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Training {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'expiredDate': !exists(json, 'expiredDate') ? undefined : (json['expiredDate'] === null ? null : new Date(json['expiredDate'])),
        'completedDate': !exists(json, 'completedDate') ? undefined : (json['completedDate'] === null ? null : new Date(json['completedDate'])),
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'isCore': !exists(json, 'isCore') ? undefined : json['isCore'],
        'trainingTypeId': !exists(json, 'trainingTypeId') ? undefined : json['trainingTypeId'],
        'trainingType': !exists(json, 'trainingType') ? undefined : TrainingTypeFromJSON(json['trainingType']),
        'trainingProviderId': !exists(json, 'trainingProviderId') ? undefined : json['trainingProviderId'],
        'trainingProvider': !exists(json, 'trainingProvider') ? undefined : TrainingProviderFromJSON(json['trainingProvider']),
        'trainingStatusId': !exists(json, 'trainingStatusId') ? undefined : json['trainingStatusId'],
        'trainingStatus': !exists(json, 'trainingStatus') ? undefined : TrainingStatusFromJSON(json['trainingStatus']),
        'trainingFileId': !exists(json, 'trainingFileId') ? undefined : json['trainingFileId'],
        'trainingFile': !exists(json, 'trainingFile') ? undefined : UploadedFileFromJSON(json['trainingFile']),
        'certificateFileId': !exists(json, 'certificateFileId') ? undefined : json['certificateFileId'],
        'certificateFile': !exists(json, 'certificateFile') ? undefined : UploadedFileFromJSON(json['certificateFile']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

export function TrainingToJSON(value?: Training | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
        'title': value.title,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'expiredDate': value.expiredDate === undefined ? undefined : (value.expiredDate === null ? null : value.expiredDate.toISOString()),
        'completedDate': value.completedDate === undefined ? undefined : (value.completedDate === null ? null : value.completedDate.toISOString()),
        'hours': value.hours,
        'isCore': value.isCore,
        'trainingTypeId': value.trainingTypeId,
        'trainingType': TrainingTypeToJSON(value.trainingType),
        'trainingProviderId': value.trainingProviderId,
        'trainingProvider': TrainingProviderToJSON(value.trainingProvider),
        'trainingStatusId': value.trainingStatusId,
        'trainingStatus': TrainingStatusToJSON(value.trainingStatus),
        'trainingFileId': value.trainingFileId,
        'trainingFile': UploadedFileToJSON(value.trainingFile),
        'certificateFileId': value.certificateFileId,
        'certificateFile': UploadedFileToJSON(value.certificateFile),
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
        'deleted': value.deleted,
    };
}

