/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Training,
    TrainingFromJSON,
    TrainingFromJSONTyped,
    TrainingToJSON,
} from './Training';

/**
 * 
 * @export
 * @interface TrainingType
 */
export interface TrainingType {
    /**
     * 
     * @type {string}
     * @memberof TrainingType
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingType
     */
    name?: string | null;
    /**
     * 
     * @type {Array<Training>}
     * @memberof TrainingType
     */
    trainings?: Array<Training> | null;
}

export function TrainingTypeFromJSON(json: any): TrainingType {
    return TrainingTypeFromJSONTyped(json, false);
}

export function TrainingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'trainings': !exists(json, 'trainings') ? undefined : (json['trainings'] === null ? null : (json['trainings'] as Array<any>).map(TrainingFromJSON)),
    };
}

export function TrainingTypeToJSON(value?: TrainingType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'trainings': value.trainings === undefined ? undefined : (value.trainings === null ? null : (value.trainings as Array<any>).map(TrainingToJSON)),
    };
}

