/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTrainingTypeCommand,
    AddTrainingTypeCommandFromJSON,
    AddTrainingTypeCommandToJSON,
    TrainingTypeDtoApiResponse,
    TrainingTypeDtoApiResponseFromJSON,
    TrainingTypeDtoApiResponseToJSON,
    TrainingTypeDtoListApiResponse,
    TrainingTypeDtoListApiResponseFromJSON,
    TrainingTypeDtoListApiResponseToJSON,
    UpdateTrainingTypeCommand,
    UpdateTrainingTypeCommandFromJSON,
    UpdateTrainingTypeCommandToJSON,
} from '../models';

export interface ApiTrainingTypeIdDeleteRequest {
    id: string;
}

export interface ApiTrainingTypeIdGetRequest {
    id: string;
}

export interface ApiTrainingTypePostRequest {
    addTrainingTypeCommand?: AddTrainingTypeCommand;
}

export interface ApiTrainingTypePutRequest {
    updateTrainingTypeCommand?: UpdateTrainingTypeCommand;
}

/**
 * TrainingTypeApi - interface
 * 
 * @export
 * @interface TrainingTypeApiInterface
 */
export interface TrainingTypeApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingTypeApiInterface
     */
    apiTrainingTypeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingTypeDtoListApiResponse>>;

    /**
     */
    apiTrainingTypeGet(initOverrides?: RequestInit): Promise<TrainingTypeDtoListApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingTypeApiInterface
     */
    apiTrainingTypeIdDeleteRaw(requestParameters: ApiTrainingTypeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingTypeIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingTypeApiInterface
     */
    apiTrainingTypeIdGetRaw(requestParameters: ApiTrainingTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingTypeDtoApiResponse>>;

    /**
     */
    apiTrainingTypeIdGet(id: string, initOverrides?: RequestInit): Promise<TrainingTypeDtoApiResponse>;

    /**
     * 
     * @param {AddTrainingTypeCommand} [addTrainingTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingTypeApiInterface
     */
    apiTrainingTypePostRaw(requestParameters: ApiTrainingTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingTypeDtoApiResponse>>;

    /**
     */
    apiTrainingTypePost(addTrainingTypeCommand?: AddTrainingTypeCommand, initOverrides?: RequestInit): Promise<TrainingTypeDtoApiResponse>;

    /**
     * 
     * @param {UpdateTrainingTypeCommand} [updateTrainingTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingTypeApiInterface
     */
    apiTrainingTypePutRaw(requestParameters: ApiTrainingTypePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingTypePut(updateTrainingTypeCommand?: UpdateTrainingTypeCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class TrainingTypeApi extends runtime.BaseAPI implements TrainingTypeApiInterface {

    /**
     */
    async apiTrainingTypeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingTypeDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingTypeDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingTypeGet(initOverrides?: RequestInit): Promise<TrainingTypeDtoListApiResponse> {
        const response = await this.apiTrainingTypeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingTypeIdDeleteRaw(requestParameters: ApiTrainingTypeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingTypeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingTypeIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingTypeIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiTrainingTypeIdGetRaw(requestParameters: ApiTrainingTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingTypeDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingTypeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingTypeIdGet(id: string, initOverrides?: RequestInit): Promise<TrainingTypeDtoApiResponse> {
        const response = await this.apiTrainingTypeIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingTypePostRaw(requestParameters: ApiTrainingTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingTypeDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTrainingTypeCommandToJSON(requestParameters.addTrainingTypeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingTypePost(addTrainingTypeCommand?: AddTrainingTypeCommand, initOverrides?: RequestInit): Promise<TrainingTypeDtoApiResponse> {
        const response = await this.apiTrainingTypePostRaw({ addTrainingTypeCommand: addTrainingTypeCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingTypePutRaw(requestParameters: ApiTrainingTypePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingType`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTrainingTypeCommandToJSON(requestParameters.updateTrainingTypeCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingTypePut(updateTrainingTypeCommand?: UpdateTrainingTypeCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingTypePutRaw({ updateTrainingTypeCommand: updateTrainingTypeCommand }, initOverrides);
    }

}
