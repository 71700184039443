/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';

/**
 * 
 * @export
 * @interface Qualification
 */
export interface Qualification {
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    result?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    provider?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Qualification
     */
    dateAchieved?: Date;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof Qualification
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    uploadedFileId?: string | null;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Qualification
     */
    uploadedFile?: UploadedFile;
}

export function QualificationFromJSON(json: any): Qualification {
    return QualificationFromJSONTyped(json, false);
}

export function QualificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Qualification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'dateAchieved': !exists(json, 'dateAchieved') ? undefined : (new Date(json['dateAchieved'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'uploadedFileId': !exists(json, 'uploadedFileId') ? undefined : json['uploadedFileId'],
        'uploadedFile': !exists(json, 'uploadedFile') ? undefined : UploadedFileFromJSON(json['uploadedFile']),
    };
}

export function QualificationToJSON(value?: Qualification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'result': value.result,
        'provider': value.provider,
        'dateAchieved': value.dateAchieved === undefined ? undefined : (value.dateAchieved.toISOString()),
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
        'uploadedFileId': value.uploadedFileId,
        'uploadedFile': UploadedFileToJSON(value.uploadedFile),
    };
}

