/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    Badge,
    BadgeFromJSON,
    BadgeFromJSONTyped,
    BadgeToJSON,
} from './Badge';
import {
    Disciplinary,
    DisciplinaryFromJSON,
    DisciplinaryFromJSONTyped,
    DisciplinaryToJSON,
} from './Disciplinary';
import {
    PerformanceFile,
    PerformanceFileFromJSON,
    PerformanceFileFromJSONTyped,
    PerformanceFileToJSON,
} from './PerformanceFile';
import {
    Qualification,
    QualificationFromJSON,
    QualificationFromJSONTyped,
    QualificationToJSON,
} from './Qualification';
import {
    Training,
    TrainingFromJSON,
    TrainingFromJSONTyped,
    TrainingToJSON,
} from './Training';
import {
    UserFile,
    UserFileFromJSON,
    UserFileFromJSONTyped,
    UserFileToJSON,
} from './UserFile';

/**
 * 
 * @export
 * @interface UploadedFile
 */
export interface UploadedFile {
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UploadedFile
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UploadedFile
     */
    modified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    uniqueFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    originalFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    extension?: string | null;
    /**
     * 
     * @type {UserFile}
     * @memberof UploadedFile
     */
    userFile?: UserFile;
    /**
     * 
     * @type {PerformanceFile}
     * @memberof UploadedFile
     */
    performanceFile?: PerformanceFile;
    /**
     * 
     * @type {Qualification}
     * @memberof UploadedFile
     */
    qualification?: Qualification;
    /**
     * 
     * @type {Training}
     * @memberof UploadedFile
     */
    trainingFile?: Training;
    /**
     * 
     * @type {Training}
     * @memberof UploadedFile
     */
    trainingCertificate?: Training;
    /**
     * 
     * @type {Disciplinary}
     * @memberof UploadedFile
     */
    disciplinary?: Disciplinary;
    /**
     * 
     * @type {Badge}
     * @memberof UploadedFile
     */
    badge?: Badge;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof UploadedFile
     */
    user?: ApplicationUser;
}

export function UploadedFileFromJSON(json: any): UploadedFile {
    return UploadedFileFromJSONTyped(json, false);
}

export function UploadedFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadedFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
        'uniqueFileName': !exists(json, 'uniqueFileName') ? undefined : json['uniqueFileName'],
        'originalFileName': !exists(json, 'originalFileName') ? undefined : json['originalFileName'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'userFile': !exists(json, 'userFile') ? undefined : UserFileFromJSON(json['userFile']),
        'performanceFile': !exists(json, 'performanceFile') ? undefined : PerformanceFileFromJSON(json['performanceFile']),
        'qualification': !exists(json, 'qualification') ? undefined : QualificationFromJSON(json['qualification']),
        'trainingFile': !exists(json, 'trainingFile') ? undefined : TrainingFromJSON(json['trainingFile']),
        'trainingCertificate': !exists(json, 'trainingCertificate') ? undefined : TrainingFromJSON(json['trainingCertificate']),
        'disciplinary': !exists(json, 'disciplinary') ? undefined : DisciplinaryFromJSON(json['disciplinary']),
        'badge': !exists(json, 'badge') ? undefined : BadgeFromJSON(json['badge']),
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
    };
}

export function UploadedFileToJSON(value?: UploadedFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
        'uniqueFileName': value.uniqueFileName,
        'originalFileName': value.originalFileName,
        'path': value.path,
        'extension': value.extension,
        'userFile': UserFileToJSON(value.userFile),
        'performanceFile': PerformanceFileToJSON(value.performanceFile),
        'qualification': QualificationToJSON(value.qualification),
        'trainingFile': TrainingToJSON(value.trainingFile),
        'trainingCertificate': TrainingToJSON(value.trainingCertificate),
        'disciplinary': DisciplinaryToJSON(value.disciplinary),
        'badge': BadgeToJSON(value.badge),
        'user': ApplicationUserToJSON(value.user),
    };
}

