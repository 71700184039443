/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    roleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UpdateUserCommand
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    addressLine1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    postCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin1Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin1Relationship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin1Mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin1Address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin2Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin2Relationship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin2Mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    kin2Address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    managerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    roomLeaderId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserCommand
     */
    learningStyleId?: number | null;
}

export function UpdateUserCommandFromJSON(json: any): UpdateUserCommand {
    return UpdateUserCommandFromJSONTyped(json, false);
}

export function UpdateUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth'] === null ? null : new Date(json['dateOfBirth'])),
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'postCode': !exists(json, 'postCode') ? undefined : json['postCode'],
        'kin1Name': !exists(json, 'kin1Name') ? undefined : json['kin1Name'],
        'kin1Relationship': !exists(json, 'kin1Relationship') ? undefined : json['kin1Relationship'],
        'kin1Mobile': !exists(json, 'kin1Mobile') ? undefined : json['kin1Mobile'],
        'kin1Address': !exists(json, 'kin1Address') ? undefined : json['kin1Address'],
        'kin2Name': !exists(json, 'kin2Name') ? undefined : json['kin2Name'],
        'kin2Relationship': !exists(json, 'kin2Relationship') ? undefined : json['kin2Relationship'],
        'kin2Mobile': !exists(json, 'kin2Mobile') ? undefined : json['kin2Mobile'],
        'kin2Address': !exists(json, 'kin2Address') ? undefined : json['kin2Address'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'roomLeaderId': !exists(json, 'roomLeaderId') ? undefined : json['roomLeaderId'],
        'learningStyleId': !exists(json, 'learningStyleId') ? undefined : json['learningStyleId'],
    };
}

export function UpdateUserCommandToJSON(value?: UpdateUserCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'roleId': value.roleId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString()),
        'mobile': value.mobile,
        'phone': value.phone,
        'jobTitle': value.jobTitle,
        'notes': value.notes,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'town': value.town,
        'postCode': value.postCode,
        'kin1Name': value.kin1Name,
        'kin1Relationship': value.kin1Relationship,
        'kin1Mobile': value.kin1Mobile,
        'kin1Address': value.kin1Address,
        'kin2Name': value.kin2Name,
        'kin2Relationship': value.kin2Relationship,
        'kin2Mobile': value.kin2Mobile,
        'kin2Address': value.kin2Address,
        'managerId': value.managerId,
        'roomLeaderId': value.roomLeaderId,
        'learningStyleId': value.learningStyleId,
    };
}

