/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JwtDto
 */
export interface JwtDto {
    /**
     * 
     * @type {string}
     * @memberof JwtDto
     */
    accessToken?: string | null;
}

export function JwtDtoFromJSON(json: any): JwtDto {
    return JwtDtoFromJSONTyped(json, false);
}

export function JwtDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
    };
}

export function JwtDtoToJSON(value?: JwtDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
    };
}

