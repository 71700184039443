/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormTypeDto,
    FormTypeDtoFromJSON,
    FormTypeDtoFromJSONTyped,
    FormTypeDtoToJSON,
} from './FormTypeDto';

/**
 * 
 * @export
 * @interface PerformanceFileDto
 */
export interface PerformanceFileDto {
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    scheduledDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    completedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    approvedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PerformanceFileDto
     */
    performanceFileStatusId?: number;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    uploadedFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    uploadedFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFileDto
     */
    formContent?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PerformanceFileDto
     */
    formTypeId?: number | null;
    /**
     * 
     * @type {FormTypeDto}
     * @memberof PerformanceFileDto
     */
    formType?: FormTypeDto;
}

export function PerformanceFileDtoFromJSON(json: any): PerformanceFileDto {
    return PerformanceFileDtoFromJSONTyped(json, false);
}

export function PerformanceFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerformanceFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'scheduledDate': !exists(json, 'scheduledDate') ? undefined : json['scheduledDate'],
        'completedDate': !exists(json, 'completedDate') ? undefined : json['completedDate'],
        'approvedBy': !exists(json, 'approvedBy') ? undefined : json['approvedBy'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'performanceFileStatusId': !exists(json, 'performanceFileStatusId') ? undefined : json['performanceFileStatusId'],
        'uploadedFileId': !exists(json, 'uploadedFileId') ? undefined : json['uploadedFileId'],
        'uploadedFileName': !exists(json, 'uploadedFileName') ? undefined : json['uploadedFileName'],
        'formContent': !exists(json, 'formContent') ? undefined : json['formContent'],
        'formTypeId': !exists(json, 'formTypeId') ? undefined : json['formTypeId'],
        'formType': !exists(json, 'formType') ? undefined : FormTypeDtoFromJSON(json['formType']),
    };
}

export function PerformanceFileDtoToJSON(value?: PerformanceFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'scheduledDate': value.scheduledDate,
        'completedDate': value.completedDate,
        'approvedBy': value.approvedBy,
        'status': value.status,
        'performanceFileStatusId': value.performanceFileStatusId,
        'uploadedFileId': value.uploadedFileId,
        'uploadedFileName': value.uploadedFileName,
        'formContent': value.formContent,
        'formTypeId': value.formTypeId,
        'formType': FormTypeDtoToJSON(value.formType),
    };
}

