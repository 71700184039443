/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SaveToDoCommand,
    SaveToDoCommandFromJSON,
    SaveToDoCommandToJSON,
    ToDoDtoApiResponse,
    ToDoDtoApiResponseFromJSON,
    ToDoDtoApiResponseToJSON,
    ToDoDtoPagedResultApiResponse,
    ToDoDtoPagedResultApiResponseFromJSON,
    ToDoDtoPagedResultApiResponseToJSON,
} from '../models';

export interface ApiToDoCompletePutRequest {
    id?: string;
    completed?: boolean;
}

export interface ApiToDoGetAllForUserGetRequest {
    userId?: string;
    showComplete?: boolean;
    page?: number;
    pageSize?: number;
}

export interface ApiToDoGetAllGetRequest {
    showComplete?: boolean;
    page?: number;
    pageSize?: number;
    searchText?: string;
}

export interface ApiToDoIdDeleteRequest {
    id: string;
}

export interface ApiToDoSavePostRequest {
    saveToDoCommand?: SaveToDoCommand;
}

/**
 * ToDoApi - interface
 * 
 * @export
 * @interface ToDoApiInterface
 */
export interface ToDoApiInterface {
    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [completed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoApiInterface
     */
    apiToDoCompletePutRaw(requestParameters: ApiToDoCompletePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoApiResponse>>;

    /**
     */
    apiToDoCompletePut(id?: string, completed?: boolean, initOverrides?: RequestInit): Promise<ToDoDtoApiResponse>;

    /**
     * 
     * @param {string} [userId] 
     * @param {boolean} [showComplete] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoApiInterface
     */
    apiToDoGetAllForUserGetRaw(requestParameters: ApiToDoGetAllForUserGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoPagedResultApiResponse>>;

    /**
     */
    apiToDoGetAllForUserGet(userId?: string, showComplete?: boolean, page?: number, pageSize?: number, initOverrides?: RequestInit): Promise<ToDoDtoPagedResultApiResponse>;

    /**
     * 
     * @param {boolean} [showComplete] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoApiInterface
     */
    apiToDoGetAllGetRaw(requestParameters: ApiToDoGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoPagedResultApiResponse>>;

    /**
     */
    apiToDoGetAllGet(showComplete?: boolean, page?: number, pageSize?: number, searchText?: string, initOverrides?: RequestInit): Promise<ToDoDtoPagedResultApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoApiInterface
     */
    apiToDoIdDeleteRaw(requestParameters: ApiToDoIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiToDoIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {SaveToDoCommand} [saveToDoCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoApiInterface
     */
    apiToDoSavePostRaw(requestParameters: ApiToDoSavePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoApiResponse>>;

    /**
     */
    apiToDoSavePost(saveToDoCommand?: SaveToDoCommand, initOverrides?: RequestInit): Promise<ToDoDtoApiResponse>;

}

/**
 * 
 */
export class ToDoApi extends runtime.BaseAPI implements ToDoApiInterface {

    /**
     */
    async apiToDoCompletePutRaw(requestParameters: ApiToDoCompletePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.completed !== undefined) {
            queryParameters['completed'] = requestParameters.completed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ToDo/complete`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToDoDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiToDoCompletePut(id?: string, completed?: boolean, initOverrides?: RequestInit): Promise<ToDoDtoApiResponse> {
        const response = await this.apiToDoCompletePutRaw({ id: id, completed: completed }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiToDoGetAllForUserGetRaw(requestParameters: ApiToDoGetAllForUserGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.showComplete !== undefined) {
            queryParameters['showComplete'] = requestParameters.showComplete;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ToDo/getAllForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToDoDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiToDoGetAllForUserGet(userId?: string, showComplete?: boolean, page?: number, pageSize?: number, initOverrides?: RequestInit): Promise<ToDoDtoPagedResultApiResponse> {
        const response = await this.apiToDoGetAllForUserGetRaw({ userId: userId, showComplete: showComplete, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiToDoGetAllGetRaw(requestParameters: ApiToDoGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.showComplete !== undefined) {
            queryParameters['showComplete'] = requestParameters.showComplete;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['searchText'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ToDo/getAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToDoDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiToDoGetAllGet(showComplete?: boolean, page?: number, pageSize?: number, searchText?: string, initOverrides?: RequestInit): Promise<ToDoDtoPagedResultApiResponse> {
        const response = await this.apiToDoGetAllGetRaw({ showComplete: showComplete, page: page, pageSize: pageSize, searchText: searchText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiToDoIdDeleteRaw(requestParameters: ApiToDoIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiToDoIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ToDo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiToDoIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiToDoIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiToDoSavePostRaw(requestParameters: ApiToDoSavePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ToDoDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ToDo/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveToDoCommandToJSON(requestParameters.saveToDoCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToDoDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiToDoSavePost(saveToDoCommand?: SaveToDoCommand, initOverrides?: RequestInit): Promise<ToDoDtoApiResponse> {
        const response = await this.apiToDoSavePostRaw({ saveToDoCommand: saveToDoCommand }, initOverrides);
        return await response.value();
    }

}
