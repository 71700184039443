/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyDto,
    PolicyDtoFromJSON,
    PolicyDtoFromJSONTyped,
    PolicyDtoToJSON,
} from './PolicyDto';

/**
 * 
 * @export
 * @interface PolicyDtoPagedResult
 */
export interface PolicyDtoPagedResult {
    /**
     * 
     * @type {Array<PolicyDto>}
     * @memberof PolicyDtoPagedResult
     */
    items?: Array<PolicyDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyDtoPagedResult
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyDtoPagedResult
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyDtoPagedResult
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyDtoPagedResult
     */
    totalCount?: number;
}

export function PolicyDtoPagedResultFromJSON(json: any): PolicyDtoPagedResult {
    return PolicyDtoPagedResultFromJSONTyped(json, false);
}

export function PolicyDtoPagedResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyDtoPagedResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(PolicyDtoFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function PolicyDtoPagedResultToJSON(value?: PolicyDtoPagedResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(PolicyDtoToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalPages': value.totalPages,
        'totalCount': value.totalCount,
    };
}

