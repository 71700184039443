/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QualificationDto
 */
export interface QualificationDto {
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    result?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    provider?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    dateAchieved?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    certificateFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualificationDto
     */
    certificateFileId?: string | null;
}

export function QualificationDtoFromJSON(json: any): QualificationDto {
    return QualificationDtoFromJSONTyped(json, false);
}

export function QualificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'dateAchieved': !exists(json, 'dateAchieved') ? undefined : json['dateAchieved'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'certificateFileName': !exists(json, 'certificateFileName') ? undefined : json['certificateFileName'],
        'certificateFileId': !exists(json, 'certificateFileId') ? undefined : json['certificateFileId'],
    };
}

export function QualificationDtoToJSON(value?: QualificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'result': value.result,
        'provider': value.provider,
        'dateAchieved': value.dateAchieved,
        'userId': value.userId,
        'certificateFileName': value.certificateFileName,
        'certificateFileId': value.certificateFileId,
    };
}

