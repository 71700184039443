export enum ViewPermission {
  ActiveUsers,
  AddUser,
  AddTraining,
  ArchiveUsers,
  EditTraining,
  UserFiles,
  Menu,
  Disciplinaries,
  Policies,
  Trainings,
  UserPersonal,
  UserDetails,
  UserPerformance,
  UserDisciplinaries,
  UserPolicies,
  UserTodos,
  UserTrainings,
  Settings,
  Todos,
  Users,
}
