/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationRole,
    ApplicationRoleFromJSON,
    ApplicationRoleFromJSONTyped,
    ApplicationRoleToJSON,
} from './ApplicationRole';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';

/**
 * 
 * @export
 * @interface ApplicationUserRole
 */
export interface ApplicationUserRole {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRole
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRole
     */
    roleId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ApplicationUserRole
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {ApplicationRole}
     * @memberof ApplicationUserRole
     */
    role?: ApplicationRole;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRole
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUserRole
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRole
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUserRole
     */
    modified?: Date | null;
}

export function ApplicationUserRoleFromJSON(json: any): ApplicationUserRole {
    return ApplicationUserRoleFromJSONTyped(json, false);
}

export function ApplicationUserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUserRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'role': !exists(json, 'role') ? undefined : ApplicationRoleFromJSON(json['role']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
    };
}

export function ApplicationUserRoleToJSON(value?: ApplicationUserRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'roleId': value.roleId,
        'user': ApplicationUserToJSON(value.user),
        'role': ApplicationRoleToJSON(value.role),
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
    };
}

