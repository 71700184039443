/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    oldPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    newPassword?: string | null;
}

export function ChangePasswordFromJSON(json: any): ChangePassword {
    return ChangePasswordFromJSONTyped(json, false);
}

export function ChangePasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'oldPassword': !exists(json, 'oldPassword') ? undefined : json['oldPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
    };
}

export function ChangePasswordToJSON(value?: ChangePassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'oldPassword': value.oldPassword,
        'newPassword': value.newPassword,
    };
}

