/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    Policy,
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';

/**
 * 
 * @export
 * @interface UserPolicy
 */
export interface UserPolicy {
    /**
     * 
     * @type {string}
     * @memberof UserPolicy
     */
    readonly id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolicy
     */
    approved?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserPolicy
     */
    approvedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserPolicy
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof UserPolicy
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof UserPolicy
     */
    policyId?: string | null;
    /**
     * 
     * @type {Policy}
     * @memberof UserPolicy
     */
    policy?: Policy;
}

export function UserPolicyFromJSON(json: any): UserPolicy {
    return UserPolicyFromJSONTyped(json, false);
}

export function UserPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPolicy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'approvedDate': !exists(json, 'approvedDate') ? undefined : (new Date(json['approvedDate'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'policyId': !exists(json, 'policyId') ? undefined : json['policyId'],
        'policy': !exists(json, 'policy') ? undefined : PolicyFromJSON(json['policy']),
    };
}

export function UserPolicyToJSON(value?: UserPolicy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approved': value.approved,
        'approvedDate': value.approvedDate === undefined ? undefined : (value.approvedDate.toISOString()),
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
        'policyId': value.policyId,
        'policy': PolicyToJSON(value.policy),
    };
}

