/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UploadedFileDto,
    UploadedFileDtoFromJSON,
    UploadedFileDtoFromJSONTyped,
    UploadedFileDtoToJSON,
} from './UploadedFileDto';

/**
 * 
 * @export
 * @interface DisciplinaryDto
 */
export interface DisciplinaryDto {
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof DisciplinaryDto
     */
    typeId?: number;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    managerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    assignedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    managerSignature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisciplinaryDto
     */
    employeeSignature?: string | null;
    /**
     * 
     * @type {Array<UploadedFileDto>}
     * @memberof DisciplinaryDto
     */
    files?: Array<UploadedFileDto> | null;
}

export function DisciplinaryDtoFromJSON(json: any): DisciplinaryDto {
    return DisciplinaryDtoFromJSONTyped(json, false);
}

export function DisciplinaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisciplinaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'assignedBy': !exists(json, 'assignedBy') ? undefined : json['assignedBy'],
        'managerSignature': !exists(json, 'managerSignature') ? undefined : json['managerSignature'],
        'employeeSignature': !exists(json, 'employeeSignature') ? undefined : json['employeeSignature'],
        'files': !exists(json, 'files') ? undefined : (json['files'] === null ? null : (json['files'] as Array<any>).map(UploadedFileDtoFromJSON)),
    };
}

export function DisciplinaryDtoToJSON(value?: DisciplinaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'typeId': value.typeId,
        'userId': value.userId,
        'userName': value.userName,
        'managerId': value.managerId,
        'date': value.date,
        'description': value.description,
        'type': value.type,
        'assignedBy': value.assignedBy,
        'managerSignature': value.managerSignature,
        'employeeSignature': value.employeeSignature,
        'files': value.files === undefined ? undefined : (value.files === null ? null : (value.files as Array<any>).map(UploadedFileDtoToJSON)),
    };
}

