/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JwtDto,
    JwtDtoFromJSON,
    JwtDtoFromJSONTyped,
    JwtDtoToJSON,
} from './JwtDto';

/**
 * 
 * @export
 * @interface JwtDtoApiResponse
 */
export interface JwtDtoApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof JwtDtoApiResponse
     */
    succeeded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JwtDtoApiResponse
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtDtoApiResponse
     */
    errors?: Array<string> | null;
    /**
     * 
     * @type {JwtDto}
     * @memberof JwtDtoApiResponse
     */
    data?: JwtDto;
}

export function JwtDtoApiResponseFromJSON(json: any): JwtDtoApiResponse {
    return JwtDtoApiResponseFromJSONTyped(json, false);
}

export function JwtDtoApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtDtoApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'data': !exists(json, 'data') ? undefined : JwtDtoFromJSON(json['data']),
    };
}

export function JwtDtoApiResponseToJSON(value?: JwtDtoApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'succeeded': value.succeeded,
        'message': value.message,
        'errors': value.errors,
        'data': JwtDtoToJSON(value.data),
    };
}

