/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAllFileCategoriesQuery
 */
export interface GetAllFileCategoriesQuery {
    /**
     * 
     * @type {string}
     * @memberof GetAllFileCategoriesQuery
     */
    searchText?: string | null;
}

export function GetAllFileCategoriesQueryFromJSON(json: any): GetAllFileCategoriesQuery {
    return GetAllFileCategoriesQueryFromJSONTyped(json, false);
}

export function GetAllFileCategoriesQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllFileCategoriesQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
    };
}

export function GetAllFileCategoriesQueryToJSON(value?: GetAllFileCategoriesQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchText': value.searchText,
    };
}

