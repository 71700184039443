import { FieldPermission } from "@/shared/enums/field-permissions";
import { ViewPermission } from "@/shared/enums/view-permissions";
import { AbilityPermission } from "@/shared/enums/ability-permissions";

export const USER_PERMISSIONS = {
  user: {
    fields: [
      FieldPermission.Address,
      FieldPermission.DateOfBirth,
      FieldPermission.Email,
      FieldPermission.Firstname,
      FieldPermission.LastName,
      FieldPermission.JobTitle,
      FieldPermission.Mobile,
      FieldPermission.NextOfKin,
      FieldPermission.Phone,
      FieldPermission.StartDate,
    ],
    views: [
      ViewPermission.UserDetails,
      ViewPermission.UserPersonal,
      ViewPermission.UserTodos,
      ViewPermission.UserTrainings,
      ViewPermission.AddTraining,
      ViewPermission.UserPolicies,
    ],
    abilities: [
      AbilityPermission.AddAward,
      AbilityPermission.AddPerformanceFile,
      AbilityPermission.AddQualification,
      AbilityPermission.AddToDo,
      AbilityPermission.UploadFile,
      AbilityPermission.UploadPerformanceFile,
    ],
  },
  practitioner: {
    fields: [
      FieldPermission.Address,
      FieldPermission.DateOfBirth,
      FieldPermission.Email,
      FieldPermission.Firstname,
      FieldPermission.LastName,
      FieldPermission.JobTitle,
      FieldPermission.Mobile,
      FieldPermission.NextOfKin,
      FieldPermission.Phone,
      FieldPermission.StartDate,
    ],
    views: [
      ViewPermission.UserDetails,
      ViewPermission.UserPersonal,
      ViewPermission.UserTodos,
      ViewPermission.UserTrainings,
      ViewPermission.UserPolicies,
      ViewPermission.UserPerformance,
      ViewPermission.UserFiles,
    ],
    abilities: [
      AbilityPermission.AddAward,
      AbilityPermission.AddPerformanceFile,
      AbilityPermission.AddQualification,
      AbilityPermission.AddToDo,
      AbilityPermission.UploadFile,
      AbilityPermission.UploadPerformanceFile,
    ],
  },
  roomleader: {
    fields: [
      FieldPermission.Address,
      FieldPermission.DateOfBirth,
      FieldPermission.Email,
      FieldPermission.Firstname,
      FieldPermission.LastName,
      FieldPermission.JobTitle,
      FieldPermission.Mobile,
      FieldPermission.NextOfKin,
      FieldPermission.Notes,
      FieldPermission.Phone,
      FieldPermission.StartDate,
    ],
    views: [
      ViewPermission.ActiveUsers,
      ViewPermission.AddUser,
      ViewPermission.AddTraining,
      ViewPermission.ArchiveUsers,
      ViewPermission.EditTraining,
      ViewPermission.UserFiles,
      ViewPermission.Menu,
      ViewPermission.Policies,
      ViewPermission.Trainings,
      ViewPermission.UserPersonal,
      ViewPermission.UserDetails,
      ViewPermission.UserPerformance,
      ViewPermission.UserDisciplinaries,
      ViewPermission.UserPolicies,
      ViewPermission.UserTodos,
      ViewPermission.UserTrainings,
      ViewPermission.Todos,
      ViewPermission.Users,
    ],
    abilities: [
      // AbilityPermission.AddAward,
      AbilityPermission.AddDisciplinary,
      AbilityPermission.AddPerformanceFile,
      AbilityPermission.AddQualification,
      AbilityPermission.AddToDo,
      AbilityPermission.ChangePassword,
      AbilityPermission.DeleteQualification,
      AbilityPermission.DeleteTraining,
      AbilityPermission.EditJobTitle,
      AbilityPermission.EditQualification,
      AbilityPermission.EditShiftPatterns,
      AbilityPermission.UploadFile,
      AbilityPermission.UploadPerformanceFile,
    ],
  },
  manager: {
    fields: [
      FieldPermission.Address,
      FieldPermission.DateOfBirth,
      FieldPermission.Email,
      FieldPermission.Firstname,
      FieldPermission.LastName,
      FieldPermission.JobTitle,
      FieldPermission.LearningStyle,
      FieldPermission.Mobile,
      FieldPermission.NextOfKin,
      FieldPermission.Notes,
      FieldPermission.Phone,
      FieldPermission.StartDate,
    ],
    views: [
      ViewPermission.ActiveUsers,
      ViewPermission.AddUser,
      ViewPermission.AddTraining,
      ViewPermission.ArchiveUsers,
      ViewPermission.EditTraining,
      ViewPermission.UserFiles,
      ViewPermission.Menu,
      ViewPermission.Policies,
      ViewPermission.Trainings,
      ViewPermission.UserPersonal,
      ViewPermission.UserDetails,
      ViewPermission.UserPerformance,
      ViewPermission.UserDisciplinaries,
      ViewPermission.UserPolicies,
      ViewPermission.UserTodos,
      ViewPermission.UserTrainings,
      ViewPermission.Settings,
      ViewPermission.Todos,
      ViewPermission.Users,
      ViewPermission.Disciplinaries,
    ],
    abilities: [
      AbilityPermission.AddAward,
      AbilityPermission.AddDisciplinary,
      AbilityPermission.AddPerformanceFile,
      AbilityPermission.AddQualification,
      AbilityPermission.AddToDo,
      AbilityPermission.ChangePassword,
      AbilityPermission.DeleteQualification,
      AbilityPermission.DeleteTraining,
      AbilityPermission.EditJobTitle,
      AbilityPermission.EditQualification,
      AbilityPermission.EditShiftPatterns,
      AbilityPermission.UploadFile,
      AbilityPermission.UploadPerformanceFile,
    ],
  },
  systemadmin: {
    fields: Object.values(FieldPermission).filter(
      (value) => typeof value === "number"
    ) as number[],

    views: Object.values(ViewPermission).filter(
      (value) => typeof value === "number"
    ) as number[],

    abilities: Object.values(AbilityPermission).filter(
      (value) => typeof value === "number"
    ) as number[],
  },
};
