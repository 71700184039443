/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CoreTrainingProvider,
    CoreTrainingProviderFromJSON,
    CoreTrainingProviderFromJSONTyped,
    CoreTrainingProviderToJSON,
} from './CoreTrainingProvider';

/**
 * 
 * @export
 * @interface CoreTraining
 */
export interface CoreTraining {
    /**
     * 
     * @type {string}
     * @memberof CoreTraining
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CoreTraining
     */
    title?: string | null;
    /**
     * 
     * @type {Array<CoreTrainingProvider>}
     * @memberof CoreTraining
     */
    coreTrainingProviders?: Array<CoreTrainingProvider> | null;
}

export function CoreTrainingFromJSON(json: any): CoreTraining {
    return CoreTrainingFromJSONTyped(json, false);
}

export function CoreTrainingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoreTraining {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'coreTrainingProviders': !exists(json, 'coreTrainingProviders') ? undefined : (json['coreTrainingProviders'] === null ? null : (json['coreTrainingProviders'] as Array<any>).map(CoreTrainingProviderFromJSON)),
    };
}

export function CoreTrainingToJSON(value?: CoreTraining | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'coreTrainingProviders': value.coreTrainingProviders === undefined ? undefined : (value.coreTrainingProviders === null ? null : (value.coreTrainingProviders as Array<any>).map(CoreTrainingProviderToJSON)),
    };
}

