/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadgeDtoApiResponse,
    BadgeDtoApiResponseFromJSON,
    BadgeDtoApiResponseToJSON,
    BadgeDtoListApiResponse,
    BadgeDtoListApiResponseFromJSON,
    BadgeDtoListApiResponseToJSON,
    GetAllBadgesQuery,
    GetAllBadgesQueryFromJSON,
    GetAllBadgesQueryToJSON,
} from '../models';

export interface ApiBadgeAllPostRequest {
    getAllBadgesQuery?: GetAllBadgesQuery;
}

export interface ApiBadgeIdDeleteRequest {
    id: string;
}

export interface ApiBadgeIdGetRequest {
    id: string;
}

export interface ApiBadgeImageImageIdGetRequest {
    imageId: string;
}

export interface ApiBadgePostRequest {
    name?: string;
    description?: string;
    imageFile?: Blob;
}

export interface ApiBadgePutRequest {
    id?: string;
    name?: string;
    description?: string;
    imageFile?: Blob;
}

/**
 * BadgeApi - interface
 * 
 * @export
 * @interface BadgeApiInterface
 */
export interface BadgeApiInterface {
    /**
     * 
     * @param {GetAllBadgesQuery} [getAllBadgesQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    apiBadgeAllPostRaw(requestParameters: ApiBadgeAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BadgeDtoListApiResponse>>;

    /**
     */
    apiBadgeAllPost(getAllBadgesQuery?: GetAllBadgesQuery, initOverrides?: RequestInit): Promise<BadgeDtoListApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    apiBadgeIdDeleteRaw(requestParameters: ApiBadgeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiBadgeIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    apiBadgeIdGetRaw(requestParameters: ApiBadgeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BadgeDtoApiResponse>>;

    /**
     */
    apiBadgeIdGet(id: string, initOverrides?: RequestInit): Promise<BadgeDtoApiResponse>;

    /**
     * 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    apiBadgeImageImageIdGetRaw(requestParameters: ApiBadgeImageImageIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiBadgeImageImageIdGet(imageId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {Blob} [imageFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    apiBadgePostRaw(requestParameters: ApiBadgePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BadgeDtoApiResponse>>;

    /**
     */
    apiBadgePost(name?: string, description?: string, imageFile?: Blob, initOverrides?: RequestInit): Promise<BadgeDtoApiResponse>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {Blob} [imageFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    apiBadgePutRaw(requestParameters: ApiBadgePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiBadgePut(id?: string, name?: string, description?: string, imageFile?: Blob, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class BadgeApi extends runtime.BaseAPI implements BadgeApiInterface {

    /**
     */
    async apiBadgeAllPostRaw(requestParameters: ApiBadgeAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BadgeDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Badge/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllBadgesQueryToJSON(requestParameters.getAllBadgesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BadgeDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiBadgeAllPost(getAllBadgesQuery?: GetAllBadgesQuery, initOverrides?: RequestInit): Promise<BadgeDtoListApiResponse> {
        const response = await this.apiBadgeAllPostRaw({ getAllBadgesQuery: getAllBadgesQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBadgeIdDeleteRaw(requestParameters: ApiBadgeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBadgeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Badge/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBadgeIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiBadgeIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiBadgeIdGetRaw(requestParameters: ApiBadgeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BadgeDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBadgeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Badge/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BadgeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiBadgeIdGet(id: string, initOverrides?: RequestInit): Promise<BadgeDtoApiResponse> {
        const response = await this.apiBadgeIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBadgeImageImageIdGetRaw(requestParameters: ApiBadgeImageImageIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling apiBadgeImageImageIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Badge/image/{imageId}`.replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiBadgeImageImageIdGet(imageId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiBadgeImageImageIdGetRaw({ imageId: imageId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBadgePostRaw(requestParameters: ApiBadgePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BadgeDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.imageFile !== undefined) {
            formParams.append('ImageFile', requestParameters.imageFile as any);
        }

        const response = await this.request({
            path: `/api/Badge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BadgeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiBadgePost(name?: string, description?: string, imageFile?: Blob, initOverrides?: RequestInit): Promise<BadgeDtoApiResponse> {
        const response = await this.apiBadgePostRaw({ name: name, description: description, imageFile: imageFile }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBadgePutRaw(requestParameters: ApiBadgePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.imageFile !== undefined) {
            formParams.append('ImageFile', requestParameters.imageFile as any);
        }

        const response = await this.request({
            path: `/api/Badge`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBadgePut(id?: string, name?: string, description?: string, imageFile?: Blob, initOverrides?: RequestInit): Promise<void> {
        await this.apiBadgePutRaw({ id: id, name: name, description: description, imageFile: imageFile }, initOverrides);
    }

}
