/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoFromJSONTyped,
    RoleDtoToJSON,
} from './RoleDto';

/**
 * 
 * @export
 * @interface TrainingDto
 */
export interface TrainingDto {
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    expiredDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    completedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    hours?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingDto
     */
    isCore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TrainingDto
     */
    statusId?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    statusName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    typeId?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    typeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    providerName?: string | null;
    /**
     * 
     * @type {RoleDto}
     * @memberof TrainingDto
     */
    userRole?: RoleDto;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    fileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    certificateId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    certificateName?: string | null;
}

export function TrainingDtoFromJSON(json: any): TrainingDto {
    return TrainingDtoFromJSONTyped(json, false);
}

export function TrainingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'expiredDate': !exists(json, 'expiredDate') ? undefined : json['expiredDate'],
        'completedDate': !exists(json, 'completedDate') ? undefined : json['completedDate'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'isCore': !exists(json, 'isCore') ? undefined : json['isCore'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'statusName': !exists(json, 'statusName') ? undefined : json['statusName'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'providerId': !exists(json, 'providerId') ? undefined : json['providerId'],
        'providerName': !exists(json, 'providerName') ? undefined : json['providerName'],
        'userRole': !exists(json, 'userRole') ? undefined : RoleDtoFromJSON(json['userRole']),
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'certificateId': !exists(json, 'certificateId') ? undefined : json['certificateId'],
        'certificateName': !exists(json, 'certificateName') ? undefined : json['certificateName'],
    };
}

export function TrainingDtoToJSON(value?: TrainingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'userName': value.userName,
        'title': value.title,
        'startDate': value.startDate,
        'expiredDate': value.expiredDate,
        'completedDate': value.completedDate,
        'hours': value.hours,
        'isCore': value.isCore,
        'statusId': value.statusId,
        'statusName': value.statusName,
        'typeId': value.typeId,
        'typeName': value.typeName,
        'providerId': value.providerId,
        'providerName': value.providerName,
        'userRole': RoleDtoToJSON(value.userRole),
        'fileId': value.fileId,
        'fileName': value.fileName,
        'certificateId': value.certificateId,
        'certificateName': value.certificateName,
    };
}

