/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAllPagedPerformanceFilesQuery,
    GetAllPagedPerformanceFilesQueryFromJSON,
    GetAllPagedPerformanceFilesQueryToJSON,
    GuidApiResponse,
    GuidApiResponseFromJSON,
    GuidApiResponseToJSON,
    PerformanceFileDtoApiResponse,
    PerformanceFileDtoApiResponseFromJSON,
    PerformanceFileDtoApiResponseToJSON,
    PerformanceFileDtoPagedResultApiResponse,
    PerformanceFileDtoPagedResultApiResponseFromJSON,
    PerformanceFileDtoPagedResultApiResponseToJSON,
} from '../models';

export interface ApiPerformanceFileFileFileIdGetRequest {
    fileId: string;
}

export interface ApiPerformanceFileIdDeleteRequest {
    id: string;
}

export interface ApiPerformanceFileIdGetRequest {
    id: string;
}

export interface ApiPerformanceFilePagedPostRequest {
    getAllPagedPerformanceFilesQuery?: GetAllPagedPerformanceFilesQuery;
}

export interface ApiPerformanceFilePostRequest {
    title?: string;
    userId?: string;
    managerId?: string;
    performanceFileStatusId?: number;
    scheduledDate?: Date;
    completedDate?: Date;
    formContent?: string;
    formTypeId?: number;
    file?: Blob;
}

export interface ApiPerformanceFilePutRequest {
    id?: string;
    title?: string;
    scheduledDate?: Date;
    completedDate?: Date;
    performanceFileStatusId?: number;
    uploadedFile?: Blob;
    formTypeId?: number;
    formContent?: string;
}

/**
 * PerformanceFileApi - interface
 * 
 * @export
 * @interface PerformanceFileApiInterface
 */
export interface PerformanceFileApiInterface {
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileApiInterface
     */
    apiPerformanceFileFileFileIdGetRaw(requestParameters: ApiPerformanceFileFileFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiPerformanceFileFileFileIdGet(fileId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileApiInterface
     */
    apiPerformanceFileIdDeleteRaw(requestParameters: ApiPerformanceFileIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiPerformanceFileIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileApiInterface
     */
    apiPerformanceFileIdGetRaw(requestParameters: ApiPerformanceFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileDtoApiResponse>>;

    /**
     */
    apiPerformanceFileIdGet(id: string, initOverrides?: RequestInit): Promise<PerformanceFileDtoApiResponse>;

    /**
     * 
     * @param {GetAllPagedPerformanceFilesQuery} [getAllPagedPerformanceFilesQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileApiInterface
     */
    apiPerformanceFilePagedPostRaw(requestParameters: ApiPerformanceFilePagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileDtoPagedResultApiResponse>>;

    /**
     */
    apiPerformanceFilePagedPost(getAllPagedPerformanceFilesQuery?: GetAllPagedPerformanceFilesQuery, initOverrides?: RequestInit): Promise<PerformanceFileDtoPagedResultApiResponse>;

    /**
     * 
     * @param {string} [title] 
     * @param {string} [userId] 
     * @param {string} [managerId] 
     * @param {number} [performanceFileStatusId] 
     * @param {Date} [scheduledDate] 
     * @param {Date} [completedDate] 
     * @param {string} [formContent] 
     * @param {number} [formTypeId] 
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileApiInterface
     */
    apiPerformanceFilePostRaw(requestParameters: ApiPerformanceFilePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>>;

    /**
     */
    apiPerformanceFilePost(title?: string, userId?: string, managerId?: string, performanceFileStatusId?: number, scheduledDate?: Date, completedDate?: Date, formContent?: string, formTypeId?: number, file?: Blob, initOverrides?: RequestInit): Promise<GuidApiResponse>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [title] 
     * @param {Date} [scheduledDate] 
     * @param {Date} [completedDate] 
     * @param {number} [performanceFileStatusId] 
     * @param {Blob} [uploadedFile] 
     * @param {number} [formTypeId] 
     * @param {string} [formContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFileApiInterface
     */
    apiPerformanceFilePutRaw(requestParameters: ApiPerformanceFilePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiPerformanceFilePut(id?: string, title?: string, scheduledDate?: Date, completedDate?: Date, performanceFileStatusId?: number, uploadedFile?: Blob, formTypeId?: number, formContent?: string, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class PerformanceFileApi extends runtime.BaseAPI implements PerformanceFileApiInterface {

    /**
     */
    async apiPerformanceFileFileFileIdGetRaw(requestParameters: ApiPerformanceFileFileFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiPerformanceFileFileFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PerformanceFile/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPerformanceFileFileFileIdGet(fileId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPerformanceFileFileFileIdGetRaw({ fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPerformanceFileIdDeleteRaw(requestParameters: ApiPerformanceFileIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPerformanceFileIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PerformanceFile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPerformanceFileIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiPerformanceFileIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiPerformanceFileIdGetRaw(requestParameters: ApiPerformanceFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPerformanceFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PerformanceFile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformanceFileDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPerformanceFileIdGet(id: string, initOverrides?: RequestInit): Promise<PerformanceFileDtoApiResponse> {
        const response = await this.apiPerformanceFileIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPerformanceFilePagedPostRaw(requestParameters: ApiPerformanceFilePagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PerformanceFileDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PerformanceFile/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllPagedPerformanceFilesQueryToJSON(requestParameters.getAllPagedPerformanceFilesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformanceFileDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPerformanceFilePagedPost(getAllPagedPerformanceFilesQuery?: GetAllPagedPerformanceFilesQuery, initOverrides?: RequestInit): Promise<PerformanceFileDtoPagedResultApiResponse> {
        const response = await this.apiPerformanceFilePagedPostRaw({ getAllPagedPerformanceFilesQuery: getAllPagedPerformanceFilesQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPerformanceFilePostRaw(requestParameters: ApiPerformanceFilePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('UserId', requestParameters.userId as any);
        }

        if (requestParameters.managerId !== undefined) {
            formParams.append('ManagerId', requestParameters.managerId as any);
        }

        if (requestParameters.performanceFileStatusId !== undefined) {
            formParams.append('PerformanceFileStatusId', requestParameters.performanceFileStatusId as any);
        }

        if (requestParameters.scheduledDate !== undefined) {
            formParams.append('ScheduledDate', requestParameters.scheduledDate as any);
        }

        if (requestParameters.completedDate !== undefined) {
            formParams.append('CompletedDate', requestParameters.completedDate as any);
        }

        if (requestParameters.formContent !== undefined) {
            formParams.append('FormContent', requestParameters.formContent as any);
        }

        if (requestParameters.formTypeId !== undefined) {
            formParams.append('FormTypeId', requestParameters.formTypeId as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/PerformanceFile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuidApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPerformanceFilePost(title?: string, userId?: string, managerId?: string, performanceFileStatusId?: number, scheduledDate?: Date, completedDate?: Date, formContent?: string, formTypeId?: number, file?: Blob, initOverrides?: RequestInit): Promise<GuidApiResponse> {
        const response = await this.apiPerformanceFilePostRaw({ title: title, userId: userId, managerId: managerId, performanceFileStatusId: performanceFileStatusId, scheduledDate: scheduledDate, completedDate: completedDate, formContent: formContent, formTypeId: formTypeId, file: file }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPerformanceFilePutRaw(requestParameters: ApiPerformanceFilePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.scheduledDate !== undefined) {
            formParams.append('ScheduledDate', requestParameters.scheduledDate as any);
        }

        if (requestParameters.completedDate !== undefined) {
            formParams.append('CompletedDate', requestParameters.completedDate as any);
        }

        if (requestParameters.performanceFileStatusId !== undefined) {
            formParams.append('PerformanceFileStatusId', requestParameters.performanceFileStatusId as any);
        }

        if (requestParameters.uploadedFile !== undefined) {
            formParams.append('UploadedFile', requestParameters.uploadedFile as any);
        }

        if (requestParameters.formTypeId !== undefined) {
            formParams.append('FormTypeId', requestParameters.formTypeId as any);
        }

        if (requestParameters.formContent !== undefined) {
            formParams.append('FormContent', requestParameters.formContent as any);
        }

        const response = await this.request({
            path: `/api/PerformanceFile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPerformanceFilePut(id?: string, title?: string, scheduledDate?: Date, completedDate?: Date, performanceFileStatusId?: number, uploadedFile?: Blob, formTypeId?: number, formContent?: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiPerformanceFilePutRaw({ id: id, title: title, scheduledDate: scheduledDate, completedDate: completedDate, performanceFileStatusId: performanceFileStatusId, uploadedFile: uploadedFile, formTypeId: formTypeId, formContent: formContent }, initOverrides);
    }

}
