/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateShiftDayCommand
 */
export interface UpdateShiftDayCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDayCommand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDayCommand
     */
    startTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDayCommand
     */
    finishTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDayCommand
     */
    totalHours?: string | null;
}

export function UpdateShiftDayCommandFromJSON(json: any): UpdateShiftDayCommand {
    return UpdateShiftDayCommandFromJSONTyped(json, false);
}

export function UpdateShiftDayCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftDayCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'finishTime': !exists(json, 'finishTime') ? undefined : json['finishTime'],
        'totalHours': !exists(json, 'totalHours') ? undefined : json['totalHours'],
    };
}

export function UpdateShiftDayCommandToJSON(value?: UpdateShiftDayCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startTime': value.startTime,
        'finishTime': value.finishTime,
        'totalHours': value.totalHours,
    };
}

