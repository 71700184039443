/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';

/**
 * 
 * @export
 * @interface ShiftDay
 */
export interface ShiftDay {
    /**
     * 
     * @type {string}
     * @memberof ShiftDay
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftDay
     */
    dayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShiftDay
     */
    startTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShiftDay
     */
    finishTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShiftDay
     */
    totalHours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShiftDay
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ShiftDay
     */
    user?: ApplicationUser;
}

export function ShiftDayFromJSON(json: any): ShiftDay {
    return ShiftDayFromJSONTyped(json, false);
}

export function ShiftDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dayName': !exists(json, 'dayName') ? undefined : json['dayName'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'finishTime': !exists(json, 'finishTime') ? undefined : json['finishTime'],
        'totalHours': !exists(json, 'totalHours') ? undefined : json['totalHours'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
    };
}

export function ShiftDayToJSON(value?: ShiftDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayName': value.dayName,
        'startTime': value.startTime,
        'finishTime': value.finishTime,
        'totalHours': value.totalHours,
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
    };
}

