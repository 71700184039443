/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PerformanceFile,
    PerformanceFileFromJSON,
    PerformanceFileFromJSONTyped,
    PerformanceFileToJSON,
} from './PerformanceFile';

/**
 * 
 * @export
 * @interface FormType
 */
export interface FormType {
    /**
     * 
     * @type {number}
     * @memberof FormType
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof FormType
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormType
     */
    template?: string | null;
    /**
     * 
     * @type {Array<PerformanceFile>}
     * @memberof FormType
     */
    performanceFiles?: Array<PerformanceFile> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormType
     */
    deleted?: boolean;
}

export function FormTypeFromJSON(json: any): FormType {
    return FormTypeFromJSONTyped(json, false);
}

export function FormTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'template': !exists(json, 'template') ? undefined : json['template'],
        'performanceFiles': !exists(json, 'performanceFiles') ? undefined : (json['performanceFiles'] === null ? null : (json['performanceFiles'] as Array<any>).map(PerformanceFileFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

export function FormTypeToJSON(value?: FormType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'template': value.template,
        'performanceFiles': value.performanceFiles === undefined ? undefined : (value.performanceFiles === null ? null : (value.performanceFiles as Array<any>).map(PerformanceFileToJSON)),
        'deleted': value.deleted,
    };
}

