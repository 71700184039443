/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddFormTypeCommand,
    AddFormTypeCommandFromJSON,
    AddFormTypeCommandToJSON,
    FormTypeDtoApiResponse,
    FormTypeDtoApiResponseFromJSON,
    FormTypeDtoApiResponseToJSON,
    FormTypeDtoListApiResponse,
    FormTypeDtoListApiResponseFromJSON,
    FormTypeDtoListApiResponseToJSON,
} from '../models';

export interface ApiFormTypeIdGetRequest {
    id: number;
}

export interface ApiFormTypePostRequest {
    addFormTypeCommand?: AddFormTypeCommand;
}

/**
 * FormTypeApi - interface
 * 
 * @export
 * @interface FormTypeApiInterface
 */
export interface FormTypeApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormTypeApiInterface
     */
    apiFormTypeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormTypeDtoListApiResponse>>;

    /**
     */
    apiFormTypeGet(initOverrides?: RequestInit): Promise<FormTypeDtoListApiResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormTypeApiInterface
     */
    apiFormTypeIdGetRaw(requestParameters: ApiFormTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormTypeDtoApiResponse>>;

    /**
     */
    apiFormTypeIdGet(id: number, initOverrides?: RequestInit): Promise<FormTypeDtoApiResponse>;

    /**
     * 
     * @param {AddFormTypeCommand} [addFormTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormTypeApiInterface
     */
    apiFormTypePostRaw(requestParameters: ApiFormTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormTypeDtoApiResponse>>;

    /**
     */
    apiFormTypePost(addFormTypeCommand?: AddFormTypeCommand, initOverrides?: RequestInit): Promise<FormTypeDtoApiResponse>;

}

/**
 * 
 */
export class FormTypeApi extends runtime.BaseAPI implements FormTypeApiInterface {

    /**
     */
    async apiFormTypeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormTypeDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FormType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormTypeDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiFormTypeGet(initOverrides?: RequestInit): Promise<FormTypeDtoListApiResponse> {
        const response = await this.apiFormTypeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFormTypeIdGetRaw(requestParameters: ApiFormTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormTypeDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFormTypeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FormType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiFormTypeIdGet(id: number, initOverrides?: RequestInit): Promise<FormTypeDtoApiResponse> {
        const response = await this.apiFormTypeIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFormTypePostRaw(requestParameters: ApiFormTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormTypeDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FormType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFormTypeCommandToJSON(requestParameters.addFormTypeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiFormTypePost(addFormTypeCommand?: AddFormTypeCommand, initOverrides?: RequestInit): Promise<FormTypeDtoApiResponse> {
        const response = await this.apiFormTypePostRaw({ addFormTypeCommand: addFormTypeCommand }, initOverrides);
        return await response.value();
    }

}
