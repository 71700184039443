/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUserRole,
    ApplicationUserRoleFromJSON,
    ApplicationUserRoleFromJSONTyped,
    ApplicationUserRoleToJSON,
} from './ApplicationUserRole';

/**
 * 
 * @export
 * @interface ApplicationRole
 */
export interface ApplicationRole {
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    normalizedName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    concurrencyStamp?: string | null;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationRole
     */
    userRoles?: Array<ApplicationUserRole> | null;
}

export function ApplicationRoleFromJSON(json: any): ApplicationRole {
    return ApplicationRoleFromJSONTyped(json, false);
}

export function ApplicationRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'normalizedName': !exists(json, 'normalizedName') ? undefined : json['normalizedName'],
        'concurrencyStamp': !exists(json, 'concurrencyStamp') ? undefined : json['concurrencyStamp'],
        'userRoles': !exists(json, 'userRoles') ? undefined : (json['userRoles'] === null ? null : (json['userRoles'] as Array<any>).map(ApplicationUserRoleFromJSON)),
    };
}

export function ApplicationRoleToJSON(value?: ApplicationRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'normalizedName': value.normalizedName,
        'concurrencyStamp': value.concurrencyStamp,
        'userRoles': value.userRoles === undefined ? undefined : (value.userRoles === null ? null : (value.userRoles as Array<any>).map(ApplicationUserRoleToJSON)),
    };
}

