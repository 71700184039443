import {
  AddPolicyCommand,
  Configuration,
  PoliciesUserPolicyViewModel,
  PolicyApi,
  PolicyDto,
  UpdatePolicyCommand,
  UserPolicyViewModel,
} from "@/models";
import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import { useAuthStore } from "./authStore";
import { PaginatorConfig } from "@/shared/models/paginatorConfig";
import { errorToast, successToast } from "@/shared/composables/toast";
import { BASE_PATH } from "@/shared/config/const";
import { Filter } from "@/types/filter";

export const usePoliciesStore = defineStore({
  id: "policies",
  state: () => {
    const policies = ref([] as PolicyDto[]);
    const userPolicies = ref([] as UserPolicyViewModel[]);
    const userPoliciesToApprove = ref([] as PoliciesUserPolicyViewModel[]);
    const innerPolicies = ref([] as PoliciesUserPolicyViewModel[]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const isAdding = ref<boolean>(false);
    const isEditing = ref<boolean>(false);
    const form = ref({} as PolicyDto);

    const filters = ref<Filter[]>([
      {
        name: "archived",
        text: "Archived",
        checked: false,
      },
    ]);

    const innerPaginatorConfig = ref({
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    } as PaginatorConfig);

    const userPaginatorConfig = ref({
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    } as PaginatorConfig);

    const paginatorConfig = ref({
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    } as PaginatorConfig);

    const selectedRow = ref({} as PolicyDto);
    return {
      selectedRow,
      loading,
      filters,
      search,
      paginatorConfig,
      userPaginatorConfig,
      isAdding,
      isEditing,
      policies,
      userPolicies,
      innerPolicies,
      innerPaginatorConfig,
      userPoliciesToApprove,
      form,
    };
  },
  getters: {
    api() {
      const authStore = useAuthStore();
      const { token } = storeToRefs(authStore);
      return new PolicyApi(
        new Configuration({
          basePath: BASE_PATH,
          headers: { Authorization: "Bearer " + token.value },
        })
      );
    },
  },
  actions: {
    async getAll(): Promise<void> {
      this.loading = true;
      await this.api
        .apiPolicyAllPagedPost({
          searchText: this.search,
          archived: this.filters[0].checked,
          pageNumber: this.paginatorConfig.pageNumber,
          pageSize: this.paginatorConfig.pageSize,
        })
        .then((response) => {
          if (response.succeeded && response.data != null) {
            const data = response.data;
            this.policies = data.items ?? [];
            this.paginatorConfig = {
              pageNumber: data.pageNumber,
              pageSize: data.pageSize,
              totalCount: data.totalCount,
              totalPages: data.totalPages,
            } as PaginatorConfig;
          }
          this.loading = false;
        })
        .catch(async (error) => {
          this.loading = false;
          await errorToast(error);
        });
    },
    async getAllForUser(userId: string): Promise<void> {
      this.loading = true;
      await this.api
        .apiPolicyAllPagedUserPost({
          userId: userId,
          searchText: this.search,
          pageNumber: this.userPaginatorConfig.pageNumber,
          pageSize: this.userPaginatorConfig.pageSize,
        })
        .then((response) => {
          if (response.succeeded && response.data != null) {
            const data = response.data;
            this.userPolicies = data.items ?? [];
            this.userPaginatorConfig = {
              pageNumber: data.pageNumber,
              pageSize: data.pageSize,
              totalCount: data.totalCount,
              totalPages: data.totalPages,
            } as PaginatorConfig;
          }
        })
        .catch(async (error) => {
          await errorToast(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getById(policyId: string): Promise<void> {
      this.loading = true;
      await this.api
        .apiPolicyIdGet(policyId)
        .then((response) => {
          if (response.succeeded && response.data != null) {
            this.selectedRow = response.data;
          }
          this.loading = false;
        })
        .catch(async (error) => {
          await errorToast(error);
        });
    },
    async getAllForPolicy(policyId: string): Promise<void> {
      this.loading = true;
      await this.api
        .apiPolicyAllPagedPolicyPoliciesPost({
          policyId: policyId,
          archived: this.filters[0].checked,
          pageNumber: this.innerPaginatorConfig.pageNumber,
          pageSize: this.innerPaginatorConfig.pageSize,
        })
        .then((response) => {
          if (response.succeeded && response.data != null) {
            const data = response.data;
            this.innerPolicies = data.items ?? [];
            this.innerPaginatorConfig = {
              pageNumber: data.pageNumber,
              pageSize: data.pageSize,
              totalCount: data.totalCount,
              totalPages: data.totalPages,
            } as PaginatorConfig;
          }
          this.loading = false;
        })
        .catch(async (error) => {
          await errorToast(error);
        });
    },
    async add(command: AddPolicyCommand) {
      await this.api
        .apiPolicyPost({
          name: command?.name,
          description: command?.description,
          typeId: command?.typeId,
          status: command?.status,
        })
        .then((response) => {
          successToast();
          if (response.succeeded) {
            this.getAll();
          }
        })
        .catch(async (error) => {
          await errorToast(error);
        });
    },
    async update(command: UpdatePolicyCommand) {
      this.loading = true;
      await this.api
        .apiPolicyPut(command)
        .then(() => {
          successToast();
          this.getAll();
          if (command.id != null) {
            this.getById(command.id);
          }
        })
        .catch(async (error) => {
          await errorToast(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async approve(policyId: string) {
      await this.api
        .apiPolicyApproveIdPut(policyId)
        .then(() => {})
        .catch(async (error) => {
          await errorToast(error);
        });
    },
    async getNotApprovedPolicies(userId: string) {
      await this.api
        .apiPolicyPendingIdGet(userId)
        .then((response) => {
          if (response.data != null) {
            this.userPoliciesToApprove = response.data;
          }
        })
        .catch(async (error) => {
          await errorToast(error);
        });
    },
  },
});
