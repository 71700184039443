/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTrainingStatusCommand,
    AddTrainingStatusCommandFromJSON,
    AddTrainingStatusCommandToJSON,
    TrainingStatusDtoApiResponse,
    TrainingStatusDtoApiResponseFromJSON,
    TrainingStatusDtoApiResponseToJSON,
    TrainingStatusDtoListApiResponse,
    TrainingStatusDtoListApiResponseFromJSON,
    TrainingStatusDtoListApiResponseToJSON,
    UpdateTrainingStatusCommand,
    UpdateTrainingStatusCommandFromJSON,
    UpdateTrainingStatusCommandToJSON,
} from '../models';

export interface ApiTrainingStatusIdDeleteRequest {
    id: number;
}

export interface ApiTrainingStatusIdGetRequest {
    id: number;
}

export interface ApiTrainingStatusPostRequest {
    addTrainingStatusCommand?: AddTrainingStatusCommand;
}

export interface ApiTrainingStatusPutRequest {
    updateTrainingStatusCommand?: UpdateTrainingStatusCommand;
}

/**
 * TrainingStatusApi - interface
 * 
 * @export
 * @interface TrainingStatusApiInterface
 */
export interface TrainingStatusApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingStatusApiInterface
     */
    apiTrainingStatusGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingStatusDtoListApiResponse>>;

    /**
     */
    apiTrainingStatusGet(initOverrides?: RequestInit): Promise<TrainingStatusDtoListApiResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingStatusApiInterface
     */
    apiTrainingStatusIdDeleteRaw(requestParameters: ApiTrainingStatusIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingStatusIdDelete(id: number, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingStatusApiInterface
     */
    apiTrainingStatusIdGetRaw(requestParameters: ApiTrainingStatusIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingStatusDtoApiResponse>>;

    /**
     */
    apiTrainingStatusIdGet(id: number, initOverrides?: RequestInit): Promise<TrainingStatusDtoApiResponse>;

    /**
     * 
     * @param {AddTrainingStatusCommand} [addTrainingStatusCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingStatusApiInterface
     */
    apiTrainingStatusPostRaw(requestParameters: ApiTrainingStatusPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingStatusDtoApiResponse>>;

    /**
     */
    apiTrainingStatusPost(addTrainingStatusCommand?: AddTrainingStatusCommand, initOverrides?: RequestInit): Promise<TrainingStatusDtoApiResponse>;

    /**
     * 
     * @param {UpdateTrainingStatusCommand} [updateTrainingStatusCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingStatusApiInterface
     */
    apiTrainingStatusPutRaw(requestParameters: ApiTrainingStatusPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingStatusPut(updateTrainingStatusCommand?: UpdateTrainingStatusCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class TrainingStatusApi extends runtime.BaseAPI implements TrainingStatusApiInterface {

    /**
     */
    async apiTrainingStatusGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingStatusDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingStatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingStatusDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingStatusGet(initOverrides?: RequestInit): Promise<TrainingStatusDtoListApiResponse> {
        const response = await this.apiTrainingStatusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingStatusIdDeleteRaw(requestParameters: ApiTrainingStatusIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingStatusIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingStatus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingStatusIdDelete(id: number, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingStatusIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiTrainingStatusIdGetRaw(requestParameters: ApiTrainingStatusIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingStatusDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingStatusIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingStatus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingStatusDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingStatusIdGet(id: number, initOverrides?: RequestInit): Promise<TrainingStatusDtoApiResponse> {
        const response = await this.apiTrainingStatusIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingStatusPostRaw(requestParameters: ApiTrainingStatusPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingStatusDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTrainingStatusCommandToJSON(requestParameters.addTrainingStatusCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingStatusDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingStatusPost(addTrainingStatusCommand?: AddTrainingStatusCommand, initOverrides?: RequestInit): Promise<TrainingStatusDtoApiResponse> {
        const response = await this.apiTrainingStatusPostRaw({ addTrainingStatusCommand: addTrainingStatusCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingStatusPutRaw(requestParameters: ApiTrainingStatusPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingStatus`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTrainingStatusCommandToJSON(requestParameters.updateTrainingStatusCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingStatusPut(updateTrainingStatusCommand?: UpdateTrainingStatusCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingStatusPutRaw({ updateTrainingStatusCommand: updateTrainingStatusCommand }, initOverrides);
    }

}
