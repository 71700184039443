export enum FieldPermission {
  Address,
  AssignManager,
  DateOfBirth,
  Email,
  Firstname,
  JobTitle,
  LastName,
  LearningStyle,
  Mobile,
  NextOfKin,
  Notes,
  Phone,
  RoomLeader,
  StartDate,
  UserRole,
}
