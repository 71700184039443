/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AllUsersViewModelPagedResultApiResponse,
    AllUsersViewModelPagedResultApiResponseFromJSON,
    AllUsersViewModelPagedResultApiResponseToJSON,
    ChangePasswordCommand,
    ChangePasswordCommandFromJSON,
    ChangePasswordCommandToJSON,
    GetAllUsersByRoleQuery,
    GetAllUsersByRoleQueryFromJSON,
    GetAllUsersByRoleQueryToJSON,
    GetAllUsersPagedQuery,
    GetAllUsersPagedQueryFromJSON,
    GetAllUsersPagedQueryToJSON,
    GuidApiResponse,
    GuidApiResponseFromJSON,
    GuidApiResponseToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RegisterUserCommand,
    RegisterUserCommandFromJSON,
    RegisterUserCommandToJSON,
    RoleDtoListApiResponse,
    RoleDtoListApiResponseFromJSON,
    RoleDtoListApiResponseToJSON,
    UpdateUserCommand,
    UpdateUserCommandFromJSON,
    UpdateUserCommandToJSON,
    UserDetailsViewModelApiResponse,
    UserDetailsViewModelApiResponseFromJSON,
    UserDetailsViewModelApiResponseToJSON,
    UserDtoApiResponse,
    UserDtoApiResponseFromJSON,
    UserDtoApiResponseToJSON,
    UserDtoIEnumerableApiResponse,
    UserDtoIEnumerableApiResponseFromJSON,
    UserDtoIEnumerableApiResponseToJSON,
} from '../models';

export interface ApiUserAllPostRequest {
    getAllUsersByRoleQuery?: GetAllUsersByRoleQuery;
}

export interface ApiUserArchiveRestoreUserIdPostRequest {
    userId: string;
}

export interface ApiUserArchiveUserIdDeleteRequest {
    userId: string;
}

export interface ApiUserChangePasswordPostRequest {
    changePasswordCommand?: ChangePasswordCommand;
}

export interface ApiUserDeleteUserIdDeleteRequest {
    userId: string;
}

export interface ApiUserIdGetRequest {
    id: string;
}

export interface ApiUserImageImageIdGetRequest {
    imageId: string;
}

export interface ApiUserImagePostRequest {
    userId?: string;
    userImage?: Blob;
}

export interface ApiUserPagedPostRequest {
    getAllUsersPagedQuery?: GetAllUsersPagedQuery;
}

export interface ApiUserPutRequest {
    updateUserCommand?: UpdateUserCommand;
}

export interface ApiUserRegisterPostRequest {
    registerUserCommand?: RegisterUserCommand;
}

/**
 * ApplicationUserApi - interface
 * 
 * @export
 * @interface ApplicationUserApiInterface
 */
export interface ApplicationUserApiInterface {
    /**
     * 
     * @param {GetAllUsersByRoleQuery} [getAllUsersByRoleQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserAllPostRaw(requestParameters: ApiUserAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDtoIEnumerableApiResponse>>;

    /**
     */
    apiUserAllPost(getAllUsersByRoleQuery?: GetAllUsersByRoleQuery, initOverrides?: RequestInit): Promise<UserDtoIEnumerableApiResponse>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserArchiveRestoreUserIdPostRaw(requestParameters: ApiUserArchiveRestoreUserIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiUserArchiveRestoreUserIdPost(userId: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserArchiveUserIdDeleteRaw(requestParameters: ApiUserArchiveUserIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiUserArchiveUserIdDelete(userId: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {ChangePasswordCommand} [changePasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserChangePasswordPostRaw(requestParameters: ApiUserChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiUserChangePasswordPost(changePasswordCommand?: ChangePasswordCommand, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserDeleteUserIdDeleteRaw(requestParameters: ApiUserDeleteUserIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiUserDeleteUserIdDelete(userId: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserIdGetRaw(requestParameters: ApiUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDetailsViewModelApiResponse>>;

    /**
     */
    apiUserIdGet(id: string, initOverrides?: RequestInit): Promise<UserDetailsViewModelApiResponse>;

    /**
     * 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserImageImageIdGetRaw(requestParameters: ApiUserImageImageIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiUserImageImageIdGet(imageId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {string} [userId] 
     * @param {Blob} [userImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserImagePostRaw(requestParameters: ApiUserImagePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>>;

    /**
     */
    apiUserImagePost(userId?: string, userImage?: Blob, initOverrides?: RequestInit): Promise<GuidApiResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserMeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDtoApiResponse>>;

    /**
     */
    apiUserMeGet(initOverrides?: RequestInit): Promise<UserDtoApiResponse>;

    /**
     * 
     * @param {GetAllUsersPagedQuery} [getAllUsersPagedQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserPagedPostRaw(requestParameters: ApiUserPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AllUsersViewModelPagedResultApiResponse>>;

    /**
     */
    apiUserPagedPost(getAllUsersPagedQuery?: GetAllUsersPagedQuery, initOverrides?: RequestInit): Promise<AllUsersViewModelPagedResultApiResponse>;

    /**
     * 
     * @param {UpdateUserCommand} [updateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserPutRaw(requestParameters: ApiUserPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiUserPut(updateUserCommand?: UpdateUserCommand, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {RegisterUserCommand} [registerUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserRegisterPostRaw(requestParameters: ApiUserRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>>;

    /**
     */
    apiUserRegisterPost(registerUserCommand?: RegisterUserCommand, initOverrides?: RequestInit): Promise<GuidApiResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApiInterface
     */
    apiUserRolesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDtoListApiResponse>>;

    /**
     */
    apiUserRolesGet(initOverrides?: RequestInit): Promise<RoleDtoListApiResponse>;

}

/**
 * 
 */
export class ApplicationUserApi extends runtime.BaseAPI implements ApplicationUserApiInterface {

    /**
     */
    async apiUserAllPostRaw(requestParameters: ApiUserAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDtoIEnumerableApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllUsersByRoleQueryToJSON(requestParameters.getAllUsersByRoleQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoIEnumerableApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserAllPost(getAllUsersByRoleQuery?: GetAllUsersByRoleQuery, initOverrides?: RequestInit): Promise<UserDtoIEnumerableApiResponse> {
        const response = await this.apiUserAllPostRaw({ getAllUsersByRoleQuery: getAllUsersByRoleQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserArchiveRestoreUserIdPostRaw(requestParameters: ApiUserArchiveRestoreUserIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUserArchiveRestoreUserIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/archive/restore/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserArchiveRestoreUserIdPost(userId: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserArchiveRestoreUserIdPostRaw({ userId: userId }, initOverrides);
    }

    /**
     */
    async apiUserArchiveUserIdDeleteRaw(requestParameters: ApiUserArchiveUserIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUserArchiveUserIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/archive/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserArchiveUserIdDelete(userId: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserArchiveUserIdDeleteRaw({ userId: userId }, initOverrides);
    }

    /**
     */
    async apiUserChangePasswordPostRaw(requestParameters: ApiUserChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordCommandToJSON(requestParameters.changePasswordCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserChangePasswordPost(changePasswordCommand?: ChangePasswordCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserChangePasswordPostRaw({ changePasswordCommand: changePasswordCommand }, initOverrides);
    }

    /**
     */
    async apiUserDeleteUserIdDeleteRaw(requestParameters: ApiUserDeleteUserIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUserDeleteUserIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/delete/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserDeleteUserIdDelete(userId: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserDeleteUserIdDeleteRaw({ userId: userId }, initOverrides);
    }

    /**
     */
    async apiUserIdGetRaw(requestParameters: ApiUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDetailsViewModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsViewModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserIdGet(id: string, initOverrides?: RequestInit): Promise<UserDetailsViewModelApiResponse> {
        const response = await this.apiUserIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserImageImageIdGetRaw(requestParameters: ApiUserImageImageIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling apiUserImageImageIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/image/{imageId}`.replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUserImageImageIdGet(imageId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUserImageImageIdGetRaw({ imageId: imageId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserImagePostRaw(requestParameters: ApiUserImagePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('UserId', requestParameters.userId as any);
        }

        if (requestParameters.userImage !== undefined) {
            formParams.append('UserImage', requestParameters.userImage as any);
        }

        const response = await this.request({
            path: `/api/user/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuidApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserImagePost(userId?: string, userImage?: Blob, initOverrides?: RequestInit): Promise<GuidApiResponse> {
        const response = await this.apiUserImagePostRaw({ userId: userId, userImage: userImage }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserMeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserMeGet(initOverrides?: RequestInit): Promise<UserDtoApiResponse> {
        const response = await this.apiUserMeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserPagedPostRaw(requestParameters: ApiUserPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AllUsersViewModelPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllUsersPagedQueryToJSON(requestParameters.getAllUsersPagedQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllUsersViewModelPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserPagedPost(getAllUsersPagedQuery?: GetAllUsersPagedQuery, initOverrides?: RequestInit): Promise<AllUsersViewModelPagedResultApiResponse> {
        const response = await this.apiUserPagedPostRaw({ getAllUsersPagedQuery: getAllUsersPagedQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserPutRaw(requestParameters: ApiUserPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserCommandToJSON(requestParameters.updateUserCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserPut(updateUserCommand?: UpdateUserCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserPutRaw({ updateUserCommand: updateUserCommand }, initOverrides);
    }

    /**
     */
    async apiUserRegisterPostRaw(requestParameters: ApiUserRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserCommandToJSON(requestParameters.registerUserCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuidApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserRegisterPost(registerUserCommand?: RegisterUserCommand, initOverrides?: RequestInit): Promise<GuidApiResponse> {
        const response = await this.apiUserRegisterPostRaw({ registerUserCommand: registerUserCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserRolesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserRolesGet(initOverrides?: RequestInit): Promise<RoleDtoListApiResponse> {
        const response = await this.apiUserRolesGetRaw(initOverrides);
        return await response.value();
    }

}
