/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Policy,
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';

/**
 * 
 * @export
 * @interface PolicyType
 */
export interface PolicyType {
    /**
     * 
     * @type {number}
     * @memberof PolicyType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyType
     */
    name?: string | null;
    /**
     * 
     * @type {Array<Policy>}
     * @memberof PolicyType
     */
    policies?: Array<Policy> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyType
     */
    deleted?: boolean;
}

export function PolicyTypeFromJSON(json: any): PolicyType {
    return PolicyTypeFromJSONTyped(json, false);
}

export function PolicyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'policies': !exists(json, 'policies') ? undefined : (json['policies'] === null ? null : (json['policies'] as Array<any>).map(PolicyFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

export function PolicyTypeToJSON(value?: PolicyType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'policies': value.policies === undefined ? undefined : (value.policies === null ? null : (value.policies as Array<any>).map(PolicyToJSON)),
        'deleted': value.deleted,
    };
}

