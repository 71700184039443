/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QualificationDto,
    QualificationDtoFromJSON,
    QualificationDtoFromJSONTyped,
    QualificationDtoToJSON,
} from './QualificationDto';

/**
 * 
 * @export
 * @interface QualificationDtoListApiResponse
 */
export interface QualificationDtoListApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof QualificationDtoListApiResponse
     */
    succeeded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QualificationDtoListApiResponse
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QualificationDtoListApiResponse
     */
    errors?: Array<string> | null;
    /**
     * 
     * @type {Array<QualificationDto>}
     * @memberof QualificationDtoListApiResponse
     */
    data?: Array<QualificationDto> | null;
}

export function QualificationDtoListApiResponseFromJSON(json: any): QualificationDtoListApiResponse {
    return QualificationDtoListApiResponseFromJSONTyped(json, false);
}

export function QualificationDtoListApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualificationDtoListApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(QualificationDtoFromJSON)),
    };
}

export function QualificationDtoListApiResponseToJSON(value?: QualificationDtoListApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'succeeded': value.succeeded,
        'message': value.message,
        'errors': value.errors,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(QualificationDtoToJSON)),
    };
}

