/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddFileCategoryCommand,
    AddFileCategoryCommandFromJSON,
    AddFileCategoryCommandToJSON,
    FileCategoryDtoApiResponse,
    FileCategoryDtoApiResponseFromJSON,
    FileCategoryDtoApiResponseToJSON,
    FileCategoryDtoListApiResponse,
    FileCategoryDtoListApiResponseFromJSON,
    FileCategoryDtoListApiResponseToJSON,
    GetAllFileCategoriesQuery,
    GetAllFileCategoriesQueryFromJSON,
    GetAllFileCategoriesQueryToJSON,
    UpdateFileCategoryCommand,
    UpdateFileCategoryCommandFromJSON,
    UpdateFileCategoryCommandToJSON,
} from '../models';

export interface ApiFileCategoryAllPostRequest {
    getAllFileCategoriesQuery?: GetAllFileCategoriesQuery;
}

export interface ApiFileCategoryIdDeleteRequest {
    id: number;
}

export interface ApiFileCategoryIdGetRequest {
    id: number;
}

export interface ApiFileCategoryPostRequest {
    addFileCategoryCommand?: AddFileCategoryCommand;
}

export interface ApiFileCategoryPutRequest {
    updateFileCategoryCommand?: UpdateFileCategoryCommand;
}

/**
 * FileCategoryApi - interface
 * 
 * @export
 * @interface FileCategoryApiInterface
 */
export interface FileCategoryApiInterface {
    /**
     * 
     * @param {GetAllFileCategoriesQuery} [getAllFileCategoriesQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileCategoryApiInterface
     */
    apiFileCategoryAllPostRaw(requestParameters: ApiFileCategoryAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileCategoryDtoListApiResponse>>;

    /**
     */
    apiFileCategoryAllPost(getAllFileCategoriesQuery?: GetAllFileCategoriesQuery, initOverrides?: RequestInit): Promise<FileCategoryDtoListApiResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileCategoryApiInterface
     */
    apiFileCategoryIdDeleteRaw(requestParameters: ApiFileCategoryIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiFileCategoryIdDelete(id: number, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileCategoryApiInterface
     */
    apiFileCategoryIdGetRaw(requestParameters: ApiFileCategoryIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileCategoryDtoApiResponse>>;

    /**
     */
    apiFileCategoryIdGet(id: number, initOverrides?: RequestInit): Promise<FileCategoryDtoApiResponse>;

    /**
     * 
     * @param {AddFileCategoryCommand} [addFileCategoryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileCategoryApiInterface
     */
    apiFileCategoryPostRaw(requestParameters: ApiFileCategoryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileCategoryDtoApiResponse>>;

    /**
     */
    apiFileCategoryPost(addFileCategoryCommand?: AddFileCategoryCommand, initOverrides?: RequestInit): Promise<FileCategoryDtoApiResponse>;

    /**
     * 
     * @param {UpdateFileCategoryCommand} [updateFileCategoryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileCategoryApiInterface
     */
    apiFileCategoryPutRaw(requestParameters: ApiFileCategoryPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiFileCategoryPut(updateFileCategoryCommand?: UpdateFileCategoryCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class FileCategoryApi extends runtime.BaseAPI implements FileCategoryApiInterface {

    /**
     */
    async apiFileCategoryAllPostRaw(requestParameters: ApiFileCategoryAllPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileCategoryDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileCategory/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFileCategoriesQueryToJSON(requestParameters.getAllFileCategoriesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileCategoryDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiFileCategoryAllPost(getAllFileCategoriesQuery?: GetAllFileCategoriesQuery, initOverrides?: RequestInit): Promise<FileCategoryDtoListApiResponse> {
        const response = await this.apiFileCategoryAllPostRaw({ getAllFileCategoriesQuery: getAllFileCategoriesQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileCategoryIdDeleteRaw(requestParameters: ApiFileCategoryIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFileCategoryIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileCategory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileCategoryIdDelete(id: number, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileCategoryIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiFileCategoryIdGetRaw(requestParameters: ApiFileCategoryIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileCategoryDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFileCategoryIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileCategory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileCategoryDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiFileCategoryIdGet(id: number, initOverrides?: RequestInit): Promise<FileCategoryDtoApiResponse> {
        const response = await this.apiFileCategoryIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileCategoryPostRaw(requestParameters: ApiFileCategoryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileCategoryDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileCategory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFileCategoryCommandToJSON(requestParameters.addFileCategoryCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileCategoryDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiFileCategoryPost(addFileCategoryCommand?: AddFileCategoryCommand, initOverrides?: RequestInit): Promise<FileCategoryDtoApiResponse> {
        const response = await this.apiFileCategoryPostRaw({ addFileCategoryCommand: addFileCategoryCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileCategoryPutRaw(requestParameters: ApiFileCategoryPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileCategory`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFileCategoryCommandToJSON(requestParameters.updateFileCategoryCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileCategoryPut(updateFileCategoryCommand?: UpdateFileCategoryCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileCategoryPutRaw({ updateFileCategoryCommand: updateFileCategoryCommand }, initOverrides);
    }

}
