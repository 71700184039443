/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddPolicyTypeCommand,
    AddPolicyTypeCommandFromJSON,
    AddPolicyTypeCommandToJSON,
    PolicyTypeDtoApiResponse,
    PolicyTypeDtoApiResponseFromJSON,
    PolicyTypeDtoApiResponseToJSON,
    PolicyTypeDtoListApiResponse,
    PolicyTypeDtoListApiResponseFromJSON,
    PolicyTypeDtoListApiResponseToJSON,
    UpdatePolicyTypeCommand,
    UpdatePolicyTypeCommandFromJSON,
    UpdatePolicyTypeCommandToJSON,
} from '../models';

export interface ApiPolicyTypeIdDeleteRequest {
    id: number;
}

export interface ApiPolicyTypeIdGetRequest {
    id: number;
}

export interface ApiPolicyTypePostRequest {
    addPolicyTypeCommand?: AddPolicyTypeCommand;
}

export interface ApiPolicyTypePutRequest {
    updatePolicyTypeCommand?: UpdatePolicyTypeCommand;
}

/**
 * PolicyTypeApi - interface
 * 
 * @export
 * @interface PolicyTypeApiInterface
 */
export interface PolicyTypeApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyTypeApiInterface
     */
    apiPolicyTypeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyTypeDtoListApiResponse>>;

    /**
     */
    apiPolicyTypeGet(initOverrides?: RequestInit): Promise<PolicyTypeDtoListApiResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyTypeApiInterface
     */
    apiPolicyTypeIdDeleteRaw(requestParameters: ApiPolicyTypeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiPolicyTypeIdDelete(id: number, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyTypeApiInterface
     */
    apiPolicyTypeIdGetRaw(requestParameters: ApiPolicyTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyTypeDtoApiResponse>>;

    /**
     */
    apiPolicyTypeIdGet(id: number, initOverrides?: RequestInit): Promise<PolicyTypeDtoApiResponse>;

    /**
     * 
     * @param {AddPolicyTypeCommand} [addPolicyTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyTypeApiInterface
     */
    apiPolicyTypePostRaw(requestParameters: ApiPolicyTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyTypeDtoApiResponse>>;

    /**
     */
    apiPolicyTypePost(addPolicyTypeCommand?: AddPolicyTypeCommand, initOverrides?: RequestInit): Promise<PolicyTypeDtoApiResponse>;

    /**
     * 
     * @param {UpdatePolicyTypeCommand} [updatePolicyTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyTypeApiInterface
     */
    apiPolicyTypePutRaw(requestParameters: ApiPolicyTypePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiPolicyTypePut(updatePolicyTypeCommand?: UpdatePolicyTypeCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class PolicyTypeApi extends runtime.BaseAPI implements PolicyTypeApiInterface {

    /**
     */
    async apiPolicyTypeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyTypeDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PolicyType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyTypeDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyTypeGet(initOverrides?: RequestInit): Promise<PolicyTypeDtoListApiResponse> {
        const response = await this.apiPolicyTypeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyTypeIdDeleteRaw(requestParameters: ApiPolicyTypeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPolicyTypeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PolicyType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPolicyTypeIdDelete(id: number, initOverrides?: RequestInit): Promise<void> {
        await this.apiPolicyTypeIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiPolicyTypeIdGetRaw(requestParameters: ApiPolicyTypeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyTypeDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPolicyTypeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PolicyType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyTypeIdGet(id: number, initOverrides?: RequestInit): Promise<PolicyTypeDtoApiResponse> {
        const response = await this.apiPolicyTypeIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyTypePostRaw(requestParameters: ApiPolicyTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolicyTypeDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PolicyType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPolicyTypeCommandToJSON(requestParameters.addPolicyTypeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyTypeDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPolicyTypePost(addPolicyTypeCommand?: AddPolicyTypeCommand, initOverrides?: RequestInit): Promise<PolicyTypeDtoApiResponse> {
        const response = await this.apiPolicyTypePostRaw({ addPolicyTypeCommand: addPolicyTypeCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPolicyTypePutRaw(requestParameters: ApiPolicyTypePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PolicyType`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePolicyTypeCommandToJSON(requestParameters.updatePolicyTypeCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPolicyTypePut(updatePolicyTypeCommand?: UpdatePolicyTypeCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiPolicyTypePutRaw({ updatePolicyTypeCommand: updatePolicyTypeCommand }, initOverrides);
    }

}
