/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAllUserFilesPagedQuery,
    GetAllUserFilesPagedQueryFromJSON,
    GetAllUserFilesPagedQueryToJSON,
    GuidApiResponse,
    GuidApiResponseFromJSON,
    GuidApiResponseToJSON,
    UserFileDtoApiResponse,
    UserFileDtoApiResponseFromJSON,
    UserFileDtoApiResponseToJSON,
    UserFileDtoPagedResultApiResponse,
    UserFileDtoPagedResultApiResponseFromJSON,
    UserFileDtoPagedResultApiResponseToJSON,
} from '../models';

export interface ApiUserFileFileFileIdGetRequest {
    fileId: string;
}

export interface ApiUserFileIdDeleteRequest {
    id: string;
}

export interface ApiUserFileIdGetRequest {
    id: string;
}

export interface ApiUserFilePagedPostRequest {
    getAllUserFilesPagedQuery?: GetAllUserFilesPagedQuery;
}

export interface ApiUserFilePutRequest {
    id?: string;
    title?: string;
    onboarding?: boolean;
    adminViewOnly?: boolean;
    fileCategoryId?: number;
    userFile?: Blob;
}

export interface ApiUserFileUploadPostRequest {
    userId?: string;
    title?: string;
    onboarding?: boolean;
    adminViewOnly?: boolean;
    fileCategoryId?: number;
    userFile?: Blob;
}

/**
 * UserFileApi - interface
 * 
 * @export
 * @interface UserFileApiInterface
 */
export interface UserFileApiInterface {
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFileApiInterface
     */
    apiUserFileFileFileIdGetRaw(requestParameters: ApiUserFileFileFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiUserFileFileFileIdGet(fileId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFileApiInterface
     */
    apiUserFileIdDeleteRaw(requestParameters: ApiUserFileIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiUserFileIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFileApiInterface
     */
    apiUserFileIdGetRaw(requestParameters: ApiUserFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserFileDtoApiResponse>>;

    /**
     */
    apiUserFileIdGet(id: string, initOverrides?: RequestInit): Promise<UserFileDtoApiResponse>;

    /**
     * 
     * @param {GetAllUserFilesPagedQuery} [getAllUserFilesPagedQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFileApiInterface
     */
    apiUserFilePagedPostRaw(requestParameters: ApiUserFilePagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserFileDtoPagedResultApiResponse>>;

    /**
     */
    apiUserFilePagedPost(getAllUserFilesPagedQuery?: GetAllUserFilesPagedQuery, initOverrides?: RequestInit): Promise<UserFileDtoPagedResultApiResponse>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [title] 
     * @param {boolean} [onboarding] 
     * @param {boolean} [adminViewOnly] 
     * @param {number} [fileCategoryId] 
     * @param {Blob} [userFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFileApiInterface
     */
    apiUserFilePutRaw(requestParameters: ApiUserFilePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiUserFilePut(id?: string, title?: string, onboarding?: boolean, adminViewOnly?: boolean, fileCategoryId?: number, userFile?: Blob, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [title] 
     * @param {boolean} [onboarding] 
     * @param {boolean} [adminViewOnly] 
     * @param {number} [fileCategoryId] 
     * @param {Blob} [userFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFileApiInterface
     */
    apiUserFileUploadPostRaw(requestParameters: ApiUserFileUploadPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>>;

    /**
     */
    apiUserFileUploadPost(userId?: string, title?: string, onboarding?: boolean, adminViewOnly?: boolean, fileCategoryId?: number, userFile?: Blob, initOverrides?: RequestInit): Promise<GuidApiResponse>;

}

/**
 * 
 */
export class UserFileApi extends runtime.BaseAPI implements UserFileApiInterface {

    /**
     */
    async apiUserFileFileFileIdGetRaw(requestParameters: ApiUserFileFileFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiUserFileFileFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UserFile/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUserFileFileFileIdGet(fileId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUserFileFileFileIdGetRaw({ fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserFileIdDeleteRaw(requestParameters: ApiUserFileIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserFileIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UserFile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserFileIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserFileIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiUserFileIdGetRaw(requestParameters: ApiUserFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserFileDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UserFile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFileDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserFileIdGet(id: string, initOverrides?: RequestInit): Promise<UserFileDtoApiResponse> {
        const response = await this.apiUserFileIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserFilePagedPostRaw(requestParameters: ApiUserFilePagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserFileDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/UserFile/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllUserFilesPagedQueryToJSON(requestParameters.getAllUserFilesPagedQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFileDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserFilePagedPost(getAllUserFilesPagedQuery?: GetAllUserFilesPagedQuery, initOverrides?: RequestInit): Promise<UserFileDtoPagedResultApiResponse> {
        const response = await this.apiUserFilePagedPostRaw({ getAllUserFilesPagedQuery: getAllUserFilesPagedQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserFilePutRaw(requestParameters: ApiUserFilePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.onboarding !== undefined) {
            formParams.append('Onboarding', requestParameters.onboarding as any);
        }

        if (requestParameters.adminViewOnly !== undefined) {
            formParams.append('AdminViewOnly', requestParameters.adminViewOnly as any);
        }

        if (requestParameters.fileCategoryId !== undefined) {
            formParams.append('FileCategoryId', requestParameters.fileCategoryId as any);
        }

        if (requestParameters.userFile !== undefined) {
            formParams.append('UserFile', requestParameters.userFile as any);
        }

        const response = await this.request({
            path: `/api/UserFile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserFilePut(id?: string, title?: string, onboarding?: boolean, adminViewOnly?: boolean, fileCategoryId?: number, userFile?: Blob, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserFilePutRaw({ id: id, title: title, onboarding: onboarding, adminViewOnly: adminViewOnly, fileCategoryId: fileCategoryId, userFile: userFile }, initOverrides);
    }

    /**
     */
    async apiUserFileUploadPostRaw(requestParameters: ApiUserFileUploadPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GuidApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('UserId', requestParameters.userId as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.onboarding !== undefined) {
            formParams.append('Onboarding', requestParameters.onboarding as any);
        }

        if (requestParameters.adminViewOnly !== undefined) {
            formParams.append('AdminViewOnly', requestParameters.adminViewOnly as any);
        }

        if (requestParameters.fileCategoryId !== undefined) {
            formParams.append('FileCategoryId', requestParameters.fileCategoryId as any);
        }

        if (requestParameters.userFile !== undefined) {
            formParams.append('UserFile', requestParameters.userFile as any);
        }

        const response = await this.request({
            path: `/api/UserFile/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuidApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserFileUploadPost(userId?: string, title?: string, onboarding?: boolean, adminViewOnly?: boolean, fileCategoryId?: number, userFile?: Blob, initOverrides?: RequestInit): Promise<GuidApiResponse> {
        const response = await this.apiUserFileUploadPostRaw({ userId: userId, title: title, onboarding: onboarding, adminViewOnly: adminViewOnly, fileCategoryId: fileCategoryId, userFile: userFile }, initOverrides);
        return await response.value();
    }

}
