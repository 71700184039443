/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Award,
    AwardFromJSON,
    AwardFromJSONTyped,
    AwardToJSON,
} from './Award';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';

/**
 * 
 * @export
 * @interface Badge
 */
export interface Badge {
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    imageId?: string | null;
    /**
     * 
     * @type {UploadedFile}
     * @memberof Badge
     */
    image?: UploadedFile;
    /**
     * 
     * @type {Array<Award>}
     * @memberof Badge
     */
    awards?: Array<Award> | null;
}

export function BadgeFromJSON(json: any): Badge {
    return BadgeFromJSONTyped(json, false);
}

export function BadgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Badge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'image': !exists(json, 'image') ? undefined : UploadedFileFromJSON(json['image']),
        'awards': !exists(json, 'awards') ? undefined : (json['awards'] === null ? null : (json['awards'] as Array<any>).map(AwardFromJSON)),
    };
}

export function BadgeToJSON(value?: Badge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'imageId': value.imageId,
        'image': UploadedFileToJSON(value.image),
        'awards': value.awards === undefined ? undefined : (value.awards === null ? null : (value.awards as Array<any>).map(AwardToJSON)),
    };
}

