/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmployeeTrainingViewModelPagedResultApiResponse,
    EmployeeTrainingViewModelPagedResultApiResponseFromJSON,
    EmployeeTrainingViewModelPagedResultApiResponseToJSON,
    GetAllTrainingsPagedByUserIdQuery,
    GetAllTrainingsPagedByUserIdQueryFromJSON,
    GetAllTrainingsPagedByUserIdQueryToJSON,
    GetAllTrainingsPagedQuery,
    GetAllTrainingsPagedQueryFromJSON,
    GetAllTrainingsPagedQueryToJSON,
    TrainingApiResponse,
    TrainingApiResponseFromJSON,
    TrainingApiResponseToJSON,
    TrainingDtoApiResponse,
    TrainingDtoApiResponseFromJSON,
    TrainingDtoApiResponseToJSON,
    TrainingDtoPagedResultApiResponse,
    TrainingDtoPagedResultApiResponseFromJSON,
    TrainingDtoPagedResultApiResponseToJSON,
} from '../models';

export interface ApiTrainingCertificateTrainingCertificateIdGetRequest {
    trainingCertificateId: string;
}

export interface ApiTrainingFileTrainingFileIdGetRequest {
    trainingFileId: string;
}

export interface ApiTrainingIdDeleteRequest {
    id: string;
}

export interface ApiTrainingIdGetRequest {
    id: string;
}

export interface ApiTrainingPagedPostRequest {
    getAllTrainingsPagedQuery?: GetAllTrainingsPagedQuery;
}

export interface ApiTrainingPostRequest {
    userId?: string;
    title?: string;
    startDate?: string;
    expiredDate?: string;
    hours?: string;
    isCore?: boolean;
    typeId?: string;
    providerId?: string;
    statusId?: number;
    uploadedTrainingFile?: Blob;
    uploadedCertificateFile?: Blob;
}

export interface ApiTrainingPutRequest {
    id?: string;
    userId?: string;
    title?: string;
    startDate?: string;
    expiredDate?: string;
    completedDate?: string;
    hours?: string;
    isCore?: boolean;
    typeId?: string;
    providerId?: string;
    statusId?: number;
    uploadedTrainingFile?: Blob;
    uploadedCertificateFile?: Blob;
}

export interface ApiTrainingUserPagedPostRequest {
    getAllTrainingsPagedByUserIdQuery?: GetAllTrainingsPagedByUserIdQuery;
}

/**
 * TrainingApi - interface
 * 
 * @export
 * @interface TrainingApiInterface
 */
export interface TrainingApiInterface {
    /**
     * 
     * @param {string} trainingCertificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingCertificateTrainingCertificateIdGetRaw(requestParameters: ApiTrainingCertificateTrainingCertificateIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiTrainingCertificateTrainingCertificateIdGet(trainingCertificateId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {string} trainingFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingFileTrainingFileIdGetRaw(requestParameters: ApiTrainingFileTrainingFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    apiTrainingFileTrainingFileIdGet(trainingFileId: string, initOverrides?: RequestInit): Promise<Blob>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingIdDeleteRaw(requestParameters: ApiTrainingIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingIdGetRaw(requestParameters: ApiTrainingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingDtoApiResponse>>;

    /**
     */
    apiTrainingIdGet(id: string, initOverrides?: RequestInit): Promise<TrainingDtoApiResponse>;

    /**
     * 
     * @param {GetAllTrainingsPagedQuery} [getAllTrainingsPagedQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingPagedPostRaw(requestParameters: ApiTrainingPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingDtoPagedResultApiResponse>>;

    /**
     */
    apiTrainingPagedPost(getAllTrainingsPagedQuery?: GetAllTrainingsPagedQuery, initOverrides?: RequestInit): Promise<TrainingDtoPagedResultApiResponse>;

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [title] 
     * @param {string} [startDate] 
     * @param {string} [expiredDate] 
     * @param {string} [hours] 
     * @param {boolean} [isCore] 
     * @param {string} [typeId] 
     * @param {string} [providerId] 
     * @param {number} [statusId] 
     * @param {Blob} [uploadedTrainingFile] 
     * @param {Blob} [uploadedCertificateFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingPostRaw(requestParameters: ApiTrainingPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingApiResponse>>;

    /**
     */
    apiTrainingPost(userId?: string, title?: string, startDate?: string, expiredDate?: string, hours?: string, isCore?: boolean, typeId?: string, providerId?: string, statusId?: number, uploadedTrainingFile?: Blob, uploadedCertificateFile?: Blob, initOverrides?: RequestInit): Promise<TrainingApiResponse>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [userId] 
     * @param {string} [title] 
     * @param {string} [startDate] 
     * @param {string} [expiredDate] 
     * @param {string} [completedDate] 
     * @param {string} [hours] 
     * @param {boolean} [isCore] 
     * @param {string} [typeId] 
     * @param {string} [providerId] 
     * @param {number} [statusId] 
     * @param {Blob} [uploadedTrainingFile] 
     * @param {Blob} [uploadedCertificateFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingPutRaw(requestParameters: ApiTrainingPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingPut(id?: string, userId?: string, title?: string, startDate?: string, expiredDate?: string, completedDate?: string, hours?: string, isCore?: boolean, typeId?: string, providerId?: string, statusId?: number, uploadedTrainingFile?: Blob, uploadedCertificateFile?: Blob, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {GetAllTrainingsPagedByUserIdQuery} [getAllTrainingsPagedByUserIdQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    apiTrainingUserPagedPostRaw(requestParameters: ApiTrainingUserPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmployeeTrainingViewModelPagedResultApiResponse>>;

    /**
     */
    apiTrainingUserPagedPost(getAllTrainingsPagedByUserIdQuery?: GetAllTrainingsPagedByUserIdQuery, initOverrides?: RequestInit): Promise<EmployeeTrainingViewModelPagedResultApiResponse>;

}

/**
 * 
 */
export class TrainingApi extends runtime.BaseAPI implements TrainingApiInterface {

    /**
     */
    async apiTrainingCertificateTrainingCertificateIdGetRaw(requestParameters: ApiTrainingCertificateTrainingCertificateIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.trainingCertificateId === null || requestParameters.trainingCertificateId === undefined) {
            throw new runtime.RequiredError('trainingCertificateId','Required parameter requestParameters.trainingCertificateId was null or undefined when calling apiTrainingCertificateTrainingCertificateIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Training/certificate/{trainingCertificateId}`.replace(`{${"trainingCertificateId"}}`, encodeURIComponent(String(requestParameters.trainingCertificateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTrainingCertificateTrainingCertificateIdGet(trainingCertificateId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTrainingCertificateTrainingCertificateIdGetRaw({ trainingCertificateId: trainingCertificateId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingFileTrainingFileIdGetRaw(requestParameters: ApiTrainingFileTrainingFileIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.trainingFileId === null || requestParameters.trainingFileId === undefined) {
            throw new runtime.RequiredError('trainingFileId','Required parameter requestParameters.trainingFileId was null or undefined when calling apiTrainingFileTrainingFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Training/file/{trainingFileId}`.replace(`{${"trainingFileId"}}`, encodeURIComponent(String(requestParameters.trainingFileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTrainingFileTrainingFileIdGet(trainingFileId: string, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTrainingFileTrainingFileIdGetRaw({ trainingFileId: trainingFileId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingIdDeleteRaw(requestParameters: ApiTrainingIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Training/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiTrainingIdGetRaw(requestParameters: ApiTrainingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Training/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingIdGet(id: string, initOverrides?: RequestInit): Promise<TrainingDtoApiResponse> {
        const response = await this.apiTrainingIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingPagedPostRaw(requestParameters: ApiTrainingPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingDtoPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Training/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllTrainingsPagedQueryToJSON(requestParameters.getAllTrainingsPagedQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingDtoPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingPagedPost(getAllTrainingsPagedQuery?: GetAllTrainingsPagedQuery, initOverrides?: RequestInit): Promise<TrainingDtoPagedResultApiResponse> {
        const response = await this.apiTrainingPagedPostRaw({ getAllTrainingsPagedQuery: getAllTrainingsPagedQuery }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingPostRaw(requestParameters: ApiTrainingPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('UserId', requestParameters.userId as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.startDate !== undefined) {
            formParams.append('StartDate', requestParameters.startDate as any);
        }

        if (requestParameters.expiredDate !== undefined) {
            formParams.append('ExpiredDate', requestParameters.expiredDate as any);
        }

        if (requestParameters.hours !== undefined) {
            formParams.append('Hours', requestParameters.hours as any);
        }

        if (requestParameters.isCore !== undefined) {
            formParams.append('IsCore', requestParameters.isCore as any);
        }

        if (requestParameters.typeId !== undefined) {
            formParams.append('TypeId', requestParameters.typeId as any);
        }

        if (requestParameters.providerId !== undefined) {
            formParams.append('ProviderId', requestParameters.providerId as any);
        }

        if (requestParameters.statusId !== undefined) {
            formParams.append('StatusId', requestParameters.statusId as any);
        }

        if (requestParameters.uploadedTrainingFile !== undefined) {
            formParams.append('UploadedTrainingFile', requestParameters.uploadedTrainingFile as any);
        }

        if (requestParameters.uploadedCertificateFile !== undefined) {
            formParams.append('UploadedCertificateFile', requestParameters.uploadedCertificateFile as any);
        }

        const response = await this.request({
            path: `/api/Training`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingPost(userId?: string, title?: string, startDate?: string, expiredDate?: string, hours?: string, isCore?: boolean, typeId?: string, providerId?: string, statusId?: number, uploadedTrainingFile?: Blob, uploadedCertificateFile?: Blob, initOverrides?: RequestInit): Promise<TrainingApiResponse> {
        const response = await this.apiTrainingPostRaw({ userId: userId, title: title, startDate: startDate, expiredDate: expiredDate, hours: hours, isCore: isCore, typeId: typeId, providerId: providerId, statusId: statusId, uploadedTrainingFile: uploadedTrainingFile, uploadedCertificateFile: uploadedCertificateFile }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingPutRaw(requestParameters: ApiTrainingPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('UserId', requestParameters.userId as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.startDate !== undefined) {
            formParams.append('StartDate', requestParameters.startDate as any);
        }

        if (requestParameters.expiredDate !== undefined) {
            formParams.append('ExpiredDate', requestParameters.expiredDate as any);
        }

        if (requestParameters.completedDate !== undefined) {
            formParams.append('CompletedDate', requestParameters.completedDate as any);
        }

        if (requestParameters.hours !== undefined) {
            formParams.append('Hours', requestParameters.hours as any);
        }

        if (requestParameters.isCore !== undefined) {
            formParams.append('IsCore', requestParameters.isCore as any);
        }

        if (requestParameters.typeId !== undefined) {
            formParams.append('TypeId', requestParameters.typeId as any);
        }

        if (requestParameters.providerId !== undefined) {
            formParams.append('ProviderId', requestParameters.providerId as any);
        }

        if (requestParameters.statusId !== undefined) {
            formParams.append('StatusId', requestParameters.statusId as any);
        }

        if (requestParameters.uploadedTrainingFile !== undefined) {
            formParams.append('UploadedTrainingFile', requestParameters.uploadedTrainingFile as any);
        }

        if (requestParameters.uploadedCertificateFile !== undefined) {
            formParams.append('UploadedCertificateFile', requestParameters.uploadedCertificateFile as any);
        }

        const response = await this.request({
            path: `/api/Training`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingPut(id?: string, userId?: string, title?: string, startDate?: string, expiredDate?: string, completedDate?: string, hours?: string, isCore?: boolean, typeId?: string, providerId?: string, statusId?: number, uploadedTrainingFile?: Blob, uploadedCertificateFile?: Blob, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingPutRaw({ id: id, userId: userId, title: title, startDate: startDate, expiredDate: expiredDate, completedDate: completedDate, hours: hours, isCore: isCore, typeId: typeId, providerId: providerId, statusId: statusId, uploadedTrainingFile: uploadedTrainingFile, uploadedCertificateFile: uploadedCertificateFile }, initOverrides);
    }

    /**
     */
    async apiTrainingUserPagedPostRaw(requestParameters: ApiTrainingUserPagedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmployeeTrainingViewModelPagedResultApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Training/user/paged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllTrainingsPagedByUserIdQueryToJSON(requestParameters.getAllTrainingsPagedByUserIdQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeTrainingViewModelPagedResultApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingUserPagedPost(getAllTrainingsPagedByUserIdQuery?: GetAllTrainingsPagedByUserIdQuery, initOverrides?: RequestInit): Promise<EmployeeTrainingViewModelPagedResultApiResponse> {
        const response = await this.apiTrainingUserPagedPostRaw({ getAllTrainingsPagedByUserIdQuery: getAllTrainingsPagedByUserIdQuery }, initOverrides);
        return await response.value();
    }

}
