/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CoreTraining,
    CoreTrainingFromJSON,
    CoreTrainingFromJSONTyped,
    CoreTrainingToJSON,
} from './CoreTraining';
import {
    TrainingProvider,
    TrainingProviderFromJSON,
    TrainingProviderFromJSONTyped,
    TrainingProviderToJSON,
} from './TrainingProvider';

/**
 * 
 * @export
 * @interface CoreTrainingProvider
 */
export interface CoreTrainingProvider {
    /**
     * 
     * @type {string}
     * @memberof CoreTrainingProvider
     */
    trainingProviderId?: string | null;
    /**
     * 
     * @type {TrainingProvider}
     * @memberof CoreTrainingProvider
     */
    trainingProvider?: TrainingProvider;
    /**
     * 
     * @type {string}
     * @memberof CoreTrainingProvider
     */
    coreTrainingId?: string | null;
    /**
     * 
     * @type {CoreTraining}
     * @memberof CoreTrainingProvider
     */
    coreTraining?: CoreTraining;
    /**
     * 
     * @type {boolean}
     * @memberof CoreTrainingProvider
     */
    isLinked?: boolean;
}

export function CoreTrainingProviderFromJSON(json: any): CoreTrainingProvider {
    return CoreTrainingProviderFromJSONTyped(json, false);
}

export function CoreTrainingProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoreTrainingProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trainingProviderId': !exists(json, 'trainingProviderId') ? undefined : json['trainingProviderId'],
        'trainingProvider': !exists(json, 'trainingProvider') ? undefined : TrainingProviderFromJSON(json['trainingProvider']),
        'coreTrainingId': !exists(json, 'coreTrainingId') ? undefined : json['coreTrainingId'],
        'coreTraining': !exists(json, 'coreTraining') ? undefined : CoreTrainingFromJSON(json['coreTraining']),
        'isLinked': !exists(json, 'isLinked') ? undefined : json['isLinked'],
    };
}

export function CoreTrainingProviderToJSON(value?: CoreTrainingProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trainingProviderId': value.trainingProviderId,
        'trainingProvider': TrainingProviderToJSON(value.trainingProvider),
        'coreTrainingId': value.coreTrainingId,
        'coreTraining': CoreTrainingToJSON(value.coreTraining),
        'isLinked': value.isLinked,
    };
}

