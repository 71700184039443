/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserPoliciesByPolicyIdQuery
 */
export interface GetUserPoliciesByPolicyIdQuery {
    /**
     * 
     * @type {string}
     * @memberof GetUserPoliciesByPolicyIdQuery
     */
    policyId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserPoliciesByPolicyIdQuery
     */
    archived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserPoliciesByPolicyIdQuery
     */
    expired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserPoliciesByPolicyIdQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserPoliciesByPolicyIdQuery
     */
    pageSize?: number;
}

export function GetUserPoliciesByPolicyIdQueryFromJSON(json: any): GetUserPoliciesByPolicyIdQuery {
    return GetUserPoliciesByPolicyIdQueryFromJSONTyped(json, false);
}

export function GetUserPoliciesByPolicyIdQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserPoliciesByPolicyIdQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policyId': !exists(json, 'policyId') ? undefined : json['policyId'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'expired': !exists(json, 'expired') ? undefined : json['expired'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function GetUserPoliciesByPolicyIdQueryToJSON(value?: GetUserPoliciesByPolicyIdQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policyId': value.policyId,
        'archived': value.archived,
        'expired': value.expired,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}

