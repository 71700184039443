/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPagedByUserIdPolicyQuery
 */
export interface GetPagedByUserIdPolicyQuery {
    /**
     * 
     * @type {string}
     * @memberof GetPagedByUserIdPolicyQuery
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPagedByUserIdPolicyQuery
     */
    searchText?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPagedByUserIdPolicyQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPagedByUserIdPolicyQuery
     */
    pageSize?: number;
}

export function GetPagedByUserIdPolicyQueryFromJSON(json: any): GetPagedByUserIdPolicyQuery {
    return GetPagedByUserIdPolicyQueryFromJSONTyped(json, false);
}

export function GetPagedByUserIdPolicyQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPagedByUserIdPolicyQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function GetPagedByUserIdPolicyQueryToJSON(value?: GetPagedByUserIdPolicyQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'searchText': value.searchText,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}

