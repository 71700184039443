import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";

export const useAppStore = defineStore("app", () => {
  const route = useRoute();

  const isMenuCollapsed = ref(false);
  const hideMenu = computed(() => route.meta.hideMenu == true);

  const hideNav = computed(() => route.meta.hideNav == true);

  return { isMenuCollapsed, hideNav, hideMenu };
});
