import { createRouter, createWebHistory } from "vue-router";
import {
  authGuard,
  AuthGuardType,
  permissionGuard,
} from "@/shared/composables/guards";
import { ViewPermission } from "@/shared/enums/view-permissions";
import { BASE_URLS } from "@/shared/config/urls";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: BASE_URLS.HOME,
      name: "login",
      component: () => import("../views/Core/LoginView.vue"),
      beforeEnter: () => authGuard(AuthGuardType.LOGIN),
      meta: {
        title: "Mosaic",
        hideNav: true,
        hideMenu: true,
      },
    },
    {
      path: BASE_URLS.SET_PASSWORD,
      meta: {
        title: "Mosaic",
        hideNav: true,
        hideMenu: true,
      },
      component: () => import("@/views/Core/SetPasswordView.vue"),
      beforeEnter: () => authGuard(AuthGuardType.LOGIN),
    },
    {
      path: BASE_URLS.RESET_PASSWORD,
      meta: {
        title: "Mosaic",
        hideNav: true,
        hideMenu: true,
      },
      component: () => import("@/views/Core/ResetPasswordView.vue"),
      beforeEnter: () => authGuard(AuthGuardType.LOGIN),
    },
    {
      path: BASE_URLS.FORGOT_PASSWORD,
      meta: {
        title: "Mosaic",
        hideNav: true,
        hideMenu: true,
      },
      component: () => import("@/views/Core/ForgotPasswordView.vue"),
      beforeEnter: () => authGuard(AuthGuardType.LOGIN),
    },
    {
      path: "/create-account/:id",
      name: "CreateAccount",
      meta: {
        title: "Mosaic - Create Account",
      },
      component: () => import("@/views/Users/CreateAccountView.vue"),
      beforeEnter: () => authGuard(AuthGuardType.LOGIN),
    },
    {
      path: BASE_URLS.USERS,
      children: [
        {
          path: "",
          name: "Users",
          component: () => import("@/views/Users/UsersView.vue"),
          beforeEnter: (to, from, next) =>
            authGuard(AuthGuardType.UNAUTHORIZED) &&
            permissionGuard(ViewPermission.Users, to, from, next),
        },
        {
          path: "add",
          name: "add-user",
          component: () => import("@/views/Users/UserAddView.vue"),
          beforeEnter: (to, from, next) =>
            authGuard(AuthGuardType.UNAUTHORIZED) &&
            permissionGuard(ViewPermission.AddUser, to, from, next),
        },
        {
          path: "edit/:id",
          children: [
            {
              path: "",
              name: "edit-user",
              component: () => import("@/views/Users/UserEditView.vue"),
              beforeEnter: () => authGuard(AuthGuardType.UNAUTHORIZED),
            },
            {
              path: "training/edit/:trainingId",
              name: "edit-user-training",
              component: () => import("@/views/Users/UserTrainingEditView.vue"),
              beforeEnter: (to, from, next) =>
                authGuard(AuthGuardType.UNAUTHORIZED) &&
                permissionGuard(ViewPermission.EditTraining, to, from, next),
            },
            {
              path: "training/add", //userId
              name: "add-user-training",
              component: () => import("@/views/Users/UserTrainingAddView.vue"),
              beforeEnter: (to, from, next) =>
                authGuard(AuthGuardType.UNAUTHORIZED) &&
                permissionGuard(ViewPermission.AddTraining, to, from, next),
            },
            {
              path: "form/add/:formId", //userId
              name: "add-performance-form",
              component: () =>
                import("@/views/Users/UserPerformanceFormAddView.vue"),
              beforeEnter: (to, from, next) =>
                authGuard(AuthGuardType.UNAUTHORIZED) &&
                permissionGuard(ViewPermission.UserPerformance, to, from, next),
            },
            {
              path: "form/edit/:formId/:performanceFileId", //userId
              name: "edit-performance-form",
              component: () =>
                import("@/views/Users/UserPerformanceFormEditView.vue"),
              beforeEnter: (to, from, next) =>
                authGuard(AuthGuardType.UNAUTHORIZED) &&
                permissionGuard(ViewPermission.UserPerformance, to, from, next),
            },
          ],
        },
      ],
    },
    {
      path: BASE_URLS.SETTINGS,
      name: "settings",
      component: () => import("@/views/Settings/SettingsView.vue"),
      beforeEnter: (to, from, next) =>
        authGuard(AuthGuardType.UNAUTHORIZED) &&
        permissionGuard(ViewPermission.Settings, to, from, next),
    },
    {
      path: BASE_URLS.TRAININGS,
      children: [
        {
          path: "",
          name: "trainings",
          component: () => import("@/views/Trainings/TrainingView.vue"),
          beforeEnter: (to, from, next) =>
            authGuard(AuthGuardType.UNAUTHORIZED) &&
            permissionGuard(ViewPermission.Trainings, to, from, next),
        },
        {
          path: "edit/:trainingId",
          name: "edit-training",
          component: () => import("@/views/Trainings/TrainingEditView.vue"),
          beforeEnter: (to, from, next) =>
            authGuard(AuthGuardType.UNAUTHORIZED) &&
            permissionGuard(ViewPermission.EditTraining, to, from, next),
        },
      ],
    },

    {
      path: BASE_URLS.DISCIPLINARIES,
      name: "disciplinaries",
      component: () => import("@/views/Disciplinaries/DisciplinariesView.vue"),
      beforeEnter: (to, from, next) =>
        authGuard(AuthGuardType.UNAUTHORIZED) &&
        permissionGuard(ViewPermission.Disciplinaries, to, from, next),
    },
    {
      path: BASE_URLS.POLICIES,
      children: [
        {
          path: "",
          name: "policies",
          component: () => import("@/views/Policies/PoliciesView.vue"),
          beforeEnter: (to, from, next) =>
            authGuard(AuthGuardType.UNAUTHORIZED) &&
            permissionGuard(ViewPermission.Policies, to, from, next),
        },
        {
          path: ":id",
          name: "inner-policies",
          component: () => import("@/views/Policies/InnerPoliciesView.vue"),
          beforeEnter: (to, from, next) =>
            authGuard(AuthGuardType.UNAUTHORIZED) &&
            permissionGuard(ViewPermission.Policies, to, from, next),
        },
      ],
    },
    {
      path: BASE_URLS.TODOS,
      name: "todos",
      component: () => import("@/views/ToDos/ToDosView.vue"),
      beforeEnter: (to, from, next) =>
        authGuard(AuthGuardType.UNAUTHORIZED) &&
        permissionGuard(ViewPermission.Todos, to, from, next),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: () => import("@/views/Core/NotFound.vue"),
    },
  ],
});

export default router;
router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || "Mosaic"; // Set a default title if not provided in the meta
  next();
});
