/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAllTrainingsPagedByUserIdQuery
 */
export interface GetAllTrainingsPagedByUserIdQuery {
    /**
     * 
     * @type {string}
     * @memberof GetAllTrainingsPagedByUserIdQuery
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllTrainingsPagedByUserIdQuery
     */
    searchText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllTrainingsPagedByUserIdQuery
     */
    archived?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllTrainingsPagedByUserIdQuery
     */
    expiredOnly?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllTrainingsPagedByUserIdQuery
     */
    coreOnly?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllTrainingsPagedByUserIdQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllTrainingsPagedByUserIdQuery
     */
    pageSize?: number;
}

export function GetAllTrainingsPagedByUserIdQueryFromJSON(json: any): GetAllTrainingsPagedByUserIdQuery {
    return GetAllTrainingsPagedByUserIdQueryFromJSONTyped(json, false);
}

export function GetAllTrainingsPagedByUserIdQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllTrainingsPagedByUserIdQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'expiredOnly': !exists(json, 'expiredOnly') ? undefined : json['expiredOnly'],
        'coreOnly': !exists(json, 'coreOnly') ? undefined : json['coreOnly'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function GetAllTrainingsPagedByUserIdQueryToJSON(value?: GetAllTrainingsPagedByUserIdQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'searchText': value.searchText,
        'archived': value.archived,
        'expiredOnly': value.expiredOnly,
        'coreOnly': value.coreOnly,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}

