/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    Badge,
    BadgeFromJSON,
    BadgeFromJSONTyped,
    BadgeToJSON,
} from './Badge';

/**
 * 
 * @export
 * @interface Award
 */
export interface Award {
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Award
     */
    dateReceived?: Date;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    managerId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof Award
     */
    manager?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof Award
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    badgeId?: string | null;
    /**
     * 
     * @type {Badge}
     * @memberof Award
     */
    badge?: Badge;
}

export function AwardFromJSON(json: any): Award {
    return AwardFromJSONTyped(json, false);
}

export function AwardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Award {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dateReceived': !exists(json, 'dateReceived') ? undefined : (new Date(json['dateReceived'])),
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'manager': !exists(json, 'manager') ? undefined : ApplicationUserFromJSON(json['manager']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'badgeId': !exists(json, 'badgeId') ? undefined : json['badgeId'],
        'badge': !exists(json, 'badge') ? undefined : BadgeFromJSON(json['badge']),
    };
}

export function AwardToJSON(value?: Award | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'dateReceived': value.dateReceived === undefined ? undefined : (value.dateReceived.toISOString()),
        'managerId': value.managerId,
        'manager': ApplicationUserToJSON(value.manager),
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
        'badgeId': value.badgeId,
        'badge': BadgeToJSON(value.badge),
    };
}

