/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUserRole,
    ApplicationUserRoleFromJSON,
    ApplicationUserRoleFromJSONTyped,
    ApplicationUserRoleToJSON,
} from './ApplicationUserRole';
import {
    Award,
    AwardFromJSON,
    AwardFromJSONTyped,
    AwardToJSON,
} from './Award';
import {
    LearningStyle,
    LearningStyleFromJSON,
    LearningStyleFromJSONTyped,
    LearningStyleToJSON,
} from './LearningStyle';
import {
    PerformanceFile,
    PerformanceFileFromJSON,
    PerformanceFileFromJSONTyped,
    PerformanceFileToJSON,
} from './PerformanceFile';
import {
    Policy,
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';
import {
    Qualification,
    QualificationFromJSON,
    QualificationFromJSONTyped,
    QualificationToJSON,
} from './Qualification';
import {
    ShiftDay,
    ShiftDayFromJSON,
    ShiftDayFromJSONTyped,
    ShiftDayToJSON,
} from './ShiftDay';
import {
    StringIdentityUserClaim,
    StringIdentityUserClaimFromJSON,
    StringIdentityUserClaimFromJSONTyped,
    StringIdentityUserClaimToJSON,
} from './StringIdentityUserClaim';
import {
    StringIdentityUserLogin,
    StringIdentityUserLoginFromJSON,
    StringIdentityUserLoginFromJSONTyped,
    StringIdentityUserLoginToJSON,
} from './StringIdentityUserLogin';
import {
    StringIdentityUserToken,
    StringIdentityUserTokenFromJSON,
    StringIdentityUserTokenFromJSONTyped,
    StringIdentityUserTokenToJSON,
} from './StringIdentityUserToken';
import {
    Training,
    TrainingFromJSON,
    TrainingFromJSONTyped,
    TrainingToJSON,
} from './Training';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';
import {
    UserDisciplinary,
    UserDisciplinaryFromJSON,
    UserDisciplinaryFromJSONTyped,
    UserDisciplinaryToJSON,
} from './UserDisciplinary';
import {
    UserFile,
    UserFileFromJSON,
    UserFileFromJSONTyped,
    UserFileToJSON,
} from './UserFile';
import {
    UserPolicy,
    UserPolicyFromJSON,
    UserPolicyFromJSONTyped,
    UserPolicyToJSON,
} from './UserPolicy';

/**
 * 
 * @export
 * @interface ApplicationUser
 */
export interface ApplicationUser {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    normalizedUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    normalizedEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    passwordHash?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    securityStamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    concurrencyStamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    phoneNumberConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    twoFactorEnabled?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    lockoutEnd?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    lockoutEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    accessFailedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    addressLine1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    postCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin1Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin1Relationship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin1Mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin1Address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin2Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin2Relationship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin2Mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    kin2Address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    managerId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ApplicationUser
     */
    manager?: ApplicationUser;
    /**
     * 
     * @type {Array<ApplicationUser>}
     * @memberof ApplicationUser
     */
    managedUsers?: Array<ApplicationUser> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    roomLeaderId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ApplicationUser
     */
    roomLeader?: ApplicationUser;
    /**
     * 
     * @type {Array<ApplicationUser>}
     * @memberof ApplicationUser
     */
    roomLeaderUsers?: Array<ApplicationUser> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    learningStyleId?: number | null;
    /**
     * 
     * @type {LearningStyle}
     * @memberof ApplicationUser
     */
    learningStyle?: LearningStyle;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    status?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    modified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    userImageId?: string | null;
    /**
     * 
     * @type {UploadedFile}
     * @memberof ApplicationUser
     */
    userImage?: UploadedFile;
    /**
     * 
     * @type {Array<Training>}
     * @memberof ApplicationUser
     */
    readonly trainings?: Array<Training> | null;
    /**
     * 
     * @type {Array<Award>}
     * @memberof ApplicationUser
     */
    awards?: Array<Award> | null;
    /**
     * 
     * @type {Array<Award>}
     * @memberof ApplicationUser
     */
    awardedAwards?: Array<Award> | null;
    /**
     * 
     * @type {Array<UserFile>}
     * @memberof ApplicationUser
     */
    files?: Array<UserFile> | null;
    /**
     * 
     * @type {Array<PerformanceFile>}
     * @memberof ApplicationUser
     */
    performanceFiles?: Array<PerformanceFile> | null;
    /**
     * 
     * @type {Array<PerformanceFile>}
     * @memberof ApplicationUser
     */
    approvedPerformanceFiles?: Array<PerformanceFile> | null;
    /**
     * 
     * @type {Array<Qualification>}
     * @memberof ApplicationUser
     */
    qualifications?: Array<Qualification> | null;
    /**
     * 
     * @type {Array<ShiftDay>}
     * @memberof ApplicationUser
     */
    shiftPattern?: Array<ShiftDay> | null;
    /**
     * 
     * @type {Array<StringIdentityUserClaim>}
     * @memberof ApplicationUser
     */
    claims?: Array<StringIdentityUserClaim> | null;
    /**
     * 
     * @type {Array<StringIdentityUserLogin>}
     * @memberof ApplicationUser
     */
    logins?: Array<StringIdentityUserLogin> | null;
    /**
     * 
     * @type {Array<StringIdentityUserToken>}
     * @memberof ApplicationUser
     */
    tokens?: Array<StringIdentityUserToken> | null;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUser
     */
    userRoles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {Array<UserDisciplinary>}
     * @memberof ApplicationUser
     */
    userDisciplinaries?: Array<UserDisciplinary> | null;
    /**
     * 
     * @type {Array<UserPolicy>}
     * @memberof ApplicationUser
     */
    userPolicies?: Array<UserPolicy> | null;
    /**
     * 
     * @type {Array<Policy>}
     * @memberof ApplicationUser
     */
    publishedPolicies?: Array<Policy> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    deleted?: boolean;
}

export function ApplicationUserFromJSON(json: any): ApplicationUser {
    return ApplicationUserFromJSONTyped(json, false);
}

export function ApplicationUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'normalizedUserName': !exists(json, 'normalizedUserName') ? undefined : json['normalizedUserName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'normalizedEmail': !exists(json, 'normalizedEmail') ? undefined : json['normalizedEmail'],
        'emailConfirmed': !exists(json, 'emailConfirmed') ? undefined : json['emailConfirmed'],
        'passwordHash': !exists(json, 'passwordHash') ? undefined : json['passwordHash'],
        'securityStamp': !exists(json, 'securityStamp') ? undefined : json['securityStamp'],
        'concurrencyStamp': !exists(json, 'concurrencyStamp') ? undefined : json['concurrencyStamp'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'phoneNumberConfirmed': !exists(json, 'phoneNumberConfirmed') ? undefined : json['phoneNumberConfirmed'],
        'twoFactorEnabled': !exists(json, 'twoFactorEnabled') ? undefined : json['twoFactorEnabled'],
        'lockoutEnd': !exists(json, 'lockoutEnd') ? undefined : (json['lockoutEnd'] === null ? null : new Date(json['lockoutEnd'])),
        'lockoutEnabled': !exists(json, 'lockoutEnabled') ? undefined : json['lockoutEnabled'],
        'accessFailedCount': !exists(json, 'accessFailedCount') ? undefined : json['accessFailedCount'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth'] === null ? null : new Date(json['dateOfBirth'])),
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'postCode': !exists(json, 'postCode') ? undefined : json['postCode'],
        'kin1Name': !exists(json, 'kin1Name') ? undefined : json['kin1Name'],
        'kin1Relationship': !exists(json, 'kin1Relationship') ? undefined : json['kin1Relationship'],
        'kin1Mobile': !exists(json, 'kin1Mobile') ? undefined : json['kin1Mobile'],
        'kin1Address': !exists(json, 'kin1Address') ? undefined : json['kin1Address'],
        'kin2Name': !exists(json, 'kin2Name') ? undefined : json['kin2Name'],
        'kin2Relationship': !exists(json, 'kin2Relationship') ? undefined : json['kin2Relationship'],
        'kin2Mobile': !exists(json, 'kin2Mobile') ? undefined : json['kin2Mobile'],
        'kin2Address': !exists(json, 'kin2Address') ? undefined : json['kin2Address'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'manager': !exists(json, 'manager') ? undefined : ApplicationUserFromJSON(json['manager']),
        'managedUsers': !exists(json, 'managedUsers') ? undefined : (json['managedUsers'] === null ? null : (json['managedUsers'] as Array<any>).map(ApplicationUserFromJSON)),
        'roomLeaderId': !exists(json, 'roomLeaderId') ? undefined : json['roomLeaderId'],
        'roomLeader': !exists(json, 'roomLeader') ? undefined : ApplicationUserFromJSON(json['roomLeader']),
        'roomLeaderUsers': !exists(json, 'roomLeaderUsers') ? undefined : (json['roomLeaderUsers'] === null ? null : (json['roomLeaderUsers'] as Array<any>).map(ApplicationUserFromJSON)),
        'learningStyleId': !exists(json, 'learningStyleId') ? undefined : json['learningStyleId'],
        'learningStyle': !exists(json, 'learningStyle') ? undefined : LearningStyleFromJSON(json['learningStyle']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
        'userImageId': !exists(json, 'userImageId') ? undefined : json['userImageId'],
        'userImage': !exists(json, 'userImage') ? undefined : UploadedFileFromJSON(json['userImage']),
        'trainings': !exists(json, 'trainings') ? undefined : (json['trainings'] === null ? null : (json['trainings'] as Array<any>).map(TrainingFromJSON)),
        'awards': !exists(json, 'awards') ? undefined : (json['awards'] === null ? null : (json['awards'] as Array<any>).map(AwardFromJSON)),
        'awardedAwards': !exists(json, 'awardedAwards') ? undefined : (json['awardedAwards'] === null ? null : (json['awardedAwards'] as Array<any>).map(AwardFromJSON)),
        'files': !exists(json, 'files') ? undefined : (json['files'] === null ? null : (json['files'] as Array<any>).map(UserFileFromJSON)),
        'performanceFiles': !exists(json, 'performanceFiles') ? undefined : (json['performanceFiles'] === null ? null : (json['performanceFiles'] as Array<any>).map(PerformanceFileFromJSON)),
        'approvedPerformanceFiles': !exists(json, 'approvedPerformanceFiles') ? undefined : (json['approvedPerformanceFiles'] === null ? null : (json['approvedPerformanceFiles'] as Array<any>).map(PerformanceFileFromJSON)),
        'qualifications': !exists(json, 'qualifications') ? undefined : (json['qualifications'] === null ? null : (json['qualifications'] as Array<any>).map(QualificationFromJSON)),
        'shiftPattern': !exists(json, 'shiftPattern') ? undefined : (json['shiftPattern'] === null ? null : (json['shiftPattern'] as Array<any>).map(ShiftDayFromJSON)),
        'claims': !exists(json, 'claims') ? undefined : (json['claims'] === null ? null : (json['claims'] as Array<any>).map(StringIdentityUserClaimFromJSON)),
        'logins': !exists(json, 'logins') ? undefined : (json['logins'] === null ? null : (json['logins'] as Array<any>).map(StringIdentityUserLoginFromJSON)),
        'tokens': !exists(json, 'tokens') ? undefined : (json['tokens'] === null ? null : (json['tokens'] as Array<any>).map(StringIdentityUserTokenFromJSON)),
        'userRoles': !exists(json, 'userRoles') ? undefined : (json['userRoles'] === null ? null : (json['userRoles'] as Array<any>).map(ApplicationUserRoleFromJSON)),
        'userDisciplinaries': !exists(json, 'userDisciplinaries') ? undefined : (json['userDisciplinaries'] === null ? null : (json['userDisciplinaries'] as Array<any>).map(UserDisciplinaryFromJSON)),
        'userPolicies': !exists(json, 'userPolicies') ? undefined : (json['userPolicies'] === null ? null : (json['userPolicies'] as Array<any>).map(UserPolicyFromJSON)),
        'publishedPolicies': !exists(json, 'publishedPolicies') ? undefined : (json['publishedPolicies'] === null ? null : (json['publishedPolicies'] as Array<any>).map(PolicyFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

export function ApplicationUserToJSON(value?: ApplicationUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'normalizedUserName': value.normalizedUserName,
        'email': value.email,
        'normalizedEmail': value.normalizedEmail,
        'emailConfirmed': value.emailConfirmed,
        'passwordHash': value.passwordHash,
        'securityStamp': value.securityStamp,
        'concurrencyStamp': value.concurrencyStamp,
        'phoneNumber': value.phoneNumber,
        'phoneNumberConfirmed': value.phoneNumberConfirmed,
        'twoFactorEnabled': value.twoFactorEnabled,
        'lockoutEnd': value.lockoutEnd === undefined ? undefined : (value.lockoutEnd === null ? null : value.lockoutEnd.toISOString()),
        'lockoutEnabled': value.lockoutEnabled,
        'accessFailedCount': value.accessFailedCount,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString()),
        'mobile': value.mobile,
        'jobTitle': value.jobTitle,
        'notes': value.notes,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'town': value.town,
        'postCode': value.postCode,
        'kin1Name': value.kin1Name,
        'kin1Relationship': value.kin1Relationship,
        'kin1Mobile': value.kin1Mobile,
        'kin1Address': value.kin1Address,
        'kin2Name': value.kin2Name,
        'kin2Relationship': value.kin2Relationship,
        'kin2Mobile': value.kin2Mobile,
        'kin2Address': value.kin2Address,
        'managerId': value.managerId,
        'manager': ApplicationUserToJSON(value.manager),
        'managedUsers': value.managedUsers === undefined ? undefined : (value.managedUsers === null ? null : (value.managedUsers as Array<any>).map(ApplicationUserToJSON)),
        'roomLeaderId': value.roomLeaderId,
        'roomLeader': ApplicationUserToJSON(value.roomLeader),
        'roomLeaderUsers': value.roomLeaderUsers === undefined ? undefined : (value.roomLeaderUsers === null ? null : (value.roomLeaderUsers as Array<any>).map(ApplicationUserToJSON)),
        'learningStyleId': value.learningStyleId,
        'learningStyle': LearningStyleToJSON(value.learningStyle),
        'status': value.status,
        'active': value.active,
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
        'userImageId': value.userImageId,
        'userImage': UploadedFileToJSON(value.userImage),
        'awards': value.awards === undefined ? undefined : (value.awards === null ? null : (value.awards as Array<any>).map(AwardToJSON)),
        'awardedAwards': value.awardedAwards === undefined ? undefined : (value.awardedAwards === null ? null : (value.awardedAwards as Array<any>).map(AwardToJSON)),
        'files': value.files === undefined ? undefined : (value.files === null ? null : (value.files as Array<any>).map(UserFileToJSON)),
        'performanceFiles': value.performanceFiles === undefined ? undefined : (value.performanceFiles === null ? null : (value.performanceFiles as Array<any>).map(PerformanceFileToJSON)),
        'approvedPerformanceFiles': value.approvedPerformanceFiles === undefined ? undefined : (value.approvedPerformanceFiles === null ? null : (value.approvedPerformanceFiles as Array<any>).map(PerformanceFileToJSON)),
        'qualifications': value.qualifications === undefined ? undefined : (value.qualifications === null ? null : (value.qualifications as Array<any>).map(QualificationToJSON)),
        'shiftPattern': value.shiftPattern === undefined ? undefined : (value.shiftPattern === null ? null : (value.shiftPattern as Array<any>).map(ShiftDayToJSON)),
        'claims': value.claims === undefined ? undefined : (value.claims === null ? null : (value.claims as Array<any>).map(StringIdentityUserClaimToJSON)),
        'logins': value.logins === undefined ? undefined : (value.logins === null ? null : (value.logins as Array<any>).map(StringIdentityUserLoginToJSON)),
        'tokens': value.tokens === undefined ? undefined : (value.tokens === null ? null : (value.tokens as Array<any>).map(StringIdentityUserTokenToJSON)),
        'userRoles': value.userRoles === undefined ? undefined : (value.userRoles === null ? null : (value.userRoles as Array<any>).map(ApplicationUserRoleToJSON)),
        'userDisciplinaries': value.userDisciplinaries === undefined ? undefined : (value.userDisciplinaries === null ? null : (value.userDisciplinaries as Array<any>).map(UserDisciplinaryToJSON)),
        'userPolicies': value.userPolicies === undefined ? undefined : (value.userPolicies === null ? null : (value.userPolicies as Array<any>).map(UserPolicyToJSON)),
        'publishedPolicies': value.publishedPolicies === undefined ? undefined : (value.publishedPolicies === null ? null : (value.publishedPolicies as Array<any>).map(PolicyToJSON)),
        'deleted': value.deleted,
    };
}

