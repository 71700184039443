/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    PolicyStatus,
    PolicyStatusFromJSON,
    PolicyStatusFromJSONTyped,
    PolicyStatusToJSON,
} from './PolicyStatus';
import {
    PolicyType,
    PolicyTypeFromJSON,
    PolicyTypeFromJSONTyped,
    PolicyTypeToJSON,
} from './PolicyType';
import {
    UserPolicy,
    UserPolicyFromJSON,
    UserPolicyFromJSONTyped,
    UserPolicyToJSON,
} from './UserPolicy';

/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    modified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    description?: string | null;
    /**
     * 
     * @type {PolicyStatus}
     * @memberof Policy
     */
    status?: PolicyStatus;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    publishedById?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof Policy
     */
    publishedBy?: ApplicationUser;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    typeId?: number | null;
    /**
     * 
     * @type {PolicyType}
     * @memberof Policy
     */
    type?: PolicyType;
    /**
     * 
     * @type {Array<UserPolicy>}
     * @memberof Policy
     */
    userPolicies?: Array<UserPolicy> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Policy
     */
    deleted?: boolean;
}

export function PolicyFromJSON(json: any): Policy {
    return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': !exists(json, 'status') ? undefined : PolicyStatusFromJSON(json['status']),
        'publishedById': !exists(json, 'publishedById') ? undefined : json['publishedById'],
        'publishedBy': !exists(json, 'publishedBy') ? undefined : ApplicationUserFromJSON(json['publishedBy']),
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'type': !exists(json, 'type') ? undefined : PolicyTypeFromJSON(json['type']),
        'userPolicies': !exists(json, 'userPolicies') ? undefined : (json['userPolicies'] === null ? null : (json['userPolicies'] as Array<any>).map(UserPolicyFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

export function PolicyToJSON(value?: Policy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
        'name': value.name,
        'description': value.description,
        'status': PolicyStatusToJSON(value.status),
        'publishedById': value.publishedById,
        'publishedBy': ApplicationUserToJSON(value.publishedBy),
        'typeId': value.typeId,
        'type': PolicyTypeToJSON(value.type),
        'userPolicies': value.userPolicies === undefined ? undefined : (value.userPolicies === null ? null : (value.userPolicies as Array<any>).map(UserPolicyToJSON)),
        'deleted': value.deleted,
    };
}

