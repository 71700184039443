/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShiftDayDto,
    ShiftDayDtoFromJSON,
    ShiftDayDtoFromJSONTyped,
    ShiftDayDtoToJSON,
} from './ShiftDayDto';

/**
 * 
 * @export
 * @interface ShiftDayDtoListApiResponse
 */
export interface ShiftDayDtoListApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ShiftDayDtoListApiResponse
     */
    succeeded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShiftDayDtoListApiResponse
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShiftDayDtoListApiResponse
     */
    errors?: Array<string> | null;
    /**
     * 
     * @type {Array<ShiftDayDto>}
     * @memberof ShiftDayDtoListApiResponse
     */
    data?: Array<ShiftDayDto> | null;
}

export function ShiftDayDtoListApiResponseFromJSON(json: any): ShiftDayDtoListApiResponse {
    return ShiftDayDtoListApiResponseFromJSONTyped(json, false);
}

export function ShiftDayDtoListApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftDayDtoListApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ShiftDayDtoFromJSON)),
    };
}

export function ShiftDayDtoListApiResponseToJSON(value?: ShiftDayDtoListApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'succeeded': value.succeeded,
        'message': value.message,
        'errors': value.errors,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ShiftDayDtoToJSON)),
    };
}

