/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTrainingProviderCommand,
    AddTrainingProviderCommandFromJSON,
    AddTrainingProviderCommandToJSON,
    TrainingProviderDtoApiResponse,
    TrainingProviderDtoApiResponseFromJSON,
    TrainingProviderDtoApiResponseToJSON,
    TrainingProviderDtoListApiResponse,
    TrainingProviderDtoListApiResponseFromJSON,
    TrainingProviderDtoListApiResponseToJSON,
    UpdateTrainingProviderCommand,
    UpdateTrainingProviderCommandFromJSON,
    UpdateTrainingProviderCommandToJSON,
} from '../models';

export interface ApiTrainingProviderIdDeleteRequest {
    id: string;
}

export interface ApiTrainingProviderIdGetRequest {
    id: string;
}

export interface ApiTrainingProviderPostRequest {
    addTrainingProviderCommand?: AddTrainingProviderCommand;
}

export interface ApiTrainingProviderPutRequest {
    updateTrainingProviderCommand?: UpdateTrainingProviderCommand;
}

/**
 * TrainingProviderApi - interface
 * 
 * @export
 * @interface TrainingProviderApiInterface
 */
export interface TrainingProviderApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingProviderApiInterface
     */
    apiTrainingProviderGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingProviderDtoListApiResponse>>;

    /**
     */
    apiTrainingProviderGet(initOverrides?: RequestInit): Promise<TrainingProviderDtoListApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingProviderApiInterface
     */
    apiTrainingProviderIdDeleteRaw(requestParameters: ApiTrainingProviderIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingProviderIdDelete(id: string, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingProviderApiInterface
     */
    apiTrainingProviderIdGetRaw(requestParameters: ApiTrainingProviderIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingProviderDtoApiResponse>>;

    /**
     */
    apiTrainingProviderIdGet(id: string, initOverrides?: RequestInit): Promise<TrainingProviderDtoApiResponse>;

    /**
     * 
     * @param {AddTrainingProviderCommand} [addTrainingProviderCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingProviderApiInterface
     */
    apiTrainingProviderPostRaw(requestParameters: ApiTrainingProviderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingProviderDtoApiResponse>>;

    /**
     */
    apiTrainingProviderPost(addTrainingProviderCommand?: AddTrainingProviderCommand, initOverrides?: RequestInit): Promise<TrainingProviderDtoApiResponse>;

    /**
     * 
     * @param {UpdateTrainingProviderCommand} [updateTrainingProviderCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingProviderApiInterface
     */
    apiTrainingProviderPutRaw(requestParameters: ApiTrainingProviderPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    apiTrainingProviderPut(updateTrainingProviderCommand?: UpdateTrainingProviderCommand, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class TrainingProviderApi extends runtime.BaseAPI implements TrainingProviderApiInterface {

    /**
     */
    async apiTrainingProviderGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingProviderDtoListApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingProvider`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProviderDtoListApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingProviderGet(initOverrides?: RequestInit): Promise<TrainingProviderDtoListApiResponse> {
        const response = await this.apiTrainingProviderGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingProviderIdDeleteRaw(requestParameters: ApiTrainingProviderIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingProviderIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingProvider/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingProviderIdDelete(id: string, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingProviderIdDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async apiTrainingProviderIdGetRaw(requestParameters: ApiTrainingProviderIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingProviderDtoApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingProviderIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingProvider/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProviderDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingProviderIdGet(id: string, initOverrides?: RequestInit): Promise<TrainingProviderDtoApiResponse> {
        const response = await this.apiTrainingProviderIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingProviderPostRaw(requestParameters: ApiTrainingProviderPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrainingProviderDtoApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingProvider`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTrainingProviderCommandToJSON(requestParameters.addTrainingProviderCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProviderDtoApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingProviderPost(addTrainingProviderCommand?: AddTrainingProviderCommand, initOverrides?: RequestInit): Promise<TrainingProviderDtoApiResponse> {
        const response = await this.apiTrainingProviderPostRaw({ addTrainingProviderCommand: addTrainingProviderCommand }, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingProviderPutRaw(requestParameters: ApiTrainingProviderPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TrainingProvider`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTrainingProviderCommandToJSON(requestParameters.updateTrainingProviderCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingProviderPut(updateTrainingProviderCommand?: UpdateTrainingProviderCommand, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingProviderPutRaw({ updateTrainingProviderCommand: updateTrainingProviderCommand }, initOverrides);
    }

}
