/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAllUsersPagedQuery
 */
export interface GetAllUsersPagedQuery {
    /**
     * 
     * @type {string}
     * @memberof GetAllUsersPagedQuery
     */
    searchText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllUsersPagedQuery
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllUsersPagedQuery
     */
    archived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllUsersPagedQuery
     */
    pending?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAllUsersPagedQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllUsersPagedQuery
     */
    pageSize?: number;
}

export function GetAllUsersPagedQueryFromJSON(json: any): GetAllUsersPagedQuery {
    return GetAllUsersPagedQueryFromJSONTyped(json, false);
}

export function GetAllUsersPagedQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllUsersPagedQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'pending': !exists(json, 'pending') ? undefined : json['pending'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function GetAllUsersPagedQueryToJSON(value?: GetAllUsersPagedQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchText': value.searchText,
        'active': value.active,
        'archived': value.archived,
        'pending': value.pending,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}

