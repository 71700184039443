/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyDto,
    PolicyDtoFromJSON,
    PolicyDtoFromJSONTyped,
    PolicyDtoToJSON,
} from './PolicyDto';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface PoliciesUserPolicyViewModel
 */
export interface PoliciesUserPolicyViewModel {
    /**
     * 
     * @type {string}
     * @memberof PoliciesUserPolicyViewModel
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PoliciesUserPolicyViewModel
     */
    approved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PoliciesUserPolicyViewModel
     */
    approvedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoliciesUserPolicyViewModel
     */
    status?: string | null;
    /**
     * 
     * @type {PolicyDto}
     * @memberof PoliciesUserPolicyViewModel
     */
    policy?: PolicyDto;
    /**
     * 
     * @type {UserDto}
     * @memberof PoliciesUserPolicyViewModel
     */
    user?: UserDto;
}

export function PoliciesUserPolicyViewModelFromJSON(json: any): PoliciesUserPolicyViewModel {
    return PoliciesUserPolicyViewModelFromJSONTyped(json, false);
}

export function PoliciesUserPolicyViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesUserPolicyViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'approvedDate': !exists(json, 'approvedDate') ? undefined : json['approvedDate'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'policy': !exists(json, 'policy') ? undefined : PolicyDtoFromJSON(json['policy']),
        'user': !exists(json, 'user') ? undefined : UserDtoFromJSON(json['user']),
    };
}

export function PoliciesUserPolicyViewModelToJSON(value?: PoliciesUserPolicyViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'approved': value.approved,
        'approvedDate': value.approvedDate,
        'status': value.status,
        'policy': PolicyDtoToJSON(value.policy),
        'user': UserDtoToJSON(value.user),
    };
}

