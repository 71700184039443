/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    FileCategory,
    FileCategoryFromJSON,
    FileCategoryFromJSONTyped,
    FileCategoryToJSON,
} from './FileCategory';
import {
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';

/**
 * 
 * @export
 * @interface UserFile
 */
export interface UserFile {
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserFile
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserFile
     */
    modified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserFile
     */
    onboarding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFile
     */
    adminViewOnly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserFile
     */
    fileCategoryId?: number | null;
    /**
     * 
     * @type {FileCategory}
     * @memberof UserFile
     */
    fileCategory?: FileCategory;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof UserFile
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    uploadedFileId?: string | null;
    /**
     * 
     * @type {UploadedFile}
     * @memberof UserFile
     */
    uploadedFile?: UploadedFile;
}

export function UserFileFromJSON(json: any): UserFile {
    return UserFileFromJSONTyped(json, false);
}

export function UserFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modified': !exists(json, 'modified') ? undefined : (json['modified'] === null ? null : new Date(json['modified'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'onboarding': !exists(json, 'onboarding') ? undefined : json['onboarding'],
        'adminViewOnly': !exists(json, 'adminViewOnly') ? undefined : json['adminViewOnly'],
        'fileCategoryId': !exists(json, 'fileCategoryId') ? undefined : json['fileCategoryId'],
        'fileCategory': !exists(json, 'fileCategory') ? undefined : FileCategoryFromJSON(json['fileCategory']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'uploadedFileId': !exists(json, 'uploadedFileId') ? undefined : json['uploadedFileId'],
        'uploadedFile': !exists(json, 'uploadedFile') ? undefined : UploadedFileFromJSON(json['uploadedFile']),
    };
}

export function UserFileToJSON(value?: UserFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modified': value.modified === undefined ? undefined : (value.modified === null ? null : value.modified.toISOString()),
        'title': value.title,
        'onboarding': value.onboarding,
        'adminViewOnly': value.adminViewOnly,
        'fileCategoryId': value.fileCategoryId,
        'fileCategory': FileCategoryToJSON(value.fileCategory),
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
        'uploadedFileId': value.uploadedFileId,
        'uploadedFile': UploadedFileToJSON(value.uploadedFile),
    };
}

