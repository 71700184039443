import "element-plus/dist/index.css";
import "@/assets/element-variables.scss";

import App from "./App.vue";
import router from "./router";

import "balm-ui-css";
import "./assets/global.scss";

import BalmUI, { useGrid } from "balm-ui";
import BalmUIPlus from "balm-ui/dist/balm-ui-plus";
import moment from "moment";
import { createApp } from "vue/dist/vue.esm-bundler";
import { createPinia } from "pinia";
import { useTheme } from "balm-ui/plugins/theme";
import { defaultConfig, plugin } from "@formkit/vue";
import "@formkit/themes/genesis";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { QuillEditor } from "@vueup/vue-quill";
import VueDOMPurifyHTML from "vue-dompurify-html";

moment.locale("en");

const pinia = createPinia();
const app = createApp(App);
app
  .use(pinia)
  .use(router)
  .use(BalmUI)
  .use(BalmUIPlus)
  .use(ElementPlus)
  .use(plugin, defaultConfig)
  .use(QuillEditor)
  .use(VueDOMPurifyHTML)
  .mount("#app");

app.config.errorHandler = (err) => {
  console.log(err);
};

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.config.globalProperties.$moment = moment;

const $theme = useTheme();
$theme.primary = "#1565c0";
$theme.secondary = "#1565c0";

const $grid = useGrid();
$grid.set("gutter", "desktop", "12px");
