/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserFile,
    UserFileFromJSON,
    UserFileFromJSONTyped,
    UserFileToJSON,
} from './UserFile';

/**
 * 
 * @export
 * @interface FileCategory
 */
export interface FileCategory {
    /**
     * 
     * @type {number}
     * @memberof FileCategory
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileCategory
     */
    name?: string | null;
    /**
     * 
     * @type {Array<UserFile>}
     * @memberof FileCategory
     */
    userFiles?: Array<UserFile> | null;
}

export function FileCategoryFromJSON(json: any): FileCategory {
    return FileCategoryFromJSONTyped(json, false);
}

export function FileCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'userFiles': !exists(json, 'userFiles') ? undefined : (json['userFiles'] === null ? null : (json['userFiles'] as Array<any>).map(UserFileFromJSON)),
    };
}

export function FileCategoryToJSON(value?: FileCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'userFiles': value.userFiles === undefined ? undefined : (value.userFiles === null ? null : (value.userFiles as Array<any>).map(UserFileToJSON)),
    };
}

