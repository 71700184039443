/* tslint:disable */
/* eslint-disable */
/**
 * Little Angels API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: piotr@digitaltrading.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationUser,
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import {
    Disciplinary,
    DisciplinaryFromJSON,
    DisciplinaryFromJSONTyped,
    DisciplinaryToJSON,
} from './Disciplinary';

/**
 * 
 * @export
 * @interface UserDisciplinary
 */
export interface UserDisciplinary {
    /**
     * 
     * @type {string}
     * @memberof UserDisciplinary
     */
    readonly id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDisciplinary
     */
    isManager?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDisciplinary
     */
    userId?: string | null;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof UserDisciplinary
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof UserDisciplinary
     */
    disciplinaryId?: string | null;
    /**
     * 
     * @type {Disciplinary}
     * @memberof UserDisciplinary
     */
    disciplinary?: Disciplinary;
    /**
     * 
     * @type {boolean}
     * @memberof UserDisciplinary
     */
    deleted?: boolean;
}

export function UserDisciplinaryFromJSON(json: any): UserDisciplinary {
    return UserDisciplinaryFromJSONTyped(json, false);
}

export function UserDisciplinaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDisciplinary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isManager': !exists(json, 'isManager') ? undefined : json['isManager'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : ApplicationUserFromJSON(json['user']),
        'disciplinaryId': !exists(json, 'disciplinaryId') ? undefined : json['disciplinaryId'],
        'disciplinary': !exists(json, 'disciplinary') ? undefined : DisciplinaryFromJSON(json['disciplinary']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

export function UserDisciplinaryToJSON(value?: UserDisciplinary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isManager': value.isManager,
        'userId': value.userId,
        'user': ApplicationUserToJSON(value.user),
        'disciplinaryId': value.disciplinaryId,
        'disciplinary': DisciplinaryToJSON(value.disciplinary),
        'deleted': value.deleted,
    };
}

