import { useSnackbarStore } from "@/stores/snackbarStore";
import { storeToRefs } from "pinia";

export async function errorToast(response) {
  if (!response) {
    return;
  }
  const contentType = response.headers.get("Content-Type");
  if (!contentType || !contentType.includes("application/json")) {
    // console.log(toRaw(response));
    return;
  }

  const body = await response.json();
  const snackbarStore = useSnackbarStore();
  const { message, isOpen, snackbarClass } = storeToRefs(snackbarStore);
  snackbarClass.value = "errorToast";

  if (response.status === 500) {
    message.value = body.message;
  }

  if (response.status === 400) {
    if (body.message != null) {
      message.value = body.message;
    } else if (body.errors != null) {
      message.value = body.errors[Object.keys(body.errors)[0]][0];
    }
  }

  if (response.status === 401) {
    message.value = "You are unauthorized!";
  }

  console.log(message.value);
  isOpen.value = true;
}

export function successToast(msg?: null | string) {
  const snackbarStore = useSnackbarStore();
  const { message, isOpen, snackbarClass } = storeToRefs(snackbarStore);
  snackbarClass.value = "successToast";
  message.value = msg ?? "Success!";
  isOpen.value = true;
}
