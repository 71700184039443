import { useAuthStore } from "@/stores/authStore";
import { CheckViewPermission } from "@/shared/composables/permissions";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { storeToRefs } from "pinia";
import { ViewPermission } from "@/shared/enums/view-permissions";

export enum AuthGuardType {
  LOGIN = "login",
  UNAUTHORIZED = "unauthorized",
}

export function authGuard(type: AuthGuardType) {
  const authStore = useAuthStore();
  if (
    (authStore.isTokenValid && authStore.userRole == "user") ||
    authStore.userRole == "practitioner"
  ) {
    return { path: "/users/edit/" + authStore.userId };
  }

  if (authStore.isTokenValid) {
    return type === AuthGuardType.LOGIN ? { path: "/users" } : true;
  }
  authStore.logout();
  return type === AuthGuardType.LOGIN ? true : { path: "/" };
}

export function permissionGuard(
  permission: ViewPermission,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (CheckViewPermission(permission)) {
    next();
  } else {
    const authStore = useAuthStore();
    const { userId } = storeToRefs(authStore);
    next(from.path === "/" ? `/users/edit/${userId.value}` : from);
  }
}

export function isTokenExpired(decodedToken: any) {
  return decodedToken.exp != null && decodedToken.exp < Date.now() / 1000;
}

export function isTokenNullOrEmpty(decodedToken: any) {
  return decodedToken == null || isEmpty(decodedToken);
}

export function isEmpty(obj: Record<string, any>): boolean {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
